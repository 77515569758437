import React, {useState, useEffect, useRef} from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import { Helmet } from 'react-helmet';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PageTitleSection from '../PageTitleSection';
import classes from './style.module.scss';
import clsx from 'clsx';
import Requirements from './Requirements';
import ConfirmPay from './ConfirmPay';
import axios from 'services/axios';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const STEPS = [
    {
        id: 1,
        text: 'Requirements'
    },
    {
        id: 2,
        text: 'Confirm & Pay'
    }
]

const Payment = () => {
    const [step, setStep] = useState(1);
    const [allData, setAllData] = useState();
    const [formData, setFormData] = useState({});
    const [specialities, setSpecialities] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const location = useLocation();
    const country = useSelector((state) => state.country.value);
    const providerId = useRef(undefined);

    const updateFormData = (value, key) => {
        const tFormData = {
            ...formData,
            [key]: value
        }
        setFormData(tFormData);
    }

    const getFormData = (key) => {
        if (formData[key]) {
            return formData[key];
        }
    }

    const moveToNextStep = () => {
        if (providerId.current) {
            const payload = {
                "servicecategory_id": allData.servicecategory_id,
                "service_id": formData.services_purchased ? formData.services_purchased.map(i => i.value).join(',') : undefined,
                "serviceprovider_id": providerId.current,
                "requirement_description": getFormData('service_description'),
                "budget": parseFloat(getFormData('price')),
                "location": country.selectedCountry?.label,
                "screen_from": 'order'
            }
            axios.post('/order', payload)
            .then(response => {
                if (response.status === 200) {
                    setOrderDetails(response.data);
                    setStep(2);
                } 
            });
        }
    }

    const backLink = {
        text: (
            <div className="d-flex align-items-center">
                <ArrowBackIosIcon sx={{ fontSize: 18 }} />
                Back
            </div>
        )
    }

    useEffect( () => {
        if (location && location.state && location.state.id) {
            providerId.current = location.state.id;
            let url = '/user/pro/' + location.state.id;
            
            axios.get(url)
            .then(response => {
                if(response.status === 200) {
                    setAllData(response.data.data)
                    const specialitiesList = response.data.data.services.map((curr) => {
                        return { value: curr.id, label: curr.name }
                    })
                    setSpecialities(specialitiesList)
                }
            })
        }
    }, []);

    const getSteps = () => STEPS.map(i => {
        return (
            <div key={i.id} className={clsx(classes.step, step === i.id && classes.stepActive, 'col-6 col-sm-5 col-md-4')}>
                <div className={classes.stepNo}>{i.id}</div>
                <div>{i.text}</div>
            </div>
        )
    })

    return (
        <>
            <Helmet>
                <title>Payment</title>
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="120px" blueTitle="Service Details" backLink={backLink} />
            <div className="container py-5">
                <div className="row mb-5 ml-1 mr-1">
                    {getSteps()}
                </div>
                <div className="row ml-1 mr-1">
                    {
                        step === 1 ? (
                            <Requirements moveToNextStep={moveToNextStep} allData={allData} updateFormData={updateFormData} getFormData={getFormData} specialitiesList={specialities} />
                        ) : (
                            <ConfirmPay orderDetails={orderDetails} allData={allData} getFormData={getFormData} />
                        )
                    }
                </div>
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default Payment