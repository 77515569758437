import { maxSize } from 'lib/constants';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { isFileImage } from 'lib/helpers';
import { PROFILE_DOCUMENT_ITEM } from 'lib/constants';
import PreviewImage from 'components/PreviewImage';
import axios from 'services/axios';
import clsx from 'clsx';

const UploadPreview = (props) => {
    const { preview = `https://AcDiTo.com/v1/api/files/${props.id}`, htmlFor = '', isEdit } = props;
    return (
        <div className="upload-preview-img">
            <div>
                <PreviewImage src={preview}>
                    <img src={preview} />
                    {
                        isEdit && (
                            <label className="edit" htmlFor={htmlFor}>
                                <div>
                                    <i class="far fa-edit"></i>
                                </div>
                            </label>
                        )
                    }
                </PreviewImage>
            </div>
        </div>
    )
}

const UploadDocuments = (props) => {
    const { documents, onSubmit, isEdit = false } = props;
    const [uploadData, setUploadData] = useState(documents);

    const onChangeHandler = (e, from) => {
        if (e.target.files.length) {
            var fileSize = e.target.files[0].size; // in bytes
            if (fileSize > maxSize) {
                toast.error('File size exceeded the maximum allowed size');
                return false;
            }
            if (!isFileImage(e.target.files[0])) {
                toast.error('Please select an image');
                return true;
            }

            var data = new FormData();
            data.append('input_files', e.target.files[0]);

            axios.post('/files/upload/personal', data).then((res) => {
                if (res.data && res.data.files && res.data.files.length > 0) {
                    const tUploadData = { ...uploadData };
                    tUploadData[from] = {
                        id: res.data.files[0],
                        preview: URL.createObjectURL(e.target.files[0]),
                        name: e.target.files[0].name,
                        size: e.target.files[0].size,
                    }
                    setUploadData(tUploadData);
                    onSubmit(tUploadData);
                }
            });
        }
    }

    const isFileUploaded = (id) => {
        if (uploadData && uploadData[id]) {
            return true;
        }

        return false;
    }


    const getFileUploadData = (id) => {
        if (uploadData && uploadData[id]) {
            return uploadData[id];
        }
    }

    const onDelete = (id) => {
        const tUploadData = { ...uploadData };
        delete tUploadData[id];
        setUploadData(tUploadData);
    }

    return (
        <div className="upload-document-items">
            <div className={clsx(!(isFileUploaded(PROFILE_DOCUMENT_ITEM['Doc-Id']) || isEdit) && 'd-none')}>
                {
                    isFileUploaded(PROFILE_DOCUMENT_ITEM['Doc-Id']) ? (
                        <UploadPreview isEdit={isEdit} {...getFileUploadData(PROFILE_DOCUMENT_ITEM['Doc-Id'])} htmlFor="uploadDocID" />
                    ) : (
                            isEdit &&
                            <label htmlFor="uploadDocID" className="mb-0 w-100">
                                <div className="add-profile-document">
                                    <div className="add-profile-document-plus">
                                        <i class="fa fa-plus"></i>
                                    </div>
                                </div>
                            </label>
                        )
                }
                {
                    (isFileUploaded(PROFILE_DOCUMENT_ITEM['Doc-Id']) || isEdit) && (
                        <span>ID Card</span>
                    )
                }
                {
                    isEdit && (
                        <>
                            <input
                                type="file"
                                name="photoVideo"
                                id="uploadDocID"
                                className="d-none"
                                onChange={(event) => onChangeHandler(event, PROFILE_DOCUMENT_ITEM['Doc-Id'])}
                                accept="image/*"
                            />
                        </>
                    )
                }
            </div>
            <div className={clsx(!(isFileUploaded(PROFILE_DOCUMENT_ITEM['License']) || isEdit) && 'd-none')}>
                {
                    isFileUploaded(PROFILE_DOCUMENT_ITEM['License']) ? (
                        <UploadPreview isEdit={isEdit} {...getFileUploadData(PROFILE_DOCUMENT_ITEM['License'])} htmlFor="uploadCert" />
                    ) : (
                            isEdit &&
                            <label htmlFor="uploadCert" className="mb-0 w-100">
                                <div className="add-profile-document">
                                    <div className="add-profile-document-plus">
                                        <i class="fa fa-plus"></i>
                                    </div>
                                </div>
                            </label>
                        )
                }
                {
                    (isFileUploaded(PROFILE_DOCUMENT_ITEM['License']) || isEdit) && (
                        <span>License/Certificate</span>
                    )
                }
                {
                    isEdit && (
                        <>
                            <input
                                type="file"
                                name="photoVideo"
                                id="uploadCert"
                                className="d-none"
                                onChange={(event) => onChangeHandler(event, PROFILE_DOCUMENT_ITEM.License)}
                                accept="image/*"
                            />
                        </>
                    )
                }
            </div>
            {/* <div className={clsx(!(isFileUploaded(PROFILE_DOCUMENT_ITEM['Non-Conviction']) || isEdit) && 'd-none')}>
                {
                    isFileUploaded(PROFILE_DOCUMENT_ITEM['Non-Conviction']) ? (
                        <UploadPreview isEdit={isEdit} {...getFileUploadData(PROFILE_DOCUMENT_ITEM['Non-Conviction'])} htmlFor="uploadNonCon" />
                    ) : (
                            isEdit &&
                            <label htmlFor="uploadNonCon" className="mb-0 w-100">
                                <div className="add-profile-document">
                                    <div className="add-profile-document-plus">
                                        <i class="fa fa-plus"></i>
                                    </div>
                                </div>
                            </label>
                        )
                }
                {
                    (isFileUploaded(PROFILE_DOCUMENT_ITEM['Non-Conviction']) || isEdit) && (
                        <span>Non-Conviction</span>
                    )
                }
                {
                    isEdit && (
                        <>
                            <input
                                type="file"
                                name="photoVideo"
                                id="uploadNonCon"
                                className="d-none"
                                onChange={(event) => onChangeHandler(event, PROFILE_DOCUMENT_ITEM['Non-Conviction'])}
                                accept="image/*"
                            />
                        </>
                    )
                }
            </div> */}
            <div className={clsx(!(isFileUploaded(PROFILE_DOCUMENT_ITEM['Tax-Id']) || isEdit) && 'd-none')}>
                {
                    isFileUploaded(PROFILE_DOCUMENT_ITEM['Tax-Id']) ? (
                        <UploadPreview isEdit={isEdit} {...getFileUploadData(PROFILE_DOCUMENT_ITEM['Tax-Id'])} htmlFor="uploadTaxId" />
                    ) : (
                            isEdit &&
                            <label htmlFor="uploadTaxId" className="mb-0 w-100">
                                <div className="add-profile-document">
                                    <div className="add-profile-document-plus">
                                        <i class="fa fa-plus"></i>
                                    </div>
                                </div>
                            </label>
                        )
                }
                {
                    (isFileUploaded(PROFILE_DOCUMENT_ITEM['Tax-Id']) || isEdit) && (
                        <span>Tax Id</span>
                    )
                }
                {
                    isEdit && (
                        <>
                            <input
                                type="file"
                                name="photoVideo"
                                id="uploadTaxId"
                                className="d-none"
                                onChange={(event) => onChangeHandler(event, PROFILE_DOCUMENT_ITEM['Tax-Id'])}
                                accept="image/*"
                            />
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default UploadDocuments;