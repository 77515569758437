import React, { useEffect } from 'react'
import {Helmet} from "react-helmet";
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
                <meta property="og:title" content="AcDiTo - Privacy Policy" />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="120px" title1="AcDiTo's Privacy Policy" />
            <div className="container my-5 privacy-policy">
                <div className="row">
                    <div className="col-12" >
                        <p>Last Updated: June 30, 2021</p>
                        <p>Your privacy is important to us. We handle information that you provide to us and information we obtain about you in accordance with the terms of this Privacy Policy. If you are a California resident, please see our Privacy Notice for California Residents regarding your additional rights under the California Consumer Privacy Act.</p>
                        
                        <h4 className="py-3">Overview</h4>
                        <p>This Privacy Policy describes the collection, use, and disclosure of Personal Information (as defined below) by AcDiTo.com, Inc. ("AcDiTo", "we," "us") and other parties with 
                            respect to your use of all properties (Websites, mobile application, telephone service or otherwise) owned or operated by AcDiTo (collectively, the "Properties") and all 
                            products and services available from AcDiTo, AcDiTo's family of companies (each, an "AcDiTo Family Company"), providers of home-related products and services 
                            ("Service Providers") or other third parties in connection with the Properties (individually and collectively with the Properties, the "Services"). By accessing or using the 
                            , you are accepting the practices described in this Privacy Policy. If you do not agree to the terms of this Privacy Policy, you should stop using the Services.</p>
                        <p>All capitalized terms used but not defined in this Privacy Policy have the meanings given in our Terms of Use. References to "you" means you as a User and/or as a Service 
                            Provider, as the case may be. References to AcDiTo (or "we" or "us") also include companies providing services to AcDiTo, the AcDiTo Family Companies, and our business 
                            partners, to the extent that such parties are collecting, using, disclosing, or providing Personal Information for or on behalf of AcDiTo.</p>

                        <h4 className="py-3">Information Covered</h4>
                        <p>This Privacy Policy covers "Personal Information." Personal Information means your unique personal identifiers, such as your name, email address, cellular telephone 
                            number, or government-issued identification number (such as your driver's license number, identification card, or social security number); information that we link to 
                            your unique identifier(s); and your unique financial information, such as your credit card number.</p>
                        <p>We do not consider information that is not uniquely linked to you (such as your usage of the Properties if you do not provide us with identifying information) to be Personal Information.</p>
                        <p>From time to time, we may also de-link orde-identify your Personal Information so that the information is not linked to you. We do not consider information that has 
                            been de-linked orde-identified to be Personal Information. For example, your address would not be Personal Information if it we reincluded in a list of addresses 
                            without names or other unique identifiers.</p>

                        <h4 className="py-3">Privacy Policy Updates</h4>
                        <p>We may update this Privacy Policy at any time by posting a revised version. Any changes to this Privacy Policy will be effective immediately upon posting. Please 
                            review this Privacy Policy each time you use the Services, as your continued use of the Services after such changes will constitute your acceptance and agreement to such changes.</p>

                        <h4 className="py-3">Collection of Information</h4>
                        <h6 className="py-2">Information You Provide</h6>
                        <p>Information You Submit. You can visit and browse the Properties without opening an account and entering Personal Information. You will need to open an account and provide Personal Information if you would like to get a quote for home-related products or services, if you'd like to access a mover dashboard, or if you are a Service Provider who would like to join the AcDiTo network of home services providers. When using the Services, whether as a User or as a Service Provider, you may elect to provide Personal Information about yourself, such as name, address, telephone number, email address, payment details, and information about your use of the Services or your experiences with AcDiTo, Service Providers or Users. You may provide Personal Information, for example, when you create an account, post User Submissions, request or purchase products or services, communicate with us, a Service Provider or a User, subscribe to our promotional marketing programs, "Like" us on Facebook or otherwise connect us to your social networks, or complete other forms. If you are a Service Provider, you may provide additional information about yourself or your company, including for example, when you provide details to complete your account, respond to service requests from customers, and provide information for background/reference checks.</p>
                        <p>In connection with the Services, you may also elect to provide reviews, comments, feedback, notes, photos, videos, know-how, techniques, or other information relating to you, your project, your household, your business, your real or personal property, or otherwise.</p>
                        <p>Information that you choose to provide in a public area of the Services (such as your profile page or via User Submissions), including Personal Information, will not be protected by this Privacy Policy because you have chosen to make that information public.</p>
                        <p>Social Networks. If you use the social networking connection functions offered through the Services, we may access any of your social profile information that you have made available to be shared and to use it in accordance with this Privacy Policy. Please refer to the policies of the social network applications you use to manage information that is shared through that account.</p>
                        <h6 className="py-2">Information We Collect Automatically</h6>
                        <p>Cookies and Web Beacons. We and our advertising networks use cookies, web beacons (sometimes called single-pixel gifs or action tags), or similar technologies on the Properties, in emails, and in advertisements to collect information about your online activities. Cookies (small data files that your Internet browsers to res on your hard disk or in your browser's memory) are used by the Services to connect your activity on the Services with other information we store about you in your account profile or from prior usage of the Services. Web beacons are electronic images that assist in delivering cookies, provide additional information (such as which ad you clicked on to arrive at the Properties), and allow us to compile aggregated statistics. For example, the use of cookies allows us to improve our Services by tracking saved searches, understanding your and other site visitors' use of various features and functionality, delivering targeted ads that are more relevant to you, avoiding ad duplication, measuring the effectiveness of advertising and otherwise creating a consistent user experience for you. Collected information may include the content you view, the date and time you view content, and the referring website or advertisement. This information may be associated with your unique browser, device identifier, or IP address. However, we do not treat this information as Personal Information unless it is linked to you with your name or other unique identifiers.</p>
                        <p>At any time, you may adjust your browser settings to delete existing cookies (and the data that web beacons add to the cookie) or refuse new cookies, according to the method permitted by your browser. If you choose to delete cookies, you will need to log in again on your next visit to the Properties and re-enter any preferences you have set. If you choose to disable cookies, you may not be able to access or use some portions and features of the Services and may not get the best experience possible.</p>
                        <p>Your browser may offer you a "Do Not Track" option, which allows you to signal to operators of websites and other properties that you do not wish such operators to track certain online activities. Our Services do not support Do Not Track requests.</p>
                        <p>Usage Data. We and our service providers automatically record usage data created when you use the Services. Usage data may include your IP address, browser type, operating system, referring web page, visited web pages, mobile carrier, computer or mobile device type, session information such as pages visited, screen actions and other interaction patterns, search terms, cookie information and other information. We do not treat this information as Personal Information unless it is linked to you with your name or other unique identifiers.</p>
                        <p>Location Data. If you provide location information during the account registration process or at any other time via your account settings, or if location information is included as part of the information we collect (for example, your IP address may provide an approximate geo-location), we may store and use that information in connection with your account. If you are using mobile Services, we may collect location data directly from your mobile device if your device allows us to do so. Your mobile device manufacturer or mobile service provider may provide you with choices about how and whether location data is shared with us. We do not treat this information as Personal Information unless it is linked to you with your name or other unique identifiers.</p>
                        <h6 className="py-2">Other Information We Collect</h6>
                        <p>Telephone Calls. When you call us, when we call you, or when we connect calls between Users and Service Providers, we may monitor and/or record those calls for quality assurance and customer satisfaction purposes. In those jurisdictions where consent is required for a party to record a telephone call, you consent to us recording such calls. We treat the records of these calls when linked to you, as Personal Information.</p>
                        <p>Email and Mobile Content Programs. You can choose to receive promotional emails from us. Like most e-commerce sites, we include software code in our emails to monitor whether and when you opened the email, whether you have clicked on the images and/or links in the email, and whether your computer or mobile device is capable of receiving HTML-based email. We treat this information when linked to you, as Personal Information. You may also choose to access our content through a mobile application and to receive text (SMS) messages on your mobile device. You may opt-out from email and mobile programs as described below under "opting out."</p>
                        <h6 className="py-2">Information Collected by Third Parties</h6>
                        <p>Online Activity. You may get cookies or web beacons from our third-party advertisers, tracking utilities, the AcDiTo Family Companies, business partners, or other third parties with links on the Properties. This is a standard practice in our industry, and we have no control or access to these cookies or web beacons. Our Privacy Policy does not cover the use of these technologies by third parties or the use of such technologies on websites or mobile applications other than the Properties.</p>
                        <p>Offline Activity. AcDiTo and Service Providers may collect data, text, photos, videos, measurements, comments, reviews, feedback, notes, ideas, know-how, techniques, or other information of any nature whatsoever relating to you, your household, your real or personal property, or otherwise, in connection with the Services provided by AcDiTo or the services provided by Service Providers. We treat this information when linked to you, as Personal Information.</p>
                        <h6 className="py-2">Information We Obtain From Third Parties</h6>
                        <p>We may obtain Personal Information about you from third parties and use it to provide a customized experience, to offer products or services that might be of interest to you, to supplement or correct information that we have on file for you, and generally to improve our Services. For example, we may obtain Personal Information about you from AcDiTo Family Companies, from companies who partner with us to offer discounts, promotions, or AcDiTo services (such as the AcDiToHomeAssistant) to their customers or users, or from consumer data collection companies. If you (or your agent or a person you authorize) order an AcDiTo product or service that includes or reflects information about you, we may also collect that information either directly from you or from your agent or authorized representative. We treat this information when linked to you, as Personal Information.</p>
                        <h6 className="py-2">Aggregate and Anonymized Information</h6>
                        <p>We collect non-personal, statistical information about the use of the Services, such as how many visitors visit a specific page, how long they stay, which links they click on, and how, when, and how often Users order particular Services.</p>
                        <p>We may also aggregate (group) orde-link/de-identify (anonymize) information. Aggregated, anonymized data is not Personal Information subject to this Privacy Policy, and we may use it to analyze usage of the Services or for any other purpose. We may also share aggregated and/or anonymized information with AcDiTo Family Companies and our and their business partners. and about individuals, we may compile a list of addresses that we believe are associated with an individual moving to a new address and provide that list (without any unique identifiers for the individuals) to our business partners.</p>
                    
                        <h4 className="py-3">Use of Personal Information</h4>
                        <p>Personal Information we collect from or about you may be used in the following ways:</p>
                        <ul className="list-unstyled my-3">
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To provide the Services;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To improve the Services (for example, to personalize your experience to make it smoother and more relevant to you), to improve the content and design of the Properties, and to develop new Services;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To process transactions, to provide and improve customer service, to communicate with you about your account or changes in our policies, and to otherwise manage our customer relationships;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To connect you with Service Providers and other companies that receive or help us meet requests and orders for products and services listed on our platform;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To help Service Providers select whether they want to work with you and to provide Service Providers with more opportunities to receive service requests that might be of interest to them;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To provide you with information about products and services from AcDiTo, the AcDiTo Family Companies, and our and their business partners that may be of interest to you, and to optimize our and their advertising and marketing campaigns;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To select, develop and deliver advertising, offers, and products and services from AcDiTo, the AcDiTo Family Companies and our and their business partners that may be of interest to you, and to analyze their effectiveness, distribution, and reach;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To administer contests, promotions, or sweepstakes;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To detect, investigate and prevent fraud, abuse, and activities that may be illegal or violate our policies, to address technical and security issues, to comply with applicable laws and legal process, and to otherwise protect Users, Service Providers, and our business;</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To create de-identified (anonymous) information for our own uses and use by the AcDiTo family of companies and our and their business partners; and</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>To perform other business purposes described at the time the information is collected or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act.</li>
                        </ul>

                        <h4 className="py-3">Disclosure of Information</h4>
                        <p>Your Personal Information will be shared with third parties as described below.</p>
                        <p>Except as described in this Privacy Policy, neither AcDiTo nor the AcDiTo Family Companies will sell or otherwise provide to third parties any of your Personal Information or any detailed property data that you provide to us or the AcDiTo Family Companies (including Personal Information or property data included in an inspection report provided by you or a person you authorize).</p>
                        <h6 className="py-2">Disclosures to AcDiTo Family Companies</h6>
                        <p>AcDiTo shares your Personal Information and detailed property data about your home with AcDiTo Family Companies. For example, we share Personal Information with them for their marketing purposes, to allow them to improve their products and services (for example, to confirm information they have about a consumer or to classify a consumer as a mover), and in connection with the data, technical, accounting, and other services shared within the AcDiTo family of companies. The AcDiTo Family Companies will be bound by the terms of this Privacy Policy with respect to Personal Information received from AcDiTo and will use your Personal Information only as described in this Privacy Policy.</p>
                        <h6 className="py-2">Companies Providing Services to AcDiTo</h6>
                        <p>We contract with other companies who provide business services to AcDiTo. For example, we may use a third-party service provider or an AcDiTo Family Company to process payments, host the Properties, provide call center or other customer services, create reports ordered by Users, send out information or offers, serve or manage ads, and analyze Services data. Some of these companies collect, process, and store Personal Information for us as part of their services, and we provide them with the elements of Personal Information that they need to deliver their services. Companies collecting, processing, and storing Personal Information in connection with the services they provide to us are prohibited from using that Personal Information for any other purpose and will have access only as necessary to perform the applicable services for us.</p>
                        <h6 className="py-2">Consumer-Directed Disclosures</h6>
                        <p>We will share your Personal Information when you direct AcDiTo to do something that requires its disclosure. For example, if you seek a Service Provider through the Services, we provide your Personal Information to the Service Provider and the Service Provider's information to you.</p>
                        <p>Please note that, when you submit a request for products or services, you are requesting to be (and you expressly consent to be) contacted by AcDiTo, Service Providers and companies that help connect you with Service Providers (or, if you are a Service Provider, by AcDiTo and companies that help connect you with customers), as the case may be. You consent to be contacted by these parties by telephone, email, mail, text (SMS) messaging, fax, or other reasonable means at any of the residential, mobile, or fax phone numbers or addresses you provide, even if they are listed on a national "donotcall"or "donotcontact" list. You agree that these communications may include prerecorded, artificially voiced, or auto dialed telemarketing messages and that they may be monitored and recorded for quality assurance and other reasons. You agree that these parties may contact you for the purposes of providing the products or services you have requested, marketing, addressing complaints, billing or other account-related matters, or other purposes reasonably related to the services you have requested. Your consent to receive marketing communications is not required as a condition of purchasing any goods or services.</p>
                        <h6 className="py-2">Co-Branded Partners</h6>
                        <p>Some of our Services may be offered in conjunction with an AcDiTo Family Company or a third party. If you request, access, or use our Services through an AcDiTo Family Company or a third party, or you request, access, or use their services through the Properties, both AcDiTo and they may receive your Personal Information. For example, if you signup with AcDiTo, request AcDiTo services, or consent to receiving communications from AcDiTo through a partner-promoted or co-branded website or within a partner's store, AcDiTo and that partner may share your Personal Information with each other.</p>
                        <h6 className="py-2">Other Partner Programs</h6>
                        <p>From time to time, we may partner with AcDiTo Family Companies or third parties to offer discounts, rewards, or other programs or promotions. We may disclose your Personal Information to them if you participate in their promotion or you purchase (or request to receive more information about) their products or services from us. For example, if a discount on a partner product or service is offered through a marketing communication from us or a link between their website and our website, we may disclose to the partner Personal Information about our Users that contact us as a result of the marketing communication, our Users that visit their web site or their users who visit our website.</p>
                        <h6 className="py-2">Companies with Whom You Have a Relationship</h6>
                        <p>If you are already a customer of or otherwise have a direct relationship with a business partner of AcDiTo or an AcDiTo Family Company (an "Existing Relationship Company"), we may disclose certain Personal Information to that Existing Relationship Company. If you are a match to a customer on the Existing Relationship Company customer's list, we may provide the Existing Relationship Customer with some of your Personal Information, such as the groups or segments you belong to (such as mover or homeowner) and demographic information about you and your home (such as estimated household income and square footage), so that the Existing Relationship Company can provide you with more relevant advertising, products, and services. Neither we nor the service provider will give the Existing Relationship Company your contact information, your unique personal identifiers, or your credit card information, unless for another purpose permitted in this Privacy Policy. However, the Existing Relationship Company may already have some or all of that information (because of your existing relationship with them) and they will be able to link you with the other information about you that we provide to them.</p>
                        <h6 className="py-2">Service Provider Information</h6>
                        <p>We do not consider the business information of Service Providers to be Personal Information. Accordingly, we may share Service Provider business names, owner/proprietor names, business contact information, and other information with third parties for any purpose.</p>
                        <h6 className="py-2">Compliance with Laws and Policies; Protection of Rights</h6>
                        <p>We may release your Personal Information when we believe that doing so is appropriate to comply with applicable laws, regulations, subpoenas, warrants or court orders, or other legal requirements; to cooperate with the government or law enforcement; to resolve complaints or disputes; to avoid legal liability; to comply with, enforce or investigate potential violations of our agreements, Terms of Use, program-specific terms, Privacy Policy or other policies; to bill and collect amounts owed to us; to protect our rights, reputation, safety or property or that of Users, Service Providers or others; and to address fraud, security or technical issues.</p>
                        <h6 className="py-2">Sale/Merger</h6>
                        <p>We may decide to sell, buy, merge or reorganize our own or other businesses, conduct a securities offering, or do a joint venture or other strategic transaction. We could also be involved in a bankruptcy, liquidation, dissolution, or similar transaction. Any such transaction may involve disclosing Personal Information. We would seek protections of confidentiality and restricted use for all information disclosed. In the event that we or all of our assets are acquired in such a transaction, our collected information would be one of the transferred assets.</p>
                        <h6 className="py-2">Contest/Sweepstakes</h6>
                        <p>We may publicly disclose the name, city, and state of winners.</p>
                        <p>Opting Out of AcDiTo Marketing; Editing Your Personal Information</p>
                        <h6 className="py-2">Changing Information; Closing Account</h6>
                        <p>To change or delete your Personal Information, you may log in to your account and modify or remove specified information that resides in your account. If you want to delete your account, please contact us at questions@AcDiTo.com. We will remove your account within a reasonable time after receiving your request. Please note that, even after your account is deleted, information that you chose to provide in a public area of the Services (such as reviews and projects) will remain visible to the public and may remain in the accounts of Users and Service Providers with whom such information has been shared, and all of your information (including your Personal Information) will remain in AcDiTo's backup/archival records. AcDiTo may retain and use information in our records as described in the Terms of Use and this Privacy Policy, including the section above entitled Disclosure of Information/Compliance with Laws and Policies; Protection of Rights.</p>
                        <p>You may also make requests regarding your Personal Information, such as requests to access information or requests to update or correct inaccurate information, by emailing us at questions@AcDiTo.com</p>
                        <h6 className="py-2">Marketing Calls and Texts</h6>
                        <p>Receiving promotional calls and text (SMS) messages from AcDiTo is completely voluntary, and you may opt-out at any time.</p>
                        <p>To opt-out of promotional calls or text (SMS) messages from AcDiTo, you can contact AcDiTo customer service at (713) 487 5718 or send an opt-out request to questions@AcDiTo.com. In addition, to opt-out of promotional text (SMS) messages from AcDiTo, you can text STOP to any text(SMS) message you receive. You may receive a final text message confirming your opt-out. Please allow up to 10 business days to process any opt-out request. Message and data rates may apply.</p>
                        <h6 className="py-2">Email Communications</h6>
                        <p>You can unsubscribe at any time from receiving promotional emails from AcDiTo by clicking on the unsubscribe link included at the bottom of our email messages. In addition, you can contact AcDiTo customer service at (713) 487 5718 or send an opt-out request to questions@AcDiTo.com. You may receive a final email confirming that you have been unsubscribed. Please allow up to 10 business days to process any unsubscribe request.</p>
                        <h6 className="py-2">Direct Mail</h6>
                        <p>You can opt-out at any time from receiving direct-mail promotional correspondence from AcDiTo by contacting AcDiTo customer service at (713) 487 5718 or sending a request to questions@AcDiTo.com.</p>
                        <h6 className="py-2">Continued Communications After Opt-Out</h6>
                        <p>Even if you opt-out of receiving marketing communications, you may still receive non-promotional communications from us. We reserve the right to send you service announcements (such as notifications about site outages) or administrative messages, without offering you the opportunity to opt-out of receiving them. In addition, because we must communicate with you about service requests that you have submitted (or received, if you are a Service Provider), you can not opt-out of receiving emails, text (SMS) messages, or calls from us relating to those requests.</p>
                        <h6 className="py-2">Opting Out of Communications from Third Parties</h6>
                        <p>To opt-out from receiving promotional communications from a third party, or to make requests regarding your Personal Information or other information held by a third party, you should contact the third party directly.</p>
                        <h6 className="py-2">Your California Privacy Rights</h6>
                        <p>If you are a resident of California, please see our Privacy Notice for California Residents regarding your rights under the California Consumer Privacy Act.</p>
                        <h6 className="py-2">Your Nevada Privacy Rights</h6>
                        <p>If you are a resident of Nevada, you have the right to opt-out of AcDiTo's sale of certain kinds of personal information (as defined under Nevada law) to purchasers that will then license or sell the covered information to additional persons. Nevada law specifies various transactions that are excluded from the definition of "sale": for example, sales or disclosures for purposes of providing a product or service at the request of the consumer (such as when you request a quote or information about products or services), transfers to service providers, transfers to corporate affiliates (i.e., the AcDiTo Family Companies), and disclosures consistent with the reasonable expectations of a consumer. If you exercise this opt-out right, you will not be opted out from transactions that the law does not deem to be sales. You can exercise this right by emailing us at questions@AcDiTo.com with the subject line "Nevada DoNot Sell Request."</p>
                        <h6 className="py-2">Security</h6>
                        <p>The security of your Personal Information is important to us. We maintain industry-standard physical, electronic, and procedural safeguards, such as firewalls and encryption technology, to protect against the loss, misuse, and alteration of the information under our control. For example, when you enter a credit card number on the Properties, we encrypt the transmission of that information using secure socket layer (SSL) technology. However, no method of transmitting information over the Internet or storing information is completely secure. Accordingly, we cannot guarantee the protection of any information shared with us.</p>
                        <p>If you create an account, you may be asked to choose a password to protect your account and account information. We recommend that you do not disclose your password to any other person. You are responsible for maintaining the confidentiality of your password and account and are fully responsible for all activities that occur under your password or account.</p>

                        <h4 className="py-3">General Matters</h4>
                        <h6 className="py-2">Children</h6>
                        <p>The Services are not directed to children under 16, and we do not knowingly collect or store any Personal Information about persons under the age of 16. If we learn that we have collected Personal Information of a child under16, we will make commercially reasonable efforts to delete such information from our files. If you are under the age of 16, please do not submit any Personal Information through the Properties.</p>
                        <h6 className="py-2">Third-Party Websites</h6>
                        <p>Our Services may contain links to other websites and services. Any information that you provide on another website or service is provided directly to the owner or operator of the website or service and is subject to that party's privacy policy. In addition, if you visit the Properties through a link or advertisement on another website, then that website may be collecting information from users who click on the link or banner, and the collected information will be subject to that website's privacy policy. Our Privacy Policy does not apply to any such websites or services, and we are not responsible for their content, privacy, or security practices and policies. To protect your information we recommend that you carefully review the privacy policies of other websites and services that you access. If you have any questions regarding the privacy policy of a third party, you should contact the third party directly for more information.</p>
                        <h6 className="py-2">Governing Law; Visitors Residing Outside the United States</h6>
                        <p>All of the Services are hosted in the United States. This Privacy Policy and the privacy practices of the Properties will be subject exclusively to the laws of the State of Delaware within the United States of America, without regard to conflict of law principles. If you reside outside of the United States, you are responsible for complying with local laws in connection with your use of the Services, and you consent to the collection, transfer, storage, and use of your Personal Information in the United States of America in accordance with this Privacy Policy.</p>
                        <p>The laws governing the processing of Personal Information vary depending on the jurisdiction in which you reside. To the extent the laws of your jurisdiction are more restrictive than the terms of this Privacy Policy, it is AcDiTo's policy to follow the requirements of those laws to the extent those laws are applicable to AcDiTo's activities.</p>
                    </div>
                </div>
            </div>
            
            <PagesFooter />
        </>
    )
}
