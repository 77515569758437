import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import axios from 'services/axios';
import Form from "react-bootstrap/Form";
import {Dropdown} from "react-bootstrap";

import Select from '../../components/elements/Select1';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import CorporateServicesHeader from '../../assets/img/CorporateServicesHeader.png';
import AbegYaAIPoweredTech from '../../assets/img/AbegYaAIPoweredTech.png';
import downArrow from '../../assets/img/down-arrow.svg';
import { getAllCategories, getSpecialities } from 'services/category';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

const INIT_DATA = {
    "full_name": "",
    "company_name": "",
    "email": "",
    "country": "",
    "phone": "",
    "servicecategory": undefined,
    "services": [],
    "request_details": ""
}

export default function CorporateServices() {
    const [formData, setFormData] = useState(INIT_DATA);
    const [countryCodes, setCountryCodes] = useState(null);
    const [selectedCountryCode, setSelectedCountryCode] = useState('234_ng');
    const [specialities, setSpecialities] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [apiMessagae, setApiMessagae] = useState();
    const history = useHistory();

    const updateFormData = (key, value) => {
        const tFormData = {
            ...formData,
            [key]: value
        }
        setFormData(tFormData);
    }

    const getFormData = (key) => {
        if (formData[key]) {
            return formData[key];
        }
    }

    const loadInit = async () => {
        const allCateforiesRes = await getAllCategories();
        
        if (allCateforiesRes.status === 200 && allCateforiesRes.statusText === 'OK') {
            let servicesListArray = allCateforiesRes.data
            let services = servicesListArray.data.map((service) => {
                return { value: service.id, label: service.name }
            });
            setAllCategories(services);
        }
    }

    useEffect( () => {
        loadInit();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        axios.get('/countries')
            .then(response => {
                if(response.status === 200 && response.statusText === 'OK') {
                    setCountryCodes(response.data.serviceUserCountries)
                }
            })
            .catch(err => {
                console.log(err)
            });  
    }, []);

    const onChangeCategory = (value) => {
        updateFormData('servicecategory', value);
        if (value.value) {
            getSpecialities(value.value).then((allSpecialitiesRes) => {
                if (allSpecialitiesRes.status === 200 && allSpecialitiesRes.statusText === 'OK') {
                    const specialitiesList = allSpecialitiesRes.data.data.map((curr) => {
                        return { value: curr.id, label: curr.name }
                    })
                    setSpecialities(specialitiesList);
                }
            });
        }
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const data = {
            "full_name": getFormData('full_name'),
            "company_name": getFormData('company_name'),
            "email": getFormData('email'),
            "country": `+${selectedCountryCode.split("_")[0]}`,
            "phone": getFormData('phone'),
            "servicecategory_id": getFormData('servicecategory') ? getFormData('servicecategory').value : undefined,
            "service_id": getFormData('services') ? getFormData('services').map(i => i.value).join(',') : undefined,
            "request_details": getFormData('request_details')
        }
        axios.post('/corporateservice', data)
        .then(response => {
            if (response.status === 200 && response.statusText === 'OK') {
                setApiMessagae({
                    type: 1,
                    msg: 'Your request was successfully submitted'
                });
                setTimeout(() => {
                    history.push('/');
                }, 2000);
            } else { 
                setApiMessagae({
                    type: 0,
                    msg: 'Oops, Something went wrong. Try again later'
                });
                return false 
            }
        })
        .catch(err => {
            let errmsg = err.response.data[Object.keys(err.response.data)[0]];
            setApiMessagae({
                type: 0,
                msg: errmsg
            });
            return false
        });;
    }

    return (
        <>
            <Helmet>
                <title>Corporate Services</title>
                <meta property="og:title" content="AcDiTo - Corporate Services | Hire Trusted Professionals for Your Business Needs" />
                <meta key="og:description" property="og:description" content="Need professional services in Africa? Hire trusted professionals with AcDiTo. Find the best providers for any service. View reviews, ratings, and locations before hiring. Manage projects and payments from anywhere." />
            </Helmet>
            <PagesHeader />
            <div className="container my-5">
                <div className="row">
                    <div className="col-5"></div><div className="col-12 col-md-12 col-lg-7 text-center"><h4 className="py-4">Describe the service you're looking for  - be as detailed as possible</h4></div>
                    <div className="col-12 col-md-12 col-lg-4 text-center mb-4">
                        <img src={AbegYaAIPoweredTech} className="img-fluid" alt="AcDiTo AI Powered Tech" />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-12 col-md-12 col-lg-7">
                        <form className="row form" onSubmit={onFormSubmit}>
                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                <Form.Control required onChange={
                                    (e) => updateFormData('full_name', e.target.value)
                                } value={getFormData('full_name')} type="text" className="signup-input" name='fullName' placeholder="Full Name" />
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                <Form.Control required onChange={
                                    (e) => updateFormData('company_name', e.target.value)
                                } value={getFormData('company_name')} type="text" className="signup-input" name='companyName' placeholder="Company Name" />
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                <Form.Control required onChange={
                                    (e) => updateFormData('email', e.target.value)
                                } value={getFormData('email')} type="email" className="signup-input" name='workEmail' placeholder="Work Email" />
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                <div className="input-group-prepend">
                                    <Dropdown className="d-flex align-items-stretch">
                                        <Dropdown.Toggle className="country-select" variant="secondary" id="dropdown-basic" key={selectedCountryCode}>
                                            <img alt="country" key={selectedCountryCode} src={"https://AcDiTo.com/v1/flag/"+ selectedCountryCode.split("_")[1] +".png"} /> +{ selectedCountryCode.split("_")[0] }
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="countryDropdown">
                                            {
                                                (countryCodes !== null) ? 
                                                countryCodes.map((country) => {
                                                return <Dropdown.Item onClick={() => {setSelectedCountryCode(country.code+"_"+country.iso_code.toLowerCase())}} key={country.name}><img alt="" src={"https://AcDiTo.com/v1/flag/"+ country.iso_code.toLowerCase() +".png"} /> +{country.code}</Dropdown.Item>
                                                }) : null
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control 
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={
                                        (e) => updateFormData('phone', e.target.value)
                                    }  value={getFormData('phone')} type="text" className="signup-input" name='workPhone' placeholder="Work Phone Number" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                <Select
                                    options={allCategories}
                                    placeholder='Service Category'
                                    value={getFormData('servicecategory')}
                                    onChange={onChangeCategory}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                <Select
                                    options={specialities}
                                    placeholder='Required Services'
                                    isMulti
                                    minRows={3}
                                    value={getFormData('services')}
                                    onChange={(value) => {
                                        updateFormData('services', value);
                                    }}
                                />
                            </div>
                            <div className="col-12 form-group">
                                <Form.Control onChange={
                                    (e) => updateFormData('request_details', e.target.value)
                                } value={getFormData('request_details')} as="textarea" className="signup-input" name='requestDetails' rows={4} placeholder="Request Details" />
                            </div>
                            <div className="col-12 text-center mt-3">
                                <button type="submit" className="px-5 btn lp-button1 fwBold">Submit Request</button>
                            </div>
                            {
                                apiMessagae && (
                                    <div className="col-12 text-center form-group mt-3">
                                        <span className={clsx('h5', apiMessagae.type === 1 && 'text-success', apiMessagae.type === 0 && 'text-danger')}>{apiMessagae.msg}</span>
                                    </div>
                                )
                            }
                        </form>
                    </div>
                </div>
            </div>
            
            <PagesFooter showFooter={false} />
        </>
    )
}
