import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
//import { Provider } from 'mobx-react';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
// import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// Custom Styles
import './styles/scss/styles.scss';
import 'react-toastify/dist/ReactToastify.css';

//import userStore from './stores/userStore';

import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import userReducer from "./features/user";
import countryReducer from "./features/country";
import userDataReducer from "./features/user-data";
import DateAdapter from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';

const store = configureStore({
  reducer: {
    user: userReducer,
    country: countryReducer,
    userData: userDataReducer,
  },
});



ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <LocalizationProvider dateAdapter={DateAdapter}> */}
        <App />
      {/* </LocalizationProvider> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
