import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';
import ReadOnlyDetails from 'components/ReadOnlyDetails';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AxiosService from 'services/axios';
import { getCurrnecyCode } from 'lib/getCurrencyCode';
import moment from 'moment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Transaction = (props) => {
    const { 
        to_name = '',
        amount = '',
        created_at,
        status = '',
        merchant = '',
        p_type = '',
    } = props.data;
    const [open, setOpen] = useState(false);

    const getStatusText = () => {
        switch(status) {
            case "failed":
                return (
                    <div title="Failed">
                        <CancelIcon style={{ color: 'red' }} />
                    </div>
                );

            case "success":
                return (
                    <div title="Success">
                        <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    </div>
                );
				
			case "completed":
                return (
                    <div title="Success">
                        <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    </div>
                );

			case "expired":
                return (
                    <div title="Expired">
                          <CancelIcon style={{ color: 'red' }} />
                    </div>
                );

            default:
                return (
                    <div title="Pending">
                        <ErrorOutlineIcon style={{ color: 'orange' }} />
                    </div>
                );
        }
    }

    return (
        <>
            
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">{moment(created_at).format('D MMM YYYY, H:mm ')}</TableCell>
                <TableCell>
                    {to_name}
                </TableCell>
                <TableCell>{`${getCurrnecyCode()} ${amount}`}</TableCell>
                <TableCell>{getStatusText()}</TableCell>
                <TableCell>{p_type}</TableCell>
                <TableCell>{merchant}</TableCell>
            </TableRow>
            {/* <div className={classes.transaction}>
                <div class="row">
                    <div className="col-xs-12 col-md-4 col-xl-4 mb-3">
                        <ReadOnlyDetails title="To" data={to_name} />
                    </div>
                    <div className="col-xs-12 col-md-4 col-xl-4 mb-3">
                        <ReadOnlyDetails title="Amount" data={`${getCurrnecyCode()} ${amount}`} />
                    </div>
                    <div className="col-xs-12 col-md-4 col-xl-4 mb-3">
                        <ReadOnlyDetails title="Status" data={getStatusText()} />
                    </div>
                    {
                        open && (
                            <>
                                <div className="col-xs-12 col-md-6 col-xl-6 mb-3">
                                    <ReadOnlyDetails title="Date" data={moment(created_at).format('D MMM YYYY, h:mm a')} />
                                </div>
                                <div className="col-xs-12 col-md-6 col-xl-6 mb-3">
                                    <ReadOnlyDetails title="Merchant" data={merchant} />
                                </div>
                            </>
                        )
                    }
                </div>
                <div className="row">
                    <div className={`mt-2 col-12 ${classes.transactionSeeDetails}`} onClick={() => setOpen(!open)}>
                        {open && <ArrowBackIcon />}
                        {open ? 'See Less' : 'See More'}
                        {!open && <ArrowForwardIcon />}
                    </div>
                </div> */}
            {/* </div> */}
        </>
    )
}

const TransactionHistory = () => {
    const [allTrans, setAllTrans] = useState();
    useEffect(() => {
        AxiosService.get('/transaction/history').then((res) => {
            console.log(res)
            if (res.status === 200) {
                setAllTrans(res.data);
            }
        })
    }, []);

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 625 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>From/To</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Merchant</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        allTrans && allTrans.length === 0 && (
                            <TableRow>
                                <TableCell colSpan="5">No transactions found</TableCell>
                            </TableRow>
                        )
                    }
                    {allTrans && allTrans.length > 0 && allTrans.map((row) => (
                        <Transaction data={row} key={row.id} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TransactionHistory