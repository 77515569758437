import React from 'react';
import './style.scss';

const ReadOnlyDetails = (props) => {
    const { title = '', data = '' } = props;
    return (
        <div className="read-only-wrapper">
            <div className="label">
                {title}
            </div>
            <div className="data">
                {data}
            </div>
        </div>
    )
}

export default ReadOnlyDetails;