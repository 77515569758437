import React from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import { Helmet } from 'react-helmet';
import images from '../../assets/image/index';

import 'bootstrap/dist/css/bootstrap.min.css';

class AboutPage extends React.Component {
    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>AcDiTo - Mission-driven team of innovators</title>
                    <meta
                        key="meta:description"
                        name="description"
                        content="ACT is supported by a global distributed team of innovators building a blockchain experience accessible to everyone."
                    />
                    <meta key="og:title" property="og:title" content="AcDiTo - Mission-driven team of innovators" />
                    <meta
                        key="og:description"
                        property="og:description"
                        content="ACT is supported by a global distributed team of innovators building a blockchain experience accessible to everyone."
                    />
                </Helmet>
                <PagesHeader />
                <>
                    {' '}
                    <div className="fmroboto">
                        <div className="landingpage_section1">
                            <div className="row" style={{ margin: 'auto' }}>
                                <div className="col-lg-3 col-md-3"></div>
                                <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center landing_jumbotron justify-content-center">
                                    <div className="">
                                        {/* <div className="fs30 mb_25 fwBold yellow text-center">
                                    Building a credible decentralized coupon system
                                </div> */}
                                        <div className="fs32 mb_25 fwBold text-center">
                                            Built and supported by a mission-driven team of innovators from around the
                                            world
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3"></div>
                            </div>
                            <div className="row" style={{ margin: 'auto' }}>
                                <div className="col-12" id="landing-page-caorusel">
                                    <img
                                        // className="img-fluid"
                                        style={{ maxWidth: '100%', opacity: 0.5, objectFit: 'contain' }}
                                        src={images.banner1}
                                        alt="slider1"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="landingpage_section2 pt-5 pb-5 mt-5">
                            <div className="container">
                                <div className="row mt_40">
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="mt_20 lp-description-text">
                                                Our mission is to empower consumers worldwide with the means to easily
                                                assist family and friends pay bills and buy goods and services at home
                                                and abroad. Sending money as direct payment for services instead of cash
                                                transferred to individuals gives access to discounts helping consumers
                                                save 12 billion every year, reducing time to help, fees, and increasing
                                                purchasing power.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt_40">
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="mt_20 lp-description-text">
                                                AcDiTo unlocks billions in global discounts through tokenization, giving
                                                these consumers the means to access and manage discounts, and service
                                                providers the means to target, track and maximize the return on
                                                discounting while furnishing third-party investors with a dynamic
                                                digital asset class.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt_40">
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="mt_20 lp-description-text">
                                                AcDiTo coupons/incentives are secure, tradeable, and can be shared with
                                                family or anyone else. Unique mobile wallets give consumers an
                                                easy-to-use, convenient method to save on online and offline purchases
                                                and for businesses to present their present offers to a global consumer
                                                base.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt_40">
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="mt_20 lp-description-text">
                                                Using a research-first driven approach AcDiTo combines innovation,
                                                blockchain technology, and AI to provide a secure way for everyone to
                                                seek financial aid when paying bills, and a mechanism for globally
                                                available tokenized coupons.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row mt_40">
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="mt_20 lp-description-text">
                                                <div className="text-center">Meet ACT Team</div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div
                                                                    className="col-md-4"
                                                                    style={{ maxHeight: '300px' }}
                                                                >
                                                                    <img
                                                                        className="img-fluid"
                                                                        style={{ width: '100%' }}
                                                                        src={images.gilbert_image}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8 align-items-center">
                                                                    <div>Gilbert Mbeh</div>
                                                                    <div>Co-Founder/CEO</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </>
                <PagesFooter />
            </>
        );
    }
}

export default AboutPage;
