import MuiPhoneNumber from 'material-ui-phone-number';
import React from 'react';
import './style.scss';

const PhoneInput = (props) => {
    return (
        <MuiPhoneNumber
            {...props}
            className={props.className ? `${props.className} custom-phone-input`: 'custom-phone-input'}
        />
    )
}

export default PhoneInput