import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
    selectedCountry: "",
    allCountries: [],
    allUserCountries: [],
};

export const countrySlice = createSlice({
  name: "country",
  initialState: { value: initialStateValue },
  reducers: {
    updateSelectedCountry: (state, action) => {
      state.value.selectedCountry = action.payload;
    },
    updateAllCountries: (state, action) => {
      state.value.allCountries = action.payload;
    },
    updateAllUserCountries: (state, action) => {
      state.value.allUserCountries = action.payload;
    },
  },
});

export const { updateSelectedCountry, updateAllCountries, updateAllUserCountries } = countrySlice.actions;

export default countrySlice.reducer;