import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import axios from 'services/axios';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';

import ServiceProfileCard from '../../components/pages/ServiceProfileCard'
import providerProfileImgDummy from '../../assets/img/user-244.png';
import downarrow from '../../assets/img/down-arrow.svg'
import { useSelector } from 'react-redux';

function serviceProviders() {
    let serviceName = window.location.pathname.split('/')[2]
    let serviceType = window.location.pathname.split('/')[2].split('-').join(' ')
    const country = useSelector((state) => state.country.value);

    const [providersData, setProvidersData] = useState([]);

    useEffect( () => {
        if (country.selectedCountry && country.selectedCountry.label) {
            serviceType = serviceType.replace('&', '%26')
            let url = `/user/getpro?service_name=${serviceType}&country=${country.selectedCountry.label}`;
            
            axios.get(url)
                .then(response => {
                    if(response.status === 200) {
                        setProvidersData(response.data.data)
                        // let servicesListArray = response.data
                        // let servicesList = servicesListArray.data.map((service) => {
                        //     return { value: service.id, label: service.name}
                        // })
                        // setServices(servicesList)
                        // if(selectedService){
                        //     setSelectedService(selectedService)
                        // }
                    }
                })
                .catch(err => {
                    setProvidersData([])
                    console.log(err)
                });  
        }
    }, [country.selectedCountry, serviceType]);

    const getProfilePic = (tProvidersData) => {
        if (tProvidersData && tProvidersData.profile_id) {
            return `https://acdito.com/v1/api/files/${tProvidersData.profile_id}`;
        }

        return providerProfileImgDummy;
    }

    return (
        <>
            <Helmet>
                <title>All Services</title>
                <meta property="og:title" content="AcDiTo - Find verified local professionals in minutes - It's free and simple" />
                <meta name="description" content="Find the perfect professional for your services needs" />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="300px" search={true} title1="Easily find and hire trusted local" title2 ="professionals in" />
            <div className="container full-center my-5 service-provider-page">
                <div className="row">
                    <div className="col-12"><h4 className="title-text link-blue fwBold">{serviceType}</h4></div>
                    <div className="col-12">
                        <div>
                            <i className="fas fa-map-marker-alt mr-2"></i>
                            <span>{country.selectedCountry.label}</span>
                            {/* <img className="img-fluid ml-2" alt="" src={downarrow} width="15" height="15" /> */}
                        </div>
                    </div>
                    <div className="col-12 my-3">
                        <div className="section-seprator"></div>
                    </div>
                    <div className="col-12 service-btns-container mt-2 mb-3">
                        <button type="button" className="btn lp-button1 mr-2 active">{serviceType}</button>
                        {/* <button type="button" className="btn lp-button1 mr-2">{'architect'}</button>
                        <button type="button" className="btn lp-button1 mr-2">{'Painter'}</button>
                        <button type="button" className="btn lp-button1 mr-2">{'house builder'}</button> */}
                    </div>

                    {providersData && providersData.length > 0 &&
                        providersData.map((data, index) => {
                            return <div className="col-12 col-md-6 my-2 service-profile-multi-card" key={index}>
                                <ServiceProfileCard 
                                    name={data.first_name + " " + data.last_name.charAt(0)}
                                    image={getProfilePic(data)}
                                    desc={data.service_description}
                                    isVerified={providersData.is_verified}
                                    location={data.country_name}
                                    hires={"Hires on AcDiTo"}
                                    hiresCnt={data.order_count || 0}
                                    reviews={`(${data.rating_count || 0})`}
                                    stars={data.rating || 0}
                                    profile={`/details/${serviceName}/${data.id}`}
                                />
                            </div>
                        })
                    }

                    {providersData.length == 0 &&
                        <div className='col-12' style={{textAlign: "center"}}>
                        <div style={{margin: "50px 0px"}}>No Service Provider available at the moment...</div>
                        </div>
                    }
                    
                </div>
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default serviceProviders
