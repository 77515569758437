import React, { useState, useEffect } from 'react';
import axios from 'services/axios';
import images from '../../assets/img/index';
import mapmarker from '../../assets/img/mapmarkergray.svg'
import ServiceWidget from '../Widgets/ServiceWidget';
import Select from '../elements/Select';
import { updateSelectedCountry } from "features/country";
import { useDispatch, useSelector } from "react-redux";

function ServiceSearch() {
    const dispatch = useDispatch();
    const country = useSelector((state) => state.country.value);
    const [services, setServices] = useState([]);

    const getServices = (countryName) => {
        if(countryName) {
            axios.get(`/service?country=${countryName}`)
            .then(response => {
                if(response.status === 200 && response.statusText === 'OK') { 
                    let data = response.data.data.map((curr) => {
                        return {value: curr.id, label: curr.name}
                    })
                    setServices(data);
                }
            })
        }
    }

    useEffect(() => {
        if (country.selectedCountry && country.selectedCountry.label) {
            getServices(country.selectedCountry.label);
        }
    }, [country.selectedCountry]);

    return (
        <div className="searchService row">
            <div className="lp-input-group-label rightBorderRadius col-1">
                <img src={mapmarker} alt="" className="img-responsive" />
            </div>
            <div className="col-3 px-0 coutrySearch">
                <Select
                    options={country.allCountries}
                    emptyOption={false}
                    inputView={true}
                    emptyOptionLabel={''}
                    value = {country.selectedCountry}
                    isDisabled={false}
                    onChange={ (value) => {
                        dispatch(updateSelectedCountry(value));
                        getServices(value.label);
                    }}
                    placeholder='Country'
                    style={{ border: '0px' }}
                    className="bgWhite form-control col-4"
                    searchIcon={false}
                />
            </div>
            <div className="col-8 px-0 serviceSearch">
                <ServiceWidget
                    className="form-control"
                    searchIcon={true}
                    serviceArray={services}
                    onChange={ (value) => { console.log("value:", value) }}
                />
            </div>
        </div>
    )
}

export default ServiceSearch
