import banner1 from './banner1.png';
import landingimage1 from './landingimage1.png';
import landingimage2 from './landingimage2.png';
import landingimage3 from './landingimage3.png';
import landingimage4 from './landingimage4.png';
import landingimage5 from './landingimage5.png';
import landingimage6 from './landingimage6.png';
import androidicon from './androidicon.png';
import appleicon from './appleicon.png';
import googleplayicon from './googleplayicon.png';
import trustedby01 from './trustedby01.png';
import trustedby02 from './trustedby02.png';
import trustedby03 from './trustedby03.png';
import trustedby04 from './trustedby04.png';
import trustedby05 from './trustedby05.png';
import discordicon from './discordicon.png';
import twittericon from './twittericon.png';
import linkedinicon from './linkedinicon.png';
import pfi from './pfi.png';
import educef from './educef.jpg';
import reco from './reco.png';
import partner1 from './partner1.png';
import mdf from './mdf.png';
import abegya from './abegya.png';
import queststone from './queststone.png';
import tokenpage1 from './tokenpage1.png';
import tokenpage2 from './tokenpage2.png';
import tokenpage3 from './tokenpage3.png';
import tokenpage4 from './tokenpage4.png';
import gilbert_image from './gilbert_mbeh.png';
import hasrh_image from './harsh.png';
import christian_image from './christian.png';
import walters_image from './walters.png';
import logo from './logo.png';

export default {
    banner1,
    landingimage1,
    landingimage2,
    landingimage3,
    landingimage4,
    landingimage5,
    landingimage6,
    androidicon,
    appleicon,
    googleplayicon,
    trustedby01,
    trustedby02,
    trustedby03,
    trustedby04,
    trustedby05,
    discordicon,
    twittericon,
    linkedinicon,
    pfi,
    educef,
    reco,
    partner1,
    mdf,
    abegya,
    queststone,
    tokenpage1,
    tokenpage2,
    tokenpage3,
    tokenpage4,
    gilbert_image,
    hasrh_image,
    christian_image,
    walters_image,
    logo
};
