import React from 'react';
import { Helmet } from 'react-helmet';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import classes from './style.module.scss';
import successImg from 'assets/img/success-img.gif';
import { Link, useHistory } from 'react-router-dom';

const PaymentSuccess = () => {
    const history = useHistory();

    return (
        <>
            <Helmet>
                <title>Payment Successful</title>
            </Helmet>
            <PagesHeader />
            <div className={`full-center ${classes.wrapper}`}>
                <div>
                    <img className={classes.img} src={successImg} alt="12" />
                </div>
                <div className={classes.title}>
                    Payment Successful
                </div>
                <div className={classes.msg}>
                    Your payment was successfull. Click <Link to="/">here</Link> to go home.
                </div>
                <div>
                    <button className="lp-primary-btn fwBold" onClick={() => history.push('/review')}>
                        Write Review & Rating For Pro
                    </button>
                </div>
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default PaymentSuccess;