export const getCurrnecyCode = () => {
    let currUser = JSON.parse(localStorage.getItem("currUser"));

    if (currUser.country_setting_code) {
        return currUser.country_setting_code;
    }

    return '';
}

export const setCurrnecyCode = () => {
    let currUser = JSON.parse(localStorage.getItem("currUser"));

    if (currUser.country_setting_code) {
        localStorage.setItem("currUser", JSON.stringify(currUser));
    }
}