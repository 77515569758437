import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card'
import StarRatings from 'react-star-ratings'

import verifyIcon from '../../assets/img/verify.png'
import ReadMoreLess from 'components/ReadMoreLess';

function ServiceProfileCard(props) {
    const history = useHistory();
    const gotoProfile = () => {
        history.push(props.profile)
    }
    return (
        <Card className='service-profile-card'>
            <Card.Body className="row">
                { props.image && <div className="col-3 col-sm-2 col-md-3 col-lg-2">
                    <div>
                        <Card.Img className="img-fluid service-provider-card-img" variant="top" src={props.image} />
                    </div>
                </div> }
                <div className={props.image ? "col-9 col-sm-10 col-md-9 col-lg-10" : "col-12"}>
                    <Card.Title className="mb-0"><span className="mr-2">{props.name}</span> {props.isVerified && <img className="im-fluid" src={verifyIcon} alt='' /> }</Card.Title>
                    {props.reviews && 
                        <div className="d-flex justify-content-start align-items-center mb-2">
                            <span className="mr-1 pt-1">{props.stars}</span>
                            <StarRatings
                                rating={props.stars}
                                starRatedColor={'#00b9f2'}
                                starHoverColor={'#00b9f2'}
                                numberOfStars={5}
                                name='rating'
                                starDimension={'20px'}
                                starSpacing={'0'}
                            />
                            <span className="ml-1 pt-1">{props.reviews}</span>
                        </div>
                    }
                    {props.location && 
                        <Card.Text>
                            <i className="fas fa-map-marker-alt mr-2"></i>
                            <span>{props.location}</span>
                        </Card.Text>
                    }
                    {props.hires && 
                        <Card.Text>
                            <i className="fas fa-trophy mr-2"></i>
                            <span className="link-blue">{props.hiresCnt}</span> {props.hires}
                        </Card.Text>
                    }
                    {props.desc && 
                        <Card.Text className="service-card-desc">
                            <ReadMoreLess
                                charLimit={75}
                            >
                                {props.desc}
                            </ReadMoreLess>
                        </Card.Text>
                    }
                    {props.profile &&
                        <div role="button" onClick={gotoProfile} className="service-card-goto-btn text-white lp-button1 py-2 px-3">View Profile</div>
                    }
                </div>
            </Card.Body>
        </Card>
    )
}

export default ServiceProfileCard
