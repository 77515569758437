import { TextField } from '@mui/material';
import React from 'react';
import './style.scss';

const Input = (props) => {
    return (
        <TextField
            {...props}
            className={props.className ? `${props.className} custom-input`: 'custom-input'}
        />
    )
}

export default Input