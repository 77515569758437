import React, { useState, useEffect, useMemo } from 'react'
import {Helmet} from "react-helmet";
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import PageTitleSection from '../PageTitleSection';

import AllServicesIcon from '../../components/pages/AllServicesIcon';
import PagesHeader from '../../components/Navbars/PageHeader';

import services_header_img from '../../assets/img/services_header_img.png';

import plumbersIcon from '../../assets/img/all_services/plumbersIcon.png';
import architectIcon from '../../assets/img/all_services/architectIcon.png';
import paintersIcon from '../../assets/img/all_services/paintersIcon.png';
import houseBuildersIcon from '../../assets/img/all_services/houseBuildersIcon.png';
import roofRepairIcon from '../../assets/img/all_services/roofRepairIcon.png';
import electricalServicesIcon from '../../assets/img/all_services/electricalServicesIcon.png';
import cleaningServiceIcon from '../../assets/img/all_services/cleaningServiceIcon.png';
import gardenersIcon from '../../assets/img/all_services/gardenersIcon.png';
import wasteCollectorsIcon from '../../assets/img/all_services/wasteCollectorsIcon.png';
import acTechniciansIcon from '../../assets/img/all_services/acTechniciansIcon.png';
import concreteBrickStoneIcon from '../../assets/img/all_services/concreteBrickStoneIcon.png';
import pestControlIcon from '../../assets/img/all_services/pestControlIcon.png';
import interiorDecoratorsIcon from '../../assets/img/all_services/interiorDecoratorsIcon.png';
import homeSolarEnergyIcon from '../../assets/img/all_services/homeSolarEnergyIcon.png';
import homeSecurityIcon from '../../assets/img/all_services/homeSecurityIcon.png';
import waterDrillingIcon from '../../assets/img/all_services/waterDrillingIcon.png';
import handymenIcon from '../../assets/img/all_services/handymenIcon.png';

import COVID19HelpDesksIcon from '../../assets/img/all_services/COVID19HelpDesksIcon.png';
import psychologistIcon from '../../assets/img/all_services/psychologistIcon.png';
import travelVaccinesServiceIcon from '../../assets/img/all_services/travelVaccinesServiceIcon.png';
import ophthalmologistIcon from '../../assets/img/all_services/ophthalmologistIcon.png';
import pediatricianIcon from '../../assets/img/all_services/pediatricianIcon.png';
import dermatologistIcon from '../../assets/img/all_services/dermatologistIcon.png';
import dentistIcon from '../../assets/img/all_services/dentistIcon.png';
import massageTherapyIcon from '../../assets/img/all_services/massageTherapyIcon.png';
import nutritionistsIcon from '../../assets/img/all_services/nutritionistsIcon.png';
import danceLessonsIcon from '../../assets/img/all_services/danceLessonsIcon.png';
import fitnessLessonsIcon from '../../assets/img/all_services/fitnessLessonsIcon.png';

import graphic from '../../assets/img/service_categories/graphic.svg';
import heartbeat from '../../assets/img/service_categories/heartbeat.svg';
import graphicB from '../../assets/img/service_categories/graphic-b.svg';
import heartbeatB from '../../assets/img/service_categories/heartbeat-b.svg';
import outline from '../../assets/img/service_categories/outline.png';
import repairingService from '../../assets/img/service_categories/repairing-service.svg';
import repairingServiceB from '../../assets/img/service_categories/repairing-service-b.svg';

//Business Cat Icons
import accountAndTaxExpert from '../../assets/img/all_services/AccountingTaxExperts.png';
import exchangeBureaux from '../../assets/img/all_services/ExchangeBureaux.png';
import realEstateAgents from '../../assets/img/all_services/RealEstateAgents.png';
import personCoach from '../../assets/img/all_services/PersonalCoach.png';
import commercialAgents from '../../assets/img/all_services/CommercialAgents.png';
import webDesignServices from '../../assets/img/all_services/WebDesignServices.png';
import translationService from '../../assets/img/all_services/TranslationServices.png';
import printingService from '../../assets/img/all_services/PrintingServices.png';
import graphicDesigner from '../../assets/img/all_services/GraphicDesigners.png';
import eventPlanner from '../../assets/img/all_services/EventsPlanners.png';
import eCommerceConsulting from '../../assets/img/all_services/ECommerceConsulting.png';
import notaryServices from '../../assets/img/all_services/NotaryServices.png';
import legalExperts from '../../assets/img/all_services/LegalExperts.png';
import salesAgents from '../../assets/img/all_services/SalesAgents.png';
import contractLawyers from '../../assets/img/all_services/ContractLawyers.svg';
import companyRegistrationService from '../../assets/img/all_services/CompanyRegistrationServices.png';
import bankingService from '../../assets/img/all_services/BankingServices.svg';
import insuranceService from '../../assets/img/all_services/InsuranceServices.svg';
import eventServices from '../../assets/img/all_services/EventsServices.svg';
import homeTutoringServices from '../../assets/img/all_services/HomeTutoringServices.svg';
import englishLanguageTeacher from '../../assets/img/all_services/EnglishLanguageTeachers.svg';
import frenchLanguageTeacher from '../../assets/img/all_services/FrenchLanguageTeachers.svg';
import businessMentors from '../../assets/img/all_services/BusinessMentors.svg';
import freelanceWebDesigner from '../../assets/img/all_services/FreelanceWebDesigners.svg';
import freelanceTranslators from '../../assets/img/all_services/FreelanceTranslators.svg';
import immigrationLawyers from '../../assets/img/all_services/ImmigrationLawyers.svg';
import corporateLawyer from '../../assets/img/all_services/CorporateLawyer.svg';
import logoDesign from '../../assets/img/all_services/LogoDesign.svg';
import tourGuide from '../../assets/img/all_services/TourGuide.svg';
import videoEditing from '../../assets/img/all_services/VideoEditing.svg';
import shortVideoAds from '../../assets/img/all_services/ShortVideoAds.svg';
import socialMediaManager from '../../assets/img/all_services/SocialMediaManager.svg';
import corporateVideos from '../../assets/img/all_services/CorporateVideos.svg';
import subtitlingCaptions from '../../assets/img/all_services/Subtitling&Captions.svg';
import elearningVideoProd from '../../assets/img/all_services/eLearningVideoProduction.svg';
import localPhotography from '../../assets/img/all_services/LocalPhotography.svg';
import droneVideography from '../../assets/img/all_services/DroneVideography.svg';
import productPhotography from '../../assets/img/all_services/ProductPhotography.svg';
import marketResearch from '../../assets/img/all_services/MarketResearch.svg';
import businessPlan from '../../assets/img/all_services/BusinessPlans.svg';
import legalConsulting from '../../assets/img/all_services/LegalConsulting.svg';
import virtualAssitant from '../../assets/img/all_services/VirtualAssistant.svg';
import financialConsulting from '../../assets/img/all_services/FinancialConsulting.svg';
import hrConsulting from '../../assets/img/all_services/HrConsulting.svg';
import supplyChainManagement from '../../assets/img/all_services/SupplyChainManagement.svg';
import careerCounseling from '../../assets/img/all_services/CareerCounseling.svg';
import projectManagement from '../../assets/img/all_services/ProjectManagement.svg';
import flyerDistribution from '../../assets/img/all_services/FlyerDistribution.svg';
import lifeCoaching from '../../assets/img/all_services/LifeCoaching.svg';
import personalStylist from '../../assets/img/all_services/PersonalStylists.svg';
import fashionDesigner from '../../assets/img/all_services/FashionDesigners.svg';
import tailorAndSeamstress from '../../assets/img/all_services/TailorsandSeamstress.svg';
import embroideryServices from '../../assets/img/all_services/EmbroideryServices.svg';
import dryCleaning from '../../assets/img/all_services/DryCleaning.svg';
import electronicRepair from '../../assets/img/all_services/ElectronicsRepairs.svg'
import legalWriting from '../../assets/img/all_services/LegalWriting.svg';
import leagalResearch from '../../assets/img/all_services/LegalResearch.svg';
import legalServices from '../../assets/img/all_services/LegalServices.svg';
import legalDocument from '../../assets/img/all_services/LegalDocuments.svg';
import legalContract from '../../assets/img/all_services/LegalContract.svg';
import legalDrafting from '../../assets/img/all_services/LegalDrafting.svg';
import flyersDesignPrint from '../../assets/img/all_services/FlyersDesign&Print.svg';
import labDesignSpecialist from '../../assets/img/all_services/LabDesignSpecialists.svg';
import securityServices from '../../assets/img/all_services/SecurityServices.svg';
import microfinanceConsultant from '../../assets/img/all_services/MicrofinanceConsultants.svg';
import legalAdvice from '../../assets/img/all_services/LegalAdvice.svg';
import modalsForBrand from '../../assets/img/all_services/ModelsforBrand.svg';
import contentCreator from '../../assets/img/all_services/ContentCreator.svg';
import contentEditingServices from '../../assets/img/all_services/ContentEditingServices.svg';
import landscapeDesign from '../../assets/img/all_services/LandscapeDesign.svg';
import localAdviser from '../../assets/img/all_services/LocalAdviser.svg';
import PagesFooterSub from 'components/Footer/PageFooterSub';

// import '../../assets/scss/pages/allServices.css';

const SERVICES = {
    homeImprovement: 'homeImprovement',
    health: 'health',
    business: 'business'
}

function AllServices(props) {
    const history = useHistory();

    const servicesList = {
        [SERVICES.homeImprovement]: [
            {'link':'/service/plumbers', 'icon':plumbersIcon, 'name':'Plumbers'},
            {'link':'/', 'icon':architectIcon, 'name':'Architect'},
            {'link':'/', 'icon':paintersIcon, 'name':'Painters'},
            {'link':'/', 'icon':houseBuildersIcon, 'name':'House Builders'},
            {'link':'/', 'icon':roofRepairIcon, 'name':'Roof Repair'},
            {'link':'/', 'icon':electricalServicesIcon, 'name':'Electrical Services'},
            {'link':'/', 'icon':cleaningServiceIcon, 'name':'Cleaning Service'},
            {'link':'/', 'icon':gardenersIcon, 'name':'Gardeners'},
            {'link':'/', 'icon':wasteCollectorsIcon, 'name':'Waste Collectors'},
            {'link':'/', 'icon':acTechniciansIcon, 'name':'AC Technicians'},
            {'link':'/', 'icon':concreteBrickStoneIcon, 'name':'Concrete Brick & Stone'},
            {'link':'/', 'icon':pestControlIcon, 'name':'Pest Control'},
            {'link':'/', 'icon':interiorDecoratorsIcon, 'name':'Interior Decorators'},
            {'link':'/', 'icon':homeSolarEnergyIcon, 'name':'Home Solar Energy'},
            {'link':'/', 'icon':homeSecurityIcon, 'name':'Home Security'},
            {'link':'/', 'icon':waterDrillingIcon, 'name':'Water Drilling'},
            {'link':'/', 'icon':handymenIcon, 'name':'Handymen'},
        ],
        [SERVICES.health]: [
            {'link':'/', 'icon':COVID19HelpDesksIcon, 'name':'COVID19 Help Desks'},
            {'link':'/', 'icon':psychologistIcon, 'name':'Psychologist'},
            {'link':'/', 'icon':travelVaccinesServiceIcon, 'name':'Travel Vaccines Service'},
            {'link':'/', 'icon':ophthalmologistIcon, 'name':'Ophthalmologist'},
            {'link':'/', 'icon':pediatricianIcon, 'name':'Pediatrician'},
            {'link':'/', 'icon':dermatologistIcon, 'name':'Dermatologist'},
            {'link':'/', 'icon':dentistIcon, 'name':'Dentist'},
            {'link':'/', 'icon':massageTherapyIcon, 'name':'Massage Therapy'},
            {'link':'/', 'icon':nutritionistsIcon, 'name':'Nutritionists'},
            {'link':'/', 'icon':danceLessonsIcon, 'name':'Dance Lessons'},
            {'link':'/', 'icon':fitnessLessonsIcon, 'name':'Fitness Lessons'},
        ],
        [SERVICES.business] : [
            {'link':'/', 'icon':accountAndTaxExpert, 'name':'Accounting & Tax Experts'},
            {'link':'/', 'icon':exchangeBureaux, 'name':'Exchange Bureaux'},
            {'link':'/', 'icon':realEstateAgents, 'name':'Real Estate Agents'},
            {'link':'/', 'icon':personCoach, 'name':'Personal Coach'},
            {'link':'/', 'icon':commercialAgents, 'name':'Commercial Agents'},
            {'link':'/', 'icon':webDesignServices, 'name':'Web Design Services'},
            {'link':'/', 'icon':translationService, 'name':'Translation Services'},
            {'link':'/', 'icon':printingService, 'name':'Printing Services'},
            {'link':'/', 'icon':graphicDesigner, 'name':'Graphic Designers'},
            {'link':'/', 'icon':eventPlanner, 'name':'Events Planners'},
            {'link':'/', 'icon':eCommerceConsulting, 'name':'E-Commerce Consulting'},
            {'link':'/', 'icon':notaryServices, 'name':'Notary Services'},
            {'link':'/', 'icon':legalExperts, 'name':'Legal Experts'},
            {'link':'/', 'icon':salesAgents, 'name':'Sales Agents'},
            {'link':'/', 'icon':contractLawyers, 'name':'Contract Lawyers'},
            {'link':'/', 'icon':companyRegistrationService, 'name':'Company Registration Services'},
            {'link':'/', 'icon':bankingService, 'name':'Banking Services'},
            {'link':'/', 'icon':insuranceService, 'name':'Insurance Services'},
            {'link':'/', 'icon':eventServices, 'name':'Events Services'},
            {'link':'/', 'icon':homeTutoringServices, 'name':'Home Tutoring Services'},
            {'link':'/', 'icon':englishLanguageTeacher, 'name':'English Language Teachers'},
            {'link':'/', 'icon':frenchLanguageTeacher, 'name':'French Language Teachers'},
            {'link':'/', 'icon':businessMentors, 'name':'Business Mentors '},
            {'link':'/', 'icon':freelanceWebDesigner, 'name':'Freelance Web Designers'},
            {'link':'/', 'icon':freelanceTranslators, 'name':'Freelance Translators'},
            {'link':'/', 'icon':immigrationLawyers, 'name':'Immigration Lawyers'},
            {'link':'/', 'icon':corporateLawyer, 'name':'Corporate Lawyer'},
            {'link':'/', 'icon':logoDesign, 'name':'Logo Design'},
            {'link':'/', 'icon':tourGuide, 'name':'Tour Guide'},
            {'link':'/', 'icon':videoEditing, 'name':'Video Editing'},
            {'link':'/', 'icon':shortVideoAds, 'name':'Short Video Ads'},
            {'link':'/', 'icon':socialMediaManager, 'name':'Social Media Manager'},
            {'link':'/', 'icon':corporateVideos, 'name':'Corporate Videos'},
            {'link':'/', 'icon':subtitlingCaptions, 'name':'Subtitling & Captions'},
            {'link':'/', 'icon':elearningVideoProd, 'name':'eLearning Video Production'},
            {'link':'/', 'icon':localPhotography, 'name':'Local Photography'},
            {'link':'/', 'icon':droneVideography, 'name':'Drone Videography'},
            {'link':'/', 'icon':productPhotography, 'name':'Product Photography'},
            {'link':'/', 'icon':marketResearch, 'name':'Market Research'},
            {'link':'/', 'icon':businessPlan, 'name':'Business Plans'},
            {'link':'/', 'icon':legalConsulting, 'name':'Legal Consulting'},
            {'link':'/', 'icon':virtualAssitant, 'name':'Virtual Assistant'},
            {'link':'/', 'icon':financialConsulting, 'name':'Financial Consulting'},
            {'link':'/', 'icon':hrConsulting, 'name':'HR Consulting'},
            {'link':'/', 'icon':supplyChainManagement, 'name':'Supply Chain Management'},
            {'link':'/', 'icon':careerCounseling, 'name':'Career Counseling'},
            {'link':'/', 'icon':projectManagement, 'name':'Project Management'},
            {'link':'/', 'icon':flyerDistribution, 'name':'Flyer Distribution'},
            {'link':'/', 'icon':lifeCoaching, 'name':'Life Coaching'},
            {'link':'/', 'icon':personalStylist, 'name':'Personal Stylists'},
            {'link':'/', 'icon':fashionDesigner, 'name':'Fashion Designers'},
            {'link':'/', 'icon':tailorAndSeamstress, 'name':'Tailors and Seamstress'},
            {'link':'/', 'icon':embroideryServices, 'name':'Embroidery Services'},
            {'link':'/', 'icon':dryCleaning, 'name':'Dry Cleaning'},
            {'link':'/', 'icon':electronicRepair, 'name':'Electronics Repairs '},
            {'link':'/', 'icon':legalWriting, 'name':'Legal Writing'},
            {'link':'/', 'icon':leagalResearch, 'name':'Legal Research'},
            {'link':'/', 'icon':legalServices, 'name':'Legal Services'},
            {'link':'/', 'icon':legalDocument, 'name':'Legal Documents'},
            {'link':'/', 'icon':legalContract, 'name':'Legal Contract'},
            {'link':'/', 'icon':legalDrafting, 'name':'Legal Drafting'},
            {'link':'/', 'icon':flyersDesignPrint, 'name':'Flyers Design & Print'},
            {'link':'/', 'icon':labDesignSpecialist, 'name':'Lab Design Specialists'},
            {'link':'/', 'icon':securityServices, 'name':'Security Services'},
            {'link':'/', 'icon':microfinanceConsultant, 'name':'Microfinance Consultants'},
            {'link':'/', 'icon':legalAdvice, 'name':'Legal Advice'},
            {'link':'/', 'icon':modalsForBrand, 'name':'Models for Brand'},
            {'link':'/', 'icon':contentCreator, 'name':'Content Creator'},
            {'link':'/', 'icon':contentEditingServices, 'name':'Content Editing Services'},
            {'link':'/', 'icon':landscapeDesign, 'name':'Landscape Design'},
            {'link':'/', 'icon':localAdviser, 'name':'Local Adviser'},
        ]
    }
    const [activeService, setActiveService] = useState(SERVICES.homeImprovement);

    let handleServiceCategoryChange = (event, data) => {
        setActiveService(data);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    const services = useMemo(() => servicesList[activeService], [activeService]);

    return (
        <>
            <Helmet>
                <title>All Services</title>
                <meta property="og:title" content="AcDiTo - All Services | Discover and Hire the Best Local Services in Africa" />
                <meta name="description" content="Explore all services on AcDiTo, the platform connecting you with reliable local businesses in Africa. Discover and hire the best providers near you. View reviews, ratings, and locations before hiring. Manage projects and payments from anywhere." />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="400px" image={services_header_img} search={true} blueTitle="All Services" title1="Explore the fast growing list of" title2 ="services available on AcDiTo" />
            <div className="container my-5 all-service-page">
                <div className="row">
                    <div className="col-12 col-md-3 mb-3" >
                        <div className="row serviceCategorySidebar">
                            <div className="col-12 py-3 greyBottomBorder" >
                                <h5 className="mb-0 text-bold px-2 fwBold">Service Categories</h5>
                            </div>
                            <div className="col-12">
                                <ul className="list-unstyled fs18 px-2">
                                    <li 
                                        className={clsx('mb-3 mt-2 serviceCategory', activeService === SERVICES.homeImprovement && 'activeLink')} 
                                        onClick={(event) => handleServiceCategoryChange(event, SERVICES.homeImprovement)}
                                    >
                                        <img src={repairingService} className={clsx('mr-2', activeService === SERVICES.homeImprovement && 'd-none')} />
                                        <img src={repairingServiceB} className={clsx('mr-2', activeService !== SERVICES.homeImprovement && 'd-none')} />
                                        Home Improvement
                                    </li>
                                    <li 
                                        className={clsx('mb-3 serviceCategory', activeService === SERVICES.health && 'activeLink')} 
                                        onClick={(event) => handleServiceCategoryChange(event, SERVICES.health)}
                                    >
                                        <img src={heartbeat} className={clsx('mr-2', activeService === SERVICES.health && 'd-none')} />
                                        <img src={heartbeatB} className={clsx('mr-2', activeService !== SERVICES.health && 'd-none')} />
                                        Health &#38; Wellness
                                    </li>
                                    <li 
                                        className={clsx('mb-3 serviceCategory', activeService === SERVICES.business && 'activeLink')}
                                        onClick={(event) => handleServiceCategoryChange(event, SERVICES.business)}
                                    >
                                        <img src={graphic} className={clsx('mr-2', activeService === SERVICES.business && 'd-none')} />
                                        <img src={graphicB} className={clsx('mr-2', activeService !== SERVICES.business && 'd-none')} />
                                        Business Services
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 py-3 fs18 greyTopBorder" role="button" onClick={() => history.push('/gift-service')}>
                                <img alt="" src={outline} className="mr-2 px-2" />Gift A Service
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="row">
                            {services.map((service) => {
                                return <AllServicesIcon 
                                            key={service.name}
                                            link={'/service/'+service.name.split(' ').join('-')}
                                            icon={service.icon}
                                            name={service.name} 
                                        />
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <PagesFooterSub />
        </>
    )
}

export default AllServices