import axios from 'services/axios';

export const getAllCategories = async () => {
    try {
        return await axios.get('/servicecategory');
    } catch(e) {
        console.log(e);
    }
}

export const getSpecialities = async (selectedService) => {
    try {
        return await axios.get(`/service/getbycategory/${selectedService}`);
    } catch(e) {
        console.log(e);
    }
}