import React, { useEffect, useState } from 'react';
import { getCurrnecyCode } from 'lib/getCurrencyCode';
import Dropdown from 'components/Controls/Dropdown';
import Input from 'components/Controls/Input';
import _ from 'lodash';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ReadOnlyDetails from 'components/ReadOnlyDetails';
import Error from 'components/Error';
import { CircularProgress, InputAdornment } from '@mui/material';
import AxiosService from 'services/axios';
import { toast } from 'react-toastify';

const schema = yup.object({
    // mobile: yup.string().required('Mobile is required'),
    amount: yup.string().required('Withdraw amount is required'),
}).required();

const WithdrawMoney = (props) => {
    const { momoOptions } = props;
    const { handleSubmit, control, formState: { errors }, setValues, setError, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            amount: ''
        }
    });
    const [totalBalance, setTotalBalance] = useState();
    const [primaryN, setPrimaryN] = useState();
    const [inProgress, setInProgress] = useState(false);
    const [paymentId, setPaymentId] = useState();
    const [startStatusApi, setStartStatusApi] = useState(false);

    useEffect(() => {
        AxiosService.get('/account/getaccmeta').then((res) => {
            if (res.status === 200 && res.data.data && res.data.data.acc_mobile) {
                setTotalBalance(_.toNumber(res.data.data?.balance));
                setPrimaryN(res.data.data?.acc_mobile);
            } else {
                toast.error('Please link MoMo account.');
            }
        })
    }, []);
    
    useEffect(() => {
        if (startStatusApi && paymentId) {
            const checkStatus = () => {
                AxiosService.get(`/pymt/withdraw/${paymentId}`)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && 'status' in response.data) {
                            if (response.data.status === 'completed') {
								 toast.success('Withdraw request is successfully processed.', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
								 });
								setValue('amount', '');
                                setInProgress(false);
                                setPaymentId(undefined);
                                setStartStatusApi(false);
								setTotalBalance(_.toNumber(response.data.balance));
                            } else if (response.data.status === 'failed') {
                                toast.error('Withdraw request failed. Please try again later.');
                                setInProgress(false);
                                setPaymentId(undefined);
                                setStartStatusApi(false);
                            }
                        }
                    }
                })
            };
            checkStatus();
            const interval = setInterval(() => checkStatus(), 5000);
            return () => {
              clearInterval(interval);
            }
        }
    }, [startStatusApi]);

    const onSubmit = (data) => {
        const dWithDrawAmount = _.toNumber(data.amount);
        if (dWithDrawAmount > totalBalance) {
            setError('amount', { type: 'custom', message: 'Insufficient balance' });
            return;
        }
        setInProgress(true);
        AxiosService.post('/pymt/withdraw', {
            amount: dWithDrawAmount
        }).then((res) => {
            if (res.status === 200) {
                setPaymentId(res.data._uid);
                setStartStatusApi(true);
            }
        }).catch(() => {
            setInProgress(false);
        })
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {
                    totalBalance != undefined && (
                        <>
                            <div className="col-12 mt-4">
                                <ReadOnlyDetails title="Earnings Balance" data={`${getCurrnecyCode()} ${totalBalance.toFixed(2)}`} />
                            </div>
                            {/* {
                                momoOptions && getValues('mobile') && (
                                    <div className="col-12 mt-4">
                                        <Controller
                                            name={"mobile"}
                                            control={control}
                                            key={getValues('mobile')}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropdown
                                                    options={momoOptions}
                                                    onChange={onChange}
                                                    value={value}
                                                    label="MoMo Account"
                                                    id="mobile"
                                                />
                                            )}
                                        />
                                        <Error text={errors.mobile?.message} />
                                    </div>
                                )
                            } */}
                            <div className="col-12 mt-4">
                                <Controller
                                    name={"amount"}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            type="text"
                                            id="amount"
                                            label="Withdraw Amount"
                                            variant="outlined"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <b>{getCurrnecyCode()}</b>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            helperText={`Withdraw will be processed to the primary account ${primaryN}`}
                                            onChange={(event) => {
                                                if (_.isEmpty(event.target.value) || /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(event.target.value)) {
                                                    onChange(event)
                                                }
                                            }}
                                            disabled={inProgress}
                                            inputProps={{ inputMode: 'numeric' }}
                                            value={value}
                                        />
                                    )}
                                />
                                <Error text={errors.amount?.message} />
                            </div>
                            <div className="mt-4 text-center">
                                <button disabled={inProgress} type="submit" className="btn lp-button1 fwBold" style={{ minWidth: '78px',lineHeight: '16px' }}>
                                    {inProgress ? <CircularProgress style={{ color: '#fff' }} size={17} /> : 'Submit'}
                                </button>
                            </div>
                        </>
                    )
                }
            </form>
        </div>
    )
}

export default WithdrawMoney;