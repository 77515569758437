import googleplaystoredownloadicon from './downloadgoogleplaystore.png'
import img_1 from './Img_1.png';
import img_2 from './Img_2.png';
import img_3 from './Img_3.png';
import img_4 from './Img_4.png';
import Group_1061 from './Group_1061.png';
import Group_1062 from './Group_1062.png';
import Group_1063 from './Group_1063.png';
import Group_1064 from './Group_1064.png';
import Group_1065 from './Group_1065.png';
import Group_1066 from './Group_1066.png';
import giftservice1 from './giftservice1.png';
import corpservice1 from './corpservice1.png';
import istockphoto_carpenter from './istockphoto-916080038-2048x2048.png';
import istockphoto_electrician from './istockphoto-989528418-2048x2048.png';
import istockphoto_plumber from './istockphoto-1156875601-2048x2048.png';
import istockphoto_legalservice from './istockphoto-1007651732-2048x2048.png';
import istockphoto_dentist from './istockphoto-959498114-2048x2048.png';
import trustedby01 from './trustedby01.png';
import trustedby02 from './trustedby02.png';
import trustedby03 from './trustedby03.png';
import trustedby04 from './trustedby04.png';
import trustedby05 from './trustedby05.png';
import trustedbyIBM from './trustedbyIBM.png';
import googleplay_white from './googleplay_white.png';
import applestore_white from './applestore_white.png';
import fb_logo from './facebook-logo.png';
import twitter_logo from './twitter-social-logotype.png';
import linkedin_logo from './linkedin-logo.png';
import instagram_logo from './instagram-social-network-logo-of-photo-camera.png';
import youtube_logo from './youtube-logotype.png';
import cardprofilepic from './stock-photo-happy-african-american-businesswoman-using-digital-tablet-over-light-background-copy-space-1201168342.png';
import downArrow from './down-arrow.svg'
import landingslider1 from './landingslider1.png'
import landingslider2 from './landing-slider2.png'
import review1 from './review1.jpg'
import review2 from './review2.jpg'
import aws from './aws.png'
import providerProfileImg from './providerProfileImg.png'
import verifyIcon from './verify.png'

export default {
    googleplaystoredownloadicon,
    img_1,
    img_2,
    Group_1061,
    Group_1062,
    Group_1063,
    Group_1064,
    Group_1065,
    Group_1066,
    giftservice1,
    corpservice1,
    istockphoto_carpenter,
    istockphoto_electrician,
    istockphoto_plumber,
    istockphoto_legalservice,
    istockphoto_dentist,
    trustedby01,
    trustedby02,
    trustedby03,
    trustedby04,
    trustedby05,
    googleplay_white,
    applestore_white,
    fb_logo,
    linkedin_logo,
    twitter_logo,
    instagram_logo,
    youtube_logo,
    cardprofilepic,
    img_3,
    img_4,
    trustedbyIBM,
    downArrow,
    landingslider1,
    landingslider2,
    review1,
    review2,
    aws,
    providerProfileImg,
    verifyIcon
}