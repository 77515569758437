import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
    profileId: "",
    country_setting_code: "",
    country_name: "",
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState: { value: initialStateValue },
  reducers: {
    setUserProfileId: (state, action) => {
        state.value.profileId = action.payload;
    },
    setUserCountrySettingCode: (state, action) => {
        state.value.country_setting_code = action.payload;
    },
    setUserCountry: (state, action) => {
        state.value.country_name = action.payload;
    },
  },
});

export const { setUserProfileId, setUserCountrySettingCode, setUserCountry } = userDataSlice.actions;

export default userDataSlice.reducer;