import React, { useEffect } from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import StudentProgram from '../../assets/img/StudentProgram.png';
import MyProjects from '../../assets/img/MyProjects.png';
import OpenProjects from '../../assets/img/OpenProjects.png';
import HireProfessionals from '../../assets/img/HireProfessionals.png';


export default function GrowYourBusiness() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Grow Your Business</title>
                <meta property="og:title" content="AcDiTo - Grow Your Business | Reach More Customers with AcDiTo" />
                <meta key="og:description" property="og:description" content="Want to grow your business in Africa? Join AcDiTo and connect with consumers seeking reliable local services. Showcase your work, receive reviews, ratings, and feedback. Manage projects and payments from anywhere." />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="300px" image={StudentProgram} blueTitle="Grow Your Business" title1="AcDiTo is powering small businesses, contractors, 
and freelancers with access to global opportunities" />
            <div className="container my-5">
                <div className="row">
                    <div className="col-12 mb-5">
                        <p>Build your business by joining the fastest growing professional service marketplace where millions of consumers find top-notch 
                            professional service providers.</p>
                        <p>AcDiTo features a straightforward and successful approach to win new clients who expand your business. The principal goal of AcDiTo 
                            is to assist consumers with daily hurdles such as hiring an electrician,  lawyer, consultant, plumber, dentist, nutritionist, or a local contractor. 
                            It is as simple as sending a text message. By joining AcDiTo, you gain access to millions of potential customers across the world.</p>
                        <p>As an enlisted professional on AcDiTo, you will enjoy valuable benefits. Sign up and log onto our website or download our mobile app for 
                            <strong>Android</strong> or <strong>iOS</strong>. Customize your profile by listing your services, the location where you operate, and hours when you are open, so AcDiTo can 
                            advertise the services you offer. <strong>Professionals on AcDiTo have two portals through which they get hired and paid for services:</strong></p>
                    </div>

                    <div className="col-12 mb-5">
                        <div className="row">
                            <div className="col-md-4 t-c-min-767 mb-3">
                                <img src={MyProjects} className="img-fluid" alt="My Projects" />
                            </div>
                            <div className="col-1"></div>
                            <div className="col-md-7 d-flex flex-column justify-content-center">
                                <h3>My Projects</h3>
                                <p>Say a customer is searching for a type of service provider in your local area. 
                                She finds your profile and your ratings impress her. She sends you a direct 
                                request through the system, a unique opportunity where you would not have 
                                to compete with other providers to get the Project. This also provides an 
                                opportunity to deliver the service quicker and reinforce or boost your rating, 
                                which increases demand for your services by clients worldwide.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mb-5">
                        <div className="row">
                            <div className="col-md-7 d-flex flex-column justify-content-center">
                                <h3>Open Projects</h3>
                                <p>A new project is published on AcDiTo. It matches with the services you offer 
                                and your location area. AcDiTo sends you a notification In-App or via SMS/Email 
                                if enabled by you in your settings. You review the information provided by the 
                                customer and decide whether to bid for the business. This gives you the chance 
                                to select the work you want to bid on. Under Open Project, you will be competing 
                                with other professionals to get hired for the Project. </p>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-md-4 t-c-min-767 mb-3 mt-3">
                                <img src={OpenProjects} className="img-fluid" alt="Open Projects" />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 t-c-min-767 mb-3">
                                <img src={HireProfessionals} className="img-fluid" alt="Hire Professionals" />
                            </div>
                            <div className="col-1"></div>
                            <div className="col-md-7 d-flex flex-column justify-content-center">
                                <h3>Why do millions of people around the world now 
                                rely on AcDiTo to hire professionals? </h3>
                                <p>In one word: Validation. Enlisted professionals on AcDiTo undergo background 
                                checks and proof of qualification to perform the listed service.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container-fluid my-5 grow-your-business-list">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 py-5">
                                <h3>Some of AcDiTo benefit for Professionals</h3>
                                <ul className="list-unstyled my-3">
                                    <li><i className="fas fa-check-circle check-green mr-3"></i>Through the AcDiTo website and mobile app, pros can get projects easily and quickly, whether just starting or filling up busy calendars.</li>
                                    <li><i className="fas fa-check-circle check-green mr-3"></i>See the competitive landscape and conduct vital market research at any time.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid my-5">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <p className="col-12">As a local professional, small business operator, or independent contractor, you can count on AcDiTo to provide a state-of-the-art digital 
                            venue with unlimited opportunities to grow your business locally and globally.</p>
                        </div>
                        <div className="row"><Link className="link-blue col-12" to="signup">Sign-Up for a free account</Link></div>
                    </div>
                </div>
            </div>
            
            <PagesFooter />
        </>
    )
}
