import * as yup from "yup";

export default yup.object({
    b_first_name: yup.string().required('First name is required'),
    b_last_name: yup.string().required('Second name is required'),
    b_mobile: yup.string().required('Mobile is required'),
    b_country: yup.object(),
    b_email: yup.string(),
    city: yup.string().required('City is required'),
    location: yup.string().required('Location is required'),
    servicecategory_id: yup.number().required('Service category is required'),
    service_id: yup.number().required('Service is required'),
    requirement_description: yup.string(),
    serviceprovider_id: yup.object(),
    budget: yup.string().required('Service amount is required').test(
        'minBudget',
        'The minimum order value should be 500',
        function(item) {
            if (item && !isNaN(item) && parseFloat(item) < 500) {
                return false;
            }
            return true;
        }
      ),
}).required();