import React, {
    useState,
} from 'react';
import { Dialog, Fade, Backdrop, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';

const PreviewImage = (props) => {
    const [open, setOpen] = useState(false);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    return (
        <>
            <Box data-test-id="open-box" display="inline" onClick={openDialog}>
                {props.children}
            </Box>
            <Dialog
                open={open}
                onClose={closeDialog}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    className: 'preview-close-backdrop',
                    timeout: 300,
                }}
                classes={{
                    paper: 'preview-img-container',
                }}
            >
                <>
                    <CloseIcon className='preview-close-btn' onClick={closeDialog} />
                    <Fade in={open}>
                        <img src={props.src} alt="preview" width="100%" />
                    </Fade>
                </>
            </Dialog>
        </>
    );
};

export default PreviewImage;
