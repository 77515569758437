import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './style.scss';

const CustomTabs = ({ options,value, onChange, ...rest }) => {
    return (
        <Tabs className="custom-tabs" value={value} onChange={onChange} aria-label="basic tabs example">
            {
                options.map((option) => <Tab key={option.value} label={option.label} value={option.value} className="custom-tab" />)
            }
        </Tabs>
    )
}

export default CustomTabs;