import React, { useEffect } from 'react'
import {Helmet} from "react-helmet";
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import StudentProgram from '../../assets/img/StudentProgram.png';


function StudentsProgram() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Student Program</title>
                <meta property="og:title" content="AcDiTo - Student Program | Join Us and Learn from the Best in the Industry" />
                <meta key="og:description" property="og:description" content="Are you a student passionate about digital platforms and social impact? Join the AcDiTo Student Program and gain valuable skills and experience. Work on real projects, solve problems, and make a difference in Africa. Apply now." />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="300px" image={StudentProgram} blueTitle="AcDiTo Students &#38; Grads Program" title1="Let's Build The Future Together" />
            <div className="container my-5 students-program-page">
                <div className="row">
                    <div className="col-12 col-md-8 student-program-list mb-3" >
                        <span className="studentHeadText">Our versatile Students &#38; Grads program empowers undergraduate and graduate students to:</span>
                        <ul className="list-unstyled my-3">
                            <li><i className="fas fa-check-circle check-green mr-3"></i><span>Get first class local and international professional experience; connect with our career advisors and world class mentors in Africa or in the United States.</span></li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>Gain practical experience on multiple projects and learn more about our products and services deployed across communities in Africa and the United States.</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>Meet and connect with the entrepreneurs building and deploying real solutions to real societal problems. </li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>Meet new people, experience different cultures and communities, hone practical skills, share experience and learn from peers.</li>
                            <li><i className="fas fa-check-circle check-green mr-3"></i>Have fun outside of work through our monthly events, community connections, networking and other activities.</li>
                        </ul>
                        At AcDiTo, there's no limit to the impact you can make if equipped with the right tools. We will provide you with the tools and supports so you can achieve meaningful goals.
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="row internshipBlock p-4">
                            <div className="col-12 d-flex flex-column">
                                <span>If you are looking for an internship</span>
                                <span>position that will advance your</span>
                                <span>skills and career prospects,</span>
                                <span>we invite you to join us.</span>  
                            </div>
                            <div className="col-12 mt-4">
                                <button className="p-2">Start Your Internship with AcDiTo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <PagesFooter />
        </>
    )
}

export default StudentsProgram
