import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { find } from 'lodash';
import { withRouter } from 'react-router-dom';
import Select from '../elements/Select';

@observer
class ServiceWidgetDropdown extends Component {
    notLoadedYet = true;

    constructor(props) {
        super(props);
        this.state = {
            serviceArray: [],
            openMenu: false,
        };
    }
  
    prepareOptions = array => {
        return this.props.serviceArray;
        
    };
    
    handleInputChange = (query, { action }) => {
        if (action === "input-change") {
            this.setState({ openMenu: true });
        }
    };
    
    hideMenu = () => {
        this.setState({ openMenu: false });
    };

    render() {
        // const { loadingLookup, currentTasksLookup } = this.props.taskStore;
        const {
            value,
            onChange,
            disabled,
            emptyOption,
            emptyOptionLabel,
            label = '',
            searchIcon = false
        } = this.props;
        const options = this.prepareOptions();
        // const options = this.prepareOptions(currentTasksLookup);
        const currentSelect = find(options, o => {
            return String(o.value) === String(value);
        });

        const inputView = !!label;
        return (
            <div className={`input-block`}   style={{ width: '100%' }}>
                {inputView && <span className="input-block__label">{label}</span>}
                <Select
                    options={options}
                    emptyOption={emptyOption}
                    inputView={inputView}
                    emptyOptionLabel={emptyOptionLabel}
                    value={currentSelect}
                    isDisabled={disabled}
                    onChange={value => {
                        this.hideMenu();
                        this.props.history.push('/service/'+value.label.split(' ').join('-'));
                    }}
                    onInputChange={this.handleInputChange}
                    placeholder='Which service are you looking for ?'
                    style={{ border: '0px' }}
                    onBlur={this.hideMenu}
                    className="bgWhite"
                    searchIcon={searchIcon}
                    menuIsOpen={this.state.openMenu}
                />
            </div>
        );
    }
}

@observer
class ServiceWidget extends Component {
    render() {
        return <ServiceWidgetDropdown {...this.props} mode="managers" />;
    }
}

export default withRouter(ServiceWidget);