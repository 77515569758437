import React, { useState, useEffect } from 'react'
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Select from '../../components/elements/Select1';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import axios from 'services/axios';
import Alerts from '../../components/alerts/alerts'
import ReadOnlyDetails from 'components/ReadOnlyDetails';
import PreviewImage from 'components/PreviewImage';
import Input from 'components/Controls/Input';
import Dropdown from 'components/Controls/Dropdown';
import { toast } from 'react-toastify';
import { isFileImage } from 'lib/helpers';
import { maxSize } from 'lib/constants';
import { isUserLoggedIn } from 'lib/helpers';
import { useHistory } from 'react-router-dom';
import { getProfile } from 'services/profile';
import clsx from 'clsx';
import PageTitleSection from 'containers/PageTitleSection';
import { setUserProfileId } from 'features/user-data';

function SetupProfileForUser(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);
    const [isEdit, setIsEdit] = useState(false);
    const [first_name, setFirst_name] = useState(user.first_name);
    const [last_name, setLast_name] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [mobile, setMobile] = useState(user.mobile);
    const [current_position, setCurrent_position] = useState(user.current_position);
    const [sector, setSector] = useState(user.sector);
    const [state, setState] = useState(user.state);
    const [city, setCity] = useState(user.city);
    const [job_status, setJob_status] = useState(user.job_status);
    const [about_me, setAbout_me] = useState(user.about_me);
    const [countries, setCountries] = useState(null);
    const [country, setCountry] = useState('Nigeria');

    const [apiMessagae, setApiMessagae] = useState();
    const [allData, setAllData] = useState();

    // Error states
    const [first_nameError, setFirst_nameError] = useState('');
    const [last_nameError, setLast_nameError] = useState('');

    const [alertMsg, setAlertMsg] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [image, setImage] = useState({ preview: "", fileId: "" });

    const loadInit = async () => {
        const profileRes = await getProfile();
        let rData = profileRes.data.data;

        if (profileRes.status === 200) {
            if (rData.role != "serviceuser") {
                history.push('/');
            }
            setFirst_name(rData.first_name);
            setLast_name(rData.last_name);
            setEmail(rData.email);
            setMobile(rData.mobile);
            setCurrent_position(rData.current_position);
            setSector(rData.sector);
            setState(rData.state);
            setCity(rData.city);
            if (rData.job_status) {
                setJob_status(rData.job_status);
            }
            setAbout_me(rData.about_me);
            if (rData.country_name) {
                setCountry({ label: rData.country_name, value: rData.country_name });
            }
            if (rData.profile_id) {
                setImage({ preview: `https://acdito.com/v1/api/files/${rData.profile_id}`, fileId: rData.profile_id })
            }
            setAllData(rData);
        }
        axios.get('/countries')
            .then(response => {
                if (response.status === 200) {
                    let currCountries = response.data.serviceUserCountries;
                    let countryData = currCountries.map((data) => {
                        return { value: data.name, label: data.name }
                    })
                    setCountries(countryData);
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    useEffect(() => {
        if (!isUserLoggedIn()) {
            history.push('/login')
        }
        loadInit();
    }, []);

    const submitProfile = () => {
        if (!isEdit) {
            setIsEdit(!isEdit);
            return;
        }
        setApiMessagae(undefined);
        !first_name ? setFirst_nameError("Please Enter First Name.") : setFirst_nameError('')
        !last_name ? setLast_nameError("Please Enter Last Name.") : setLast_nameError('')

        if (first_name && last_name) {
            let profileData = {
                "first_name": first_name,
                "last_name": last_name,
                "current_position": current_position,
                "sector": sector,
                "about_me": about_me,
                "state": state,
                "job_status": job_status,
                "city": city,
                "country_name": country ? country.value : undefined,
                "profile_id": image.fileId === '' ? undefined : image.fileId,
            }

            axios.post('/user/profilesetup', profileData)
                .then(response => {
                    dispatch(setUserProfileId(profileData.profile_id));
                    if (response.status === 200) {
                        setApiMessagae({
                            type: 1,
                            msg: 'Profile updated successfully'
                        });
                        setTimeout(() => {
                            history.push('/');
                        }, 2000);
                    } else { return false }
                })
                .catch(err => {
                    let errmsg = err.response.data[Object.keys(err.response.data)[0]];
                    setAlertMsg(errmsg)
                    setShowAlert(false)
                    setShowAlert(true)
                    return false
                });
        }
    }

    const handleProfileChange = e => {
        if (e.target.files.length) {
            var fileSize = e.target.files[0].size; // in bytes
            if (fileSize > maxSize) {
                toast.error('File size exceeded the maximum allowed size');
                return false;
            }

            if (!isFileImage(e.target.files[0])) {
                toast.error('Please select an image');
                return false;
            }

            var data = new FormData();
            data.append('input_files', e.target.files[0]);

            axios.post('/files/upload/profile', data).then((res) => {
                if (res.data.files.length > 0) {
                    setImage({
                        preview: URL.createObjectURL(e.target.files[0]),
                        fileId: res.data.files[0]
                    });
                }
            });
        }
    };

    return (
        <>
            <PagesHeader />
            { showAlert && <Alerts msg={alertMsg} show={showAlert} type="danger" />}
            <PageTitleSection minHeight="120px" blueTitle="My Profile" />
            <div className="container-fluid my-5 setupProfile">
                <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="upload-photo">
                                <span className="fa-stack fa-2x mt-3 mb-2 bg-secondary rounded-circle upload-photo-icon">
                                    {
                                        image.preview ? (
                                            <div>
                                                <img src={image.preview} className="profile-pic" alt="dummy" width="300" height="300" />
                                                {
                                                    isEdit && (
                                                        <div className="rounded-circle upload-photo-icon-small">
                                                            <i className="fa fa-edit text-white"></i>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : <i className="fa fa-camera text-white"></i>
                                    }
                                </span>
                                <hr className="profile-pic-seperator" />
                                <div className="profile-pic-verify">
                                    {
                                        allData?.is_verified ? (
                                            <>
                                                <i className="fas fa-check text-success"></i>
                                                    Verified
                                            </>
                                        ) : (
                                                <>
                                                    <i className="fas fa-times text-danger"></i>
                                                    Not Verified
                                                </>
                                            )
                                    }
                                </div>
                            </label>
                            <input
                                type="file"
                                name="photo"
                                id="upload-photo"
                                style={{ display: "none" }}
                                onChange={handleProfileChange}
                                accept="image/*"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-xl-8 profile-controls">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="first_name"
                                            label="Name"
                                            variant="outlined"
                                            onChange={e => setFirst_name(e.target.value)}
                                            value={first_name}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Name" data={first_name} />
                                        )
                                }
                                {first_nameError ? <span style={{ color: "red" }}>{first_nameError}</span> : ''}
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="current_position"
                                            label="Current Position"
                                            variant="outlined"
                                            onChange={e => setCurrent_position(e.target.value)}
                                            value={current_position}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Current Position" data={current_position} />
                                        )
                                }
                                {last_nameError ? <span style={{ color: "red" }}>{last_nameError}</span> : ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="email"
                                            label="Email Address"
                                            variant="outlined"
                                            value={email}
                                            disabled={true}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Email Address" data={email} />
                                        )
                                }
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="sector"
                                            label="Sector"
                                            variant="outlined"
                                            value={sector}
                                            onChange={e => setSector(e.target.value)}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Sector" data={sector} />
                                        )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="mobile"
                                            label="Mobile No."
                                            variant="outlined"
                                            value={mobile}
                                            disabled={true}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Mobile No." data={mobile} />
                                        )
                                }
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Dropdown
                                            options={countries}
                                            onChange={(event) => {
                                                setCountry(event.target.value);
                                                setState('');
                                                setCity('');
                                            }}
                                            label="Select Country"
                                            className="signup-select"
                                            value={country}
                                            disabled
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Country" data={country?.label} />
                                        )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="about_me"
                                            label="About Me"
                                            variant="outlined"
                                            value={about_me}
                                            onChange={e => setAbout_me(e.target.value)}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="About Me" data={about_me} />
                                        )
                                }
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="State"
                                            label="State"
                                            variant="outlined"
                                            onChange={e => setState(e.target.value)}
                                            value={state}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="State" data={state} />
                                        )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Dropdown
                                            options={[
                                                { value: 'Employed', label: 'Employed' },
                                                { value: 'Self-Employed', label: 'Self-Employed' },
                                                { value: 'Unemployed', label: 'Unemployed' },
                                            ]}
                                            onChange={(event) => {
                                                setJob_status(event.target.value);
                                            }}
                                            value={{ value: job_status, label: job_status }}
                                            label="Job Status"
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Job Status" data={job_status} />
                                        )
                                }
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 mb-4 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="City"
                                            label="City"
                                            variant="outlined"
                                            onChange={e => setCity(e.target.value)}
                                            value={city}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="City" data={city} />
                                        )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 mb-4 form-group">
                                <button disabled={allData === undefined} type="button" onClick={submitProfile} className="btn btn-block send-button tx-tfm" >
                                    {isEdit ? 'Save Profile' : 'Edit Profile'}
                                </button>
                            </div>
                        </div>
                        {
                            apiMessagae && (
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <span className={clsx('h5', apiMessagae.type === 1 && 'text-success')}>{apiMessagae.msg}</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <PagesFooter />
        </>
    )
}

export default SetupProfileForUser
