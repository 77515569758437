import React, { useState, useEffect } from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import Select from '../../components/elements/Select1';
import { Redirect, useHistory } from 'react-router';
import Alerts from '../../components/alerts/alerts'

import { useDispatch } from "react-redux";
import { setUser } from "../../features/user";
import axios from 'services/axios';
import {Helmet} from "react-helmet";
import {Dropdown} from "react-bootstrap";
import { Link } from 'react-router-dom';
import linkdin from '../../assets/img/linkedin.svg';
import google from '../../assets/img/google.svg';

function Signup_functional(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [redirect, setRedirect] = useState(false);
    try {
        let currUser = JSON.parse(localStorage.getItem("currUser"))
        if(currUser.token && currUser.token !== '') {
            history.push('/');
        }
    }catch(e){}

    const [userType, setUserType] = useState('');
    const [country, setCountry] = useState('');    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    
    const [allCountryCodes, setAllCountryCodes] = useState(null);
    const [countryCodes, setCountryCodes] = useState(null);
    const [selectedCountryCode, setSelectedCountryCode] = useState('234_ng');
    //Error states
    const [userTypeError, setUserTypeError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [validMobile, setValidMobile] = useState(null);
    const [emailAddressError, setEmailAddressError] = useState('');
    const [validEmailAddress, setValidEmailAddress] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [validPassword, setValidPassword] = useState(null);
    
    const [userTypes, setUserTypes] = useState([
        { value: 'serviceuser', label: 'Service User' },
        { value: 'serviceprovider', label: 'Service Provider' }
    ]);
    const [countries, setCountries] = useState(null);
    
    const handleUsertypeChange = (data) => {
        setUserType(data.value);
        (data.value === "serviceuser") ? setCountryCodes(allCountryCodes.serviceUserCountries) : setCountryCodes(allCountryCodes.serviceProviderCountries)
        setSelectedCountryCode('234_ng')
    }
    
    const validateEmail = (email) => {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(email);
        if (result === true) {
            setValidEmailAddress(true)
            return true;
        } else {
            setValidEmailAddress(false)
            return false;
        }
    }
    
    const validateMobile = (mobile) => {
        if (mobile.length >= 8 && mobile.length <= 10) {
            setValidMobile(true)
            return true;
        } else {
            setValidMobile(false)
            return false;
        }
    }

    const validatePasswordLength = (pwd) => {
        if (pwd.length >= 8 && pwd.length <= 15) {
            setValidPassword(true)
            return true;
        } else {
            setValidPassword(false)
            return false;
        }
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const submitForm = () => {
        if (!userType) {
            setUserTypeError("Please select User Type.");
        }else if (userType) {
            setUserTypeError('');
        }

        if (!country) {
            setCountryError("Please select Country");
        }else if (country) {
            setCountryError('');
        }

        if (!firstName) {
            setFirstNameError("Please Enter First Name.");
        }else if (firstName) {
            setFirstNameError('');
        }

        if (!lastName) {
            setLastNameError("Please Enter Last Name.");
        }else if (lastName) {
            setLastNameError('');
        }

        if (!mobile) {
            setMobileError("Please Enter Mobile No.");
        }else if (mobile) {
            validateMobile(mobile) ? setMobileError('') : setMobileError('Mobile Number should be 8 to 10 digits.')
        }

        if (!emailAddress) {
            setEmailAddressError("Please Enter Email Address.");
        }
        if (emailAddress) {
            validateEmail(emailAddress) ? setEmailAddressError('') : setEmailAddressError('Please Enter Valid Email Address.');
        }

        if (!password) {
            setPasswordError("Please Enter Password.");
        }else if (password) {
            validatePasswordLength(password) ? setPasswordError('') : setPasswordError('Password must be between 8 and 15 characters.');
        }
        if (userType && country && firstName && lastName && mobile && emailAddress && password) {
            if( !(validateMobile(mobile) && validateEmail(emailAddress) && validatePasswordLength(password)) ) {
                return false;
            }
            let data = {
                first_name: firstName,
                last_name: lastName,
                email: emailAddress,
                password: password,
                country_code: '+'+selectedCountryCode.split('_')[0],
                country_name: country.value,
                mobile: mobile,
                role: userType
            }

            axios.post('/auth/register', data)
            .then(response => {
                if(response.status === 200 && response.statusText === 'OK') {
                    let userData = {
                        userType: userType,
                        country: country,
                        firstName: firstName,
                        lastName: lastName,
                        mobile: mobile,
                        emailAddress: emailAddress,
                        password: password,
                    }
                    dispatch(setUser(userData)); 
                    // props.history.push('/verification', { data: mobile });
                    setRedirect(true)
                } else { return false }
            })
            .catch(err => {
                let errmsg = err.response.data[Object.keys(err.response.data)[0]];
                setAlertMsg(errmsg)
                setShowAlert(false)
                setShowAlert(true)
                return false
            });            
        }

    }

    useEffect( () => {
        axios.get('/countries')
            .then(response => {
                if(response.status === 200 && response.statusText === 'OK') {
                    setAllCountryCodes(response.data)
                    let currCountries = (userType === "serviceuser" || userType === '') ? response.data.serviceUserCountries : response.data.serviceProviderCountries
                    setCountryCodes(currCountries)
                    let countryData = currCountries.map((data) => {
                        return { value: data.name, label: data.name}
                    })
                    setCountries(countryData)
                }
            })
            .catch(err => {
                console.log(err)
            });  
    }, [userType])

    return (
        <>
            {redirect && <Redirect to={{ pathname: "/verification", state: { mobile: mobile } }} />}
            <Helmet>
                <title>Sign Up</title>
                <meta key="meta:description" name="description" content="Sign up for free on AcDiTo, the platform connecting you with reliable local businesses in Africa. Find the best providers near you. View reviews, ratings, and locations before hiring. Manage projects and payments from anywhere." />
                <meta key="og:title" property="og:title" content="AcDiTo - Sign Up for Free | Connect with Reliable Local Services in Africa" />
                <meta key="og:description" property="og:description" content="Sign up for free on AcDiTo, the platform connecting you with reliable local businesses in Africa. Find the best providers near you. View reviews, ratings, and locations before hiring. Manage projects and payments from anywhere." />
            </Helmet>
            <PagesHeader />
            { showAlert && <Alerts msg={alertMsg} show={showAlert} type="danger" /> }
            <div className="container fmroboto mt-5 mb-5">
                <div className="col-md-6 mx-auto text-center">
                    <h1 className="signup-titlee">Join AcDiTo</h1>
                </div>
                <div className="row">
                    <div className="col-md-auto mx-auto">
                        <div className="myform form ">
                            <div >
                                <div className="form-group">
                                    <div className="mb_25 mt_20">
                                        <Select
                                            options={userTypes}
                                            onChange={value => handleUsertypeChange(value)}
                                            placeholder='Select User'
                                            style={{ width: '100%', height: '45px' }}
                                            className="signup-select"
                                        />
                                        {userTypeError ? <span style={{ color: "red" }}>{userTypeError}</span> : ''}
                                    </div>
                                    <div className="mb_25 mt_20">
                                        <Select
                                            options={countries}
                                            onChange={(value) => setCountry(value)}
                                            placeholder="Select Country"
                                            style={{ width: '100%', height: '45px' }}
                                            className="signup-select"
                                            value={country}
                                        />
                                        {countryError ? (
                                            <span style={{ color: 'red' }}>{countryError}</span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="mb_25 mt_20">
                                        <input type="text" className="form-control signup-input" placeholder="First Name" name="firstName" onChange={e => setFirstName(e.target.value.trim())} style={{ borderColor: firstNameError ? "red" : "" }} />
                                        {firstNameError ? <span style={{ color: "red" }}>{firstNameError}</span> : ''}
                                    </div>

                                    <div className="mb_25 mt_20">
                                        <input type="text" className="form-control signup-input" placeholder="Last Name" name="lastName" onChange={e => setLastName(e.target.value.trim())} style={{ borderColor: lastNameError ? "red" : "" }} />
                                        {lastNameError ? <span style={{ color: "red" }}>{lastNameError}</span> : ''}
                                    </div>

                                    <div className="mb_25 mt_20">
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                            <Dropdown className="d-flex align-items-stretch">
                                                <Dropdown.Toggle variant="secondary" className="country-select" id="dropdown-basic" key={selectedCountryCode}>
                                                    <img alt="country" src={"https://AcDiTo.com/v1/flag/"+ selectedCountryCode.split("_")[1] +".png"} /> +{ selectedCountryCode.split("_")[0] }
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="countryDropdown">
                                                    {
                                                        (countryCodes !== null) ? 
                                                        countryCodes.map((country) => {
                                                        return <Dropdown.Item onClick={() => {setSelectedCountryCode(country.code+"_"+country.iso_code.toLowerCase())}} key={country.name}><img alt="" src={"https://AcDiTo.com/v1/flag/"+ country.iso_code.toLowerCase() +".png"} /> +{country.code}</Dropdown.Item>
                                                        }) : null
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            </div>
                                            <input type="text" className="form-control signup-input" placeholder="Mobile No." name="mobile" onChange={e => setMobile(e.target.value.trim())} style={{ borderColor: mobileError ? "red" : "" }} />
                                        </div>
                                        {mobileError ? <span style={{ color: "red" }}>{mobileError}</span> : ''}
                                    </div>

                                    <div className="mb_25 mt_20">
                                        <input type="text" className="form-control signup-input" placeholder="Email Address" name="emailAddress" onChange={e => setEmailAddress(e.target.value.trim())} style={{ borderColor: emailAddressError ? "red" : "" }} />
                                        {emailAddressError ? <span style={{ color: "red" }}>{emailAddressError}</span> : ''}
                                        {/* {emailAddressInvalidError ? <span style={{ color: "red" }}>{emailAddressInvalidError}</span> : ''} */}
                                    </div>

                                    <div className="mb_25 mt_20 password-field">
                                        <div className="input-group mb-2">
                                            <input type={passwordShown ? "text" : "password"} className="form-control signup-input" placeholder="Password" name="password" onChange={e => setPassword(e.target.value.trim())} style={{ borderColor: passwordError ? "red" : "" }} />
                                            <div className="input-group-append">
                                                <button className="input-group-text bg-white" onClick={togglePassword}><i className={passwordShown ? "far fa-eye" : "far fa-eye-slash"}></i></button>
                                            </div>
                                        </div>
                                        {passwordError ? <span style={{ color: "red" }}>{passwordError}</span> : ''}
                                    </div>

                                </div>
                                <div className="text-center">
                                    <button type="button" className="btn btn-block send-button tx-tfm" onClick={submitForm}>Sign Up</button>
                                </div>

                                <div className="signup-help mt-4 mb-2">By joining, you agree to AcDiTo <span><Link to="/terms-condition" className="link-blue">Terms of Service</Link></span> and to receive occasional emails from us.
                                </div>
                                <div className="text-center mt-2 mb-2">
                                    <div className="signup-or">OR</div>
                                </div>

                                <div className="text-center mt-2 mb-2">
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-block linkdin-button tx-tfm">
                                            <img src={linkdin}/> Continue with Linkedin
                                        </button>
                                    </div>
                                </div>
                                <div className="text-center mt-2 mb-2">
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-block google-button tx-tfm">
                                            <img src={google}/> Continue with Google
                                        </button>
                                    </div>
                                </div>
                                <div className="signup-help mt-4 mb-2">Already have an account?  <span><Link to="/login" className="link-blue font-weight-bold">Sign In Now!</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <PagesFooter showFooter={false} />
        </>
    );
    
}

export default Signup_functional;