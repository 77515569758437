import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';

const MultiSelect = (allProps) => {
    const { selectProps, inputProps } = allProps;
    return (
        <Autocomplete
            {...selectProps}
            multiple
            getOptionLabel={(option) => {
                if (option) {
                    return option.label
                }
            }}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                    classes={{ root: "custom-multi-select-chip" }}
                    variant="outlined"
                    label={option.label}
                    size="medium"
                    deleteIcon={<CloseIcon />}
                    {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...inputProps}
                />
            )}
        />
    )
}

export default MultiSelect;