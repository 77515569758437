import React from 'react';
import { Helmet } from 'react-helmet';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import classes from './style.module.scss';
import successImg from 'assets/img/error-img.gif';
import { Link } from 'react-router-dom';

const PaymentFailed = () => {
    return (
        <>
            <Helmet>
                <title>Payment Failed</title>
            </Helmet>
            <PagesHeader />
            <div className={`full-center ${classes.wrapper}`}>
                <div>
                    <img className={classes.img} src={successImg} alt="12" />
                </div>
                <div className={classes.title}>
                    Payment Failed
                </div>
                <div className={classes.msg}>
                    Unfortunately an error has occurred and your payment cannot be processed at this time, please verify your payment details or try again later. Click <Link to="/">here</Link> to go home.
                </div>
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default PaymentFailed;