export const addRatingForUser = (data) => {
    localStorage.setItem('rtr', JSON.stringify(data));
}

export const getRatingForUser = () => {
    const lsT = localStorage.getItem('rtr');

    if (lsT) {
        return JSON.parse(lsT);
    }

    return undefined;
}

export const removeRatingUser = () => {
    localStorage.removeItem('rtr');
}