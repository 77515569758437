import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import classes from './style.module.scss';
import StarRatings from 'react-star-ratings';
import Input from 'components/Controls/Input';
import { useHistory } from 'react-router-dom';
import { getRatingForUser } from 'services/rating';
import { getProfilePic } from 'lib/getProfilePic';
import { removeRatingUser } from 'services/rating';
import { toast } from 'react-toastify';
import AxiosService from 'services/axios';
import providerProfileImgDummy from 'assets/img/user-244.png';

const RateReview = () => {
    const [rating, setRating] = useState(0);
    const [ratingUser, setRatingUser] = useState(0);
    const [message, setMessage] = useState('');
    const history = useHistory();

    useEffect(() => {
        const ratingUser = getRatingForUser();

        if (ratingUser) {
            setRatingUser(ratingUser);
        } else {
            history.replace('/')
        }
    }, []);

    const changeRating = ( newRating, name ) => {
       setRating(newRating);
    }
    
    const handleOnError = (e) => {
        e.target.src = providerProfileImgDummy;
    }

    const getRatingName = () => {
        switch(rating) {
            case 5:
                return 'Excellent';
            case 4:
                return 'Good';
            case 3:
                return 'Fair';
            case 2:
                return 'Poor';
            case 1:
                return 'Bad';
            default:
                return '';
        }
    }

    const gotoHome = () => history.replace('/');

    const onSubmit = () => {
        const payload = {
            id: ratingUser.id,
            rating: rating,
            review: message,
        }
        AxiosService.post('/order/addreview', payload)
        .then(response => {
            if (response.status === 200) {
                removeRatingUser();
                gotoHome();
                toast.success('Review has been successfully added.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Rate &#38; Review Pro</title>
            </Helmet>
            <PagesHeader />
            <div className={`full-center`}>
                <div className="container-lg">
                    {
                        ratingUser && (
                            <>    
                                <div className={`row ${classes.title}`}>
                                    <div className="col-12 text-center">
                                        Give Rating To
                                    </div>
                                </div>
                                <div className={`row ${classes.name} mb-2`}>
                                    <div className="col-12 text-center">
                                        {ratingUser.name}
                                    </div>
                                </div>
                                <div className={`row ${classes.userImg} mb-3`}>
                                    <div className="col-12 text-center">
                                        <img src={getProfilePic(ratingUser.profileId)} onError={handleOnError} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 text-center">
                                        <StarRatings
                                            rating={rating}
                                            starRatedColor={'#00b9f2'}
                                            starHoverColor={'#00b9f2'}
                                            changeRating={changeRating}
                                            numberOfStars={5}
                                            name='rating'
                                            starSpacing={'5'}
                                        />
                                    </div>
                                </div>
                                <div className={`row mb-3`}>
                                    <div className={`col-12 text-center ${classes.ratingName}`}>
                                        {getRatingName()}
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <Input
                                            type="text"
                                            id="review-message"
                                            label="Write your review here"
                                            variant="outlined"
                                            inputProps={{ maxLength: 250 }}
                                            multiline={true}
                                            minRows={6}
                                            value={message}
                                            onChange={(e) => {
                                                setMessage(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 text-center">
                                        <button className="lp-primary-btn fwBold" onClick={onSubmit}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={`col-12 text-center ${classes.backToHome}`}>
                                        <div onClick={gotoHome}>Back to Home</div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default RateReview;