import React, { useState } from 'react'
import Form from "react-bootstrap/Form";
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import { BrowserRouter } from 'react-router-dom';
import { Redirect, useHistory } from 'react-router'
import Alerts from '../../components/alerts/alerts'
import axios from 'services/axios';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import linkdin from '../../assets/img/linkedin.svg';
import google from '../../assets/img/google.svg';
import { useDispatch } from 'react-redux';
import { setUserCountry } from 'features/user-data';

function Login(props) {
    const [redirect, setRedirect] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    try {
        let currUser = JSON.parse(localStorage.getItem("currUser"))
        if(currUser.token && currUser.token == null) {
            history.push('/');
        }

        if(currUser.token && currUser.token != null) {
            history.push('/setup-profile');
        }

    }catch(e){}
    const [state, setState] = useState({ username: "", password: "" });
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    let [btnDisable, setbtnDisable] = useState(false) // #TODO
    const [passwordShown, setPasswordShown] = useState(false);
    const errors = {}
    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        
        if(state.username.trim() !== '' && state.password.trim() !== '') {
            setbtnDisable(false)
        } else {
            setbtnDisable(false) // #TODO
        }
    };
    const handleSubmit = () => {
        if(state.username.trim() !== '' && state.password.trim() !== '') {
            let data = {
                username: state.username.trim(),
                password: state.password.trim()
            }
            try {
                axios.post('/auth/login', data, {validateStatus: false})
                .then(response => {
                    if(response.status === 200) { 
                        let data = response.data
                        if(data && data.success && data.token !== '') {
                            setAlertMsg('')
                            setShowAlert(false)
                            dispatch(setUserCountry(data?.country_name));
                            localStorage.setItem("currUser", JSON.stringify(data));
                            setTimeout(setRedirect(true), 1000);
                            
                        }
                    } else {
                        let errmsg = response.data[Object.keys(response.data)[0]];
                        setAlertMsg(errmsg)
                        setShowAlert(false)
                        setShowAlert(true)
                        return false
                    }
                })
            }catch (err) {
                return false
            }
        } else {
            return false
        }
    }
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    }
    return (
        <>
            {redirect && <Redirect to={{ pathname: "/setup-profile", state: { loggedIn: true } }} />}
            <Helmet>
                <title>Sign In</title>
                <meta key="og:title" property="og:title" content="AcDiTo - Log In" />
                <meta key="meta:description" name="description" content="Manage Your Projects | Connect with Reliable Local Businesses and contractors." />
                <meta key="og:description" property="og:description" content="Manage Your Projects | Connect with Reliable Local Businesses and contractors." />
            </Helmet>
            <PagesHeader />

            {showAlert && <Alerts msg={alertMsg} show={showAlert} type="danger" />}
            <div className="container-fluid my-5" style={{minHeight: '69.8vh'}} >
                <div className="row  text-center mb-3">
                    <h1 className="signup-titlee col-12">Sign In</h1>
                </div>
                <div className="row">
                    <div className="col-12 col-md-auto mx-auto">
                        <div className="row form">
                            <div className="col-12 form-group">
                                <Form.Control type="text" value={state.username} className="signup-input" onChange={handleChange} name='username' placeholder="Email or Mobile" error={errors.username} />
                            </div>
                            <div className="col-12 form-group password-field">
                                <div className="input-group">
                                    <Form.Control type={passwordShown ? "text" : "password"} value={state.password} className="signup-input" onChange={handleChange} name="password" placeholder="Password" error={errors.password} />
                                    <div className="input-group-append">
                                        <button className="input-group-text bg-white" onClick={togglePassword}><i className={passwordShown ? "far fa-eye" : "far fa-eye-slash"}></i></button>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <Link to="forgot-password" className="signup-help mt-4 mb-2 link-blue">Forgot Password?</Link>
                                </div>
                            </div>
                            <div className="col-12 form-group">
                                <div className="text-center">
                                    <button type="submit" className="btn btn-block send-button tx-tfm" onClick={handleSubmit} disabled={btnDisable}>Sign In</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 form-group">
                                <div className="signup-or">OR</div>
                            </div>

                            <div className="col-12 col-md-12 form-group">
                                <div className="text-center">
                                    <button type="submit" className="btn btn-block linkdin-button tx-tfm">
                                        <img src={linkdin}/> Continue with Linkedin
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 form-group">
                                <div className="text-center">
                                    <button type="submit" className="btn btn-block google-button tx-tfm">
                                    <img src={google}/> Continue with Google
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PagesFooter showFooter={false} copyClass={true} />
            
        </>
    )
}

export default Login
