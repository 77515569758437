import React from 'react';
import ReactSelect, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';

const colourStyles = {
    container: (styles, { selectProps }) => {
        const style = selectProps.style ? selectProps.style : {};
        return {
            ...styles,
            display: 'flex',
            minWidth: 100,
            height: selectProps.inputView ? '56px' : '40px',
            ...style,
        };
    },
    control: (styles, { selectProps }) => {
        const style = {
            borderColor: 'white',
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            height: '55px'
        };
        if (selectProps.error) {
            style.borderColor = 'red';
        }
        if (selectProps.isDisabled && selectProps.inputView) {
            style.backgroundColor = '#ffffff';
            style.opacity = '0.7';
        }
        return {
            ...styles,
            boxShadow: 'none',
            width: '100%',
            paddingLeft: 2,
            borderRadius: selectProps.round ? 5 : 4,
            ...style,
        };
    },
    menu: styles => {
        return {
            ...styles,
            zIndex: 20,
        };
    },
};

const SearchIcon = () => {
    return <FontAwesomeIcon className="mx-2" icon={faSearch} />;
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SearchIcon />
        </components.DropdownIndicator>
    );
};
const Select = ({
    options = [],
    label,
    inputView,
    emptyOption,
    emptyOptionLabel = '',
    placeholder,
    className,
    optionsWorkaround,
    value,
    onChange,
    asyncSelect,
    ...rest
}) => {
    if (emptyOption) {
        options.unshift({
            label: emptyOptionLabel,
            value: null,
        });
        if (!value) {
            value = {
                label: emptyOptionLabel,
                value: null,
            };
        }
    }
    const selectProps = {
        options,
        onChange,
        value,
        styles: colourStyles,
        theme: themeParam => {
            const newThemeParam = Object.assign({}, themeParam);
            newThemeParam.colors.primary = 'rgba(68,125,247, 1)';
            newThemeParam.colors.primary25 = 'rgba(68,125,247, 0.25)';
            newThemeParam.colors.primary50 = 'rgba(68,125,247, 0.5)';
            newThemeParam.colors.primary75 = 'rgba(68,125,247, 0.75)';
            return newThemeParam;
        },
        ...rest,
        inputView: !!(label || inputView),
        classNamePrefix: 'react-select',
        placeholder,
    };

    if (optionsWorkaround) selectProps.options = optionsWorkaround;
    if (asyncSelect) {
        return <AsyncSelect {...selectProps} />;
    }
    if (label) {
        return (
            <div className="input-block">
                <span className="input-block__label">{label}</span>
                <ReactSelect {...selectProps} components={rest.searchIcon && { DropdownIndicator }} />
            </div>
        );
    }
    return <ReactSelect {...selectProps} components={rest.searchIcon && { DropdownIndicator }} />;
};

export default Select;
