import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
    userType: "",
    firstName: "",
    lastName: "",
    mobile: "",
    emailAddress: "",
    emailAddressInvalid: "",
    password: "",
    country: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    setUser: (state, action) => {
        state.value = action.payload;
    },
    
    login: (state, action) => {
      state.value = action.payload;
    },

    logout: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setUser, login, logout } = userSlice.actions;

export default userSlice.reducer;