import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import images from '../../assets/img/index';
import PagesFooterSub from './PageFooterSub';

function PagesFooter(props) {
    const showFooter = typeof props.showFooter !== 'undefined' ? props.showFooter : true
    return (
        <div>
            {showFooter && <div className="footer fmroboto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <ul className="list-unstyled clear-margins ft-list">
                                <li className="widget-container widget_nav_menu">
                                    <h1 className="ft-title">AcDiTo</h1>
                                    <ul>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="/about">About</Link></li>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="/career">Career</Link></li>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="/get-help">Get Help</Link></li>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="list-unstyled clear-margins ft-list">
                                <li className="widget-container widget_nav_menu">
                                    <h1 className="ft-title">Find Out</h1>
                                    <ul>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="/all-services">All Services</Link></li>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="/corporate-services">Corporate Services</Link></li>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="/grow-business">Grow Your Business</Link></li>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="/students-program">Students Program</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="list-unstyled clear-margins ft-list">
                                <li className="widget-container widget_nav_menu">
                                    <h1 className="ft-title">Locations</h1>
                                    <ul>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="#">United States</Link></li>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="#">Africa</Link></li>
                                        <li className="ft-list-item"><i className="fa fa-angle-right"></i> <Link to="#">English | French</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul className="list-unstyled clear-margins ft-list">
                                <li className="widget-container widget_nav_menu">
                                    <h1 className="ft-title">Get The App</h1>
                                    <ul>
                                        <li className="ft-list-item"><img alt="" src={images.googleplay_white} /></li>
                                        <li className="ft-list-item"><img alt="" src={images.applestore_white} /></li>
                                        <li className="ft-list-item">
                                            <Link to="facebook" className="mr-1"><img alt="" className="mb-2" src={images.fb_logo} /></Link>
                                            <Link to="twitter" className="mr-1"><img alt="" className="mb-2" src={images.twitter_logo} /></Link>
                                            <Link to="linkedIn" className="mr-1"><img alt="" className="mb-2" src={images.linkedin_logo} /></Link>
                                            <Link to="instagram" className="mr-1"><img alt="" className="mb-2" src={images.instagram_logo} /></Link>
                                            <Link to="youtube" className="mr-1"><img alt="" className="mb-2" src={images.youtube_logo} /></Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> }
            <PagesFooterSub />
        </div>
    )
}

export default PagesFooter
