export const getProfilePic = (profileId) => {
    return `https://acdito.com/v1/api/files/${profileId}`
}

export const setProfilePicId = (profileId) => {
    let currUser = JSON.parse(localStorage.getItem("currUser"));
    localStorage.setItem("currUser", JSON.stringify({
        ...currUser,
        profile_id: profileId,
    }));
}