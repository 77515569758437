import React, { useState, useMemo, useEffect } from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import Tabs from 'components/Controls/Tabs';
import ProjectItem from './ProjectItem';
import classes from './style.module.scss';
import axios from 'services/axios';

const Projects = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [projects, setProjects] = useState();
    const [openProjects, setOpenProjects] = useState();

    const loadInit = () => {
        axios.get('/order/all')
        .then(response => {
            if(response.status === 200) {
                setProjects(response.data);
            }
        })
        axios.get('/corporateservice/all')
        .then(response => {
            if(response.status === 200) {
                setOpenProjects(response.data);
            }
        })
    }
    
    useEffect(() => {
        loadInit();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    }

    const getProjects = useMemo(() => {
        if(activeTab === 1) {
            return (projects && ( projects.length > 0 ? projects.map((project) => (
                <div className="mb-3" key={project.id}>
                    <ProjectItem project={project} />
                </div>
            )) : (
                <div className="h4">
                    No projects found...
                </div>
            )))
        } else {
            return (openProjects &&
                (openProjects.length > 0 ? (
                    openProjects
                        .map((project) => (
                            <div className="mb-3" key={project.id}>
                                <ProjectItem project={project} />
                            </div>
                        ))
                ) : (
                    <div className="h4">No projects found...</div>
                ))
            );
        }
    }, [activeTab, projects]);

    return (
        <>
            <PagesHeader />
            <div className="full-center container mt-3">
                <Tabs 
                    value={activeTab} 
                    onChange={handleChange} 
                    options={[
                        { label: 'My Projects', value: 1 },
                        { label: 'Open Projects', value: 2 },
                    ]} 
                />
                <div className={classes.projects}>
                    {getProjects}
                </div>
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default Projects;