import React from 'react';

class ReadMoreLess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false
        };
    }
    render() {
        const { props } = this;
        const { children, ellipsis = "...", readMoreText = 'See more', readLessText = 'See less', readMoreClassName, readLessClassName, readMoreStyle, readLessStyle, charLimit } = props;
        const { showMore } = this.state;
        const shortText = children.substr(0, charLimit).replace(/[\s\n]+$/,'').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/,"") + (charLimit >= children.length ? '' : ellipsis);
        const that = this;
        const ReadMore = () => (((charLimit >= children.length) || !readMoreText) ? null : <span
            className={`${readMoreClassName} read-more-less--more`}
            role="presentation"
            style={readMoreStyle}
            onClick={() => { that.setState({showMore: true}); }}
        >{readMoreText}</span>);
        const ReadLess = () => (((charLimit >= children.length) || !readLessText) ? null : <span
            className={`${readLessClassName} read-more-less--less`}
            role="presentation"
            style={readLessStyle}
            onClick={() => { that.setState({showMore: false}); }}
        >{readLessText}</span>);

        return (
            <React.Fragment>{showMore ? children : shortText} {showMore ? <ReadLess /> : <ReadMore />}</React.Fragment>
        );
    }
}

export default ReadMoreLess;