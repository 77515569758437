import React from 'react'
import { Link, useHistory } from 'react-router-dom';

import ServiceSearch from '../components/pages/ServiceSearch';

function PageTitleSection(props) {
    const history = useHistory();
    const minHeight = props.minHeight
    const Styles = {
        "minHeight": minHeight
    }

    const backClickHandler = () => {
        if (props.backLink.link) {
            history.push(props.backLink.link);
        } else {
            history.goBack();
        }
    }
    return (
        <div className="page-title-section d-flex align-items-center" style={Styles}>
            <div className="container py-5">
                <div className="row d-flex justify-content-between">
                    <div className="col-lg-8 col-md-12 col-sm-12 side_section d-flex flex-column justify-content-center">
                        {props.blueTitle && <div className="blue-title fs20 mb-2">{props.blueTitle}</div>}
                        {props.backLink && <div role="button" className="text-secondary" onClick={backClickHandler}>{props.backLink.text}</div>}
                        {
                            props.title1 && <div className="title_div fs32 mb-2 fwBold">{props.title1}<br/>{props.title2}</div>
                        }
                        {props.subHeading && <div className="subHead_div mb-2"><h5>{props.subHeading}</h5></div>}
                        {props.button && (
                            <div className="mt_20 btn_div mb-2">
                                <button className="btn lp-button1 fwBold" >
                                    {props.button.text}
                                </button>
                            </div>
                        )}
                        {props.search && (
                            <div className="mt_20 search_div">
                                <ServiceSearch />
                            </div>
                        )}
                    </div>
                    {
                        props.image && (
                            <div className="col-12 col-md-4 image_section">
                                <img alt="" className="img-fluid mt-5 mt-md-auto" src={props.image} />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default PageTitleSection
