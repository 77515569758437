import ReadOnlyDetails from 'components/ReadOnlyDetails';
import Input from 'components/Controls/Input';
import React, { useState } from 'react';
import classes from './style.module.scss';
import InputAdornment from '@mui/material/InputAdornment';
import MultiSelect from 'components/Controls/MultiSelect';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { get, isEmpty } from 'lodash';
import { getCurrnecyCode } from 'lib/getCurrencyCode';

const Requirements = (props) => {
    const { specialitiesList, allData, getFormData, updateFormData, moveToNextStep } = props;
    const [errors, setErrors] = useState({});

    const onNextClick = () => {
        setErrors({});
        let tErrors = {};

        if (!getFormData('services_purchased') || getFormData('services_purchased').length === 0) {
            tErrors['services_purchased'] = 'Please select services';
        }

        if (isEmpty(getFormData('service_description'))) {
            tErrors['service_description'] = 'Please add service description';
        }

        if (isEmpty(getFormData('price')) || getFormData('price') === '0.00') {
            tErrors['price'] = 'Please add price';
        }

        if (Object.keys(tErrors).length === 0) {
            moveToNextStep();
        }  else {
            setErrors(tErrors);
        }
    }

    const checkError = (key) => {
        if (errors && errors[key]) {
            return errors[key]
        }
        return undefined;
    }

    const Error = (props) => {
        return <span className="text-danger">{props.text}</span>
    }

    return (
        <div className={`container ${classes.requirementWrapper}`}>
            <div className="row">
                <div className="col-12 mb-4">
                    <ReadOnlyDetails title="Name" data={`${get(allData, 'first_name', '')} ${get(allData, 'last_name', '')}`} />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 mb-4">
                    <MultiSelect
                        selectProps={{
                            options: specialitiesList,
                            value: getFormData('services_purchased'),
                            onChange: (value, key) => {
                                updateFormData(key, 'services_purchased');
                            }
                        }}
                        inputProps={{
                            label: 'Services Purchased',
                        }}
                    />
                    {checkError('services_purchased') && <Error text={checkError('services_purchased')} />}
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 mb-4">
                    <Input
                        type="text"
                        id="service_description"
                        label="Service Description"
                        variant="outlined"
                        minRows={3}
                        inputProps={{
                          maxLength: 250,
                        }}
                        multiline={true}
                        onChange={(e) => {
                            updateFormData(e.target.value, 'service_description')
                        }}
                        value={getFormData('service_description')}
                    />
                    {checkError('service_description') && <Error text={checkError('service_description')} />}
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 mb-4">
                        <Input  
                            type="number"
                            id="price"
                            label="Price"
                            placeholder="Price"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {/* <AttachMoneyIcon /> */}
                                    <b>{getCurrnecyCode()}</b>
                                  </InputAdornment>
                                ),
                            }}
                            inputProps={{ inputMode: 'numeric' }}
                            className='numberInputWOArrows'
                            onChange={(event) => {
                                if (/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(event.target.value)) {
                                    updateFormData(event.target.value, 'price')
                                }
                            }}
                            onBlur={(e) => {
                                const tBudget = getFormData('price');

                                if (isNaN(tBudget)) {
                                    updateFormData(parseFloat('0').toFixed(2), 'price')
                                } else {
                                    updateFormData(parseFloat(getFormData('price')).toFixed(2), 'price')
                                }
                                
                            }}
                            value={getFormData('price')}
                        />
                        {checkError('price') && <Error text={checkError('price')} />}
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-2 mb-4">
                    <button type="button" onClick={onNextClick} className="btn btn-block send-button tx-tfm">Next</button>
                </div>
            </div>
        </div>
    )
}

export default Requirements;