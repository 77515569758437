import React, { useState, useEffect } from 'react'
import AccordianWidget from "../../components/Widgets/AccordianWidget";
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import StudentProgram from '../../assets/img/StudentProgram.png';
import data from '../../lib/static-data/get-help.json';

export default function GetHelp() {
    const [accodrianItems, setAccordianItems] = useState(data.provider);
    const [searchInput, setSearchInput] = useState('');
    const [showClass, setShowClass] = useState('')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])

    let handleBtnClickChange = (data, ele) => {
        setAccordianItems(data)
        document.querySelectorAll('.getHelpTabs button').forEach((btn) => {
            btn.classList.remove('active')
        })
        ele.target.classList.add('active')
    }

    const onReset = () => {
        setShowClass('')
        setAccordianItems(data.provider)
        document.querySelectorAll('.getHelpTabs button').forEach((btn, index) => {
            if(index !== 0) {
                btn.classList.remove('active')
            } else {
                btn.classList.add('active')
            }
        })
    }

    let handleSearch = () => {
        if(searchInput !== '') {
            setShowClass('d-none')
            console.log(data)
            console.log(Object.values(data))
            let filterData = Object.values(data).map(acc => acc.filter((item) => item.heading.includes(searchInput) || item.content.includes(searchInput))).flat()
            setAccordianItems(filterData)
            if(filterData.length === 0) {
                setAccordianItems([
                    {'heading': 'No Data', 'content': 'No Data available for this search.'}
                ])
            }
        } else {
            onReset();
        }
    }
    return (
        <>
            <Helmet>
                <title>Get Help</title>
                <meta property="og:title" content="AcDiTo - Get Help | Find Answers and Support for Your Questions" />
                <meta key="og:description" property="og:description" content="Have questions about using AcDiTo? Visit our Get Help page for answers and support. Browse FAQs, contact customer service, or chat with our assistant." />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="300px" image={StudentProgram} blueTitle="Get Help" title1="Welcome to our support page" subHeading="We are here to help" />
            <div className="container get-help-page my-5">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-10 mb-3" >
                        <div className="input-group">
                            <input type="text" name="topicSearch" className="form-control form-control-lg" placeholder="In which topic do you need support?" onChange={(eve) => {
                                if (eve.target.value === '') {
                                    onReset()
                                }
                                setSearchInput(eve.target.value)}
                             } />
                        </div>
                    </div>
                    <div className="col-12 col-md-2 mb-3" >
                        <button className="btn lp-button1 fwBold w-100 py-2" onClick={()=>handleSearch()}>Search</button>
                    </div>
                </div>
                <div className="row">
                    <div className={"col-4 mb-3 getHelpTabs " + showClass}>
                        <Button className={'active'} onClick={(eve) => handleBtnClickChange(data.provider, eve)}>I am a Service Provider</Button>
                    </div>
                    <div className={"col-4 mb-3 getHelpTabs " + showClass}>
                        <Button className={''} onClick={(eve) => handleBtnClickChange(data.user, eve)}>I am a Service User</Button>
                    </div>
                    <div className={"col-4 mb-3 getHelpTabs " + showClass}>
                        <Button className={''} onClick={(eve) => handleBtnClickChange(data.visitor, eve)}>I am a Curious Visitor</Button>
                    </div>
                    <div className="col-12" >
                        <AccordianWidget items={accodrianItems} />
                    </div>
                </div>
            </div>
            <PagesFooter />
        </>
    )
}
