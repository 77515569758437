import React from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import { Helmet } from 'react-helmet';
import images from '../../assets/image/index';

import 'bootstrap/dist/css/bootstrap.min.css';

class FinAidPage extends React.Component {
    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>FinAid | Easily Get Help from Family & Friends from Anywhere</title>
                    <meta
                        key="meta:description"
                        name="description"
                        content="AcDiTo - FinAid provides the easiest way to seek financial Aid from family and friends from anywhere"
                    />
                    <meta key="og:title" property="og:title" content="FinAid | Easily Get Help from Family & Friends from Anywhere" />
                    <meta
                        key="og:description"
                        property="og:description"
                        content="AcDiTo - FinAid provides the easiest way to seek financial Aid from family and friends from anywhere"
                    />
                </Helmet>
                <PagesHeader />
                <>
                    {' '}
                    <div className="fmroboto">
                        <div className="landingpage_section1">
                            <div className="row" style={{ margin: 'auto' }}>
                                <div className="col-lg-3 col-md-3"></div>
                                <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center landing_jumbotron justify-content-center">
                                    <div className="">
                                        {/* <div className="fs30 mb_25 fwBold yellow text-center">
                                    Building a credible decentralized coupon system
                                </div> */}
                                        <div className="fs32 mb_25 fwBold text-center">
                                            Connecting families and friends who help each other pay medical bills
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3"></div>
                            </div>
                            <div className="row" style={{ margin: 'auto' }}>
                                <div className="col-12" id="landing-page-caorusel">
                                    <img
                                        // className="img-fluid"
                                        style={{ maxWidth: '100%', opacity: 0.5, objectFit: 'contain' }}
                                        src={images.banner1}
                                        alt="slider1"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="landingpage_section2 mt-5">
                            <div className="container">
                                <div className="row mt_40 pb-5">
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="mt_20 lp-description-text">
                                                <span style={{ color: '#19566b', fontWeight: 500 }}>AcDiTo FinAid</span>{' '}
                                                empowers everyone to instantly help family and friends pay medical
                                                bills. As an AcDiTo user in need of assistance, simply fill out the
                                                request form and add the contact of the person you need as a payer. Once
                                                the request is submitted, the payer will be notified of your request and
                                                can instantly make the payment to the hospital or clinic you are
                                                requesting assistance.
                                            </div>
                                            <div>
                                                <ul style={{ listStyleType: 'none' }} className="mt-4">
                                                    <li>
                                                        <span>
                                                            <i
                                                                style={{
                                                                    color: 'white',
                                                                    backgroundColor: '#19566b',
                                                                    borderRadius: '60px',
                                                                    padding: '0.2em 0.2em',
                                                                }}
                                                                class="fa fa-solid fa-check"
                                                            ></i>
                                                        </span>{' '}
                                                        It's fast to use
                                                    </li>
                                                    <li className="mt-1">
                                                        <span>
                                                            <i
                                                                style={{
                                                                    color: 'white',
                                                                    backgroundColor: '#19566b',
                                                                    borderRadius: '60px',
                                                                    padding: '0.2em 0.2em',
                                                                }}
                                                                class="fa fa-solid fa-check"
                                                            ></i>
                                                        </span>{' '}
                                                        Simple and straightforward
                                                    </li>
                                                    <li className="mt-1">
                                                        <span>
                                                            <i
                                                                style={{
                                                                    color: 'white',
                                                                    backgroundColor: '#19566b',
                                                                    borderRadius: '60px',
                                                                    padding: '0.2em 0.2em',
                                                                }}
                                                                class="fa fa-solid fa-check"
                                                            ></i>
                                                        </span>{' '}
                                                        Globally available
                                                    </li>
                                                    <li className="mt-1">
                                                        <span>
                                                            <i
                                                                style={{
                                                                    color: 'white',
                                                                    backgroundColor: '#19566b',
                                                                    borderRadius: '60px',
                                                                    padding: '0.2em 0.2em',
                                                                }}
                                                                class="fa fa-solid fa-check"
                                                            ></i>
                                                        </span>{' '}
                                                        Improve and saves lives on the go
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt_40 pb-5">
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="mt_20 lp-title-text text-center">
                                                Do you need assistance with medical bills and know someone who can help?
                                            </div>
                                            <div className="mt_20 text-center">
                                                <button className="lp-primary-btn lp-bg-toright">
                                                    Submit FinAid Request
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                <PagesFooter />
            </>
        );
    }
}

export default FinAidPage;
