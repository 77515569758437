import React, { Component } from 'react';

import images from '../../assets/img/index';
import downloadapplestoredownaloadicon from '../../assets/img/downloadapplestore.png'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import ServiceSearch from 'components/pages/ServiceSearch'
import { Link } from 'react-router-dom';

class LandingPageContent extends Component {

    onAdd(route) {
        //redirects path to add document//
        const { history } = this.props;
        history.push('/' + route);
    }

    render() {
        return (
            <div className="fmroboto">
                <div className="landingpage_section1">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-md-12"></div> */}
                            <div className="col-lg-8 col-md-12 col-sm-12 d-flex align-items-center landing_jumbotron">
                                <div className="">
                                    <div className="fs36 mb_25 fwBold" >Easily find and hire trusted local professionals in</div>
                                    <ServiceSearch />
                                    <div className="row mt_30">
                                        {/* <div className="col-12"> */}
                                        <div className="col-6 col-md-auto">
                                            <a href="#" >
                                                <img src={images.googleplaystoredownloadicon} alt="Google Play Store" className="img-responsive downloadapp-img" />
                                            </a>
                                        </div>
                                        <div className="col-6 app-store-download">
                                            <a href="#" >
                                                <img src={downloadapplestoredownaloadicon} alt="App Store" className="img-responsive downloadapp-img" />
                                            </a>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" id="landing-page-caorusel">
                                <Carousel indicators={false} controls={false} fade={true} interval={3000}>
                                    <Carousel.Item>
                                        <img className="img-fluid"
                                            style={{ "float": "right" }}
                                            src={images.landingslider1}
                                            alt="slider1"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img className="img-fluid"
                                            style={{ "float": "right" }}
                                            src={images.landingslider2}
                                            alt="slider2"
                                        />
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt_40">
                        <div className="col-md-6">
                            <img className="img-fluid" src={images.img_4} />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="d-block">
                                <div className="lp-How-it-works-for">How it works for</div>
                                <div className="lp-Consumers">Consumers</div>
                                <div className="mt_20">
                                    <div style={{ display: "inline-flex", width: "100%" }}>
                                        <div className="lp-Consumers-icon">
                                            <img src={images.Group_1061} className="img-responsive" />
                                        </div>
                                        <div className="lp-Consumers-text">Find local businesses and professionals that match your needs in minutes, not days.</div>
                                    </div>
                                    <div className="mt-2" style={{ display: "inline-flex", width: "100%" }}>
                                        <div className="lp-Consumers-icon">
                                            <img src={images.Group_1062} className="img-responsive" />
                                        </div>
                                        <div className="lp-Consumers-text">Read their reviews, ratings, locations, and more before hiring a service provider for your project.</div>
                                    </div>
                                    <div className="mt-2" style={{ display: "inline-flex", width: "100%" }}>
                                        <div className="lp-Consumers-icon">
                                            <img src={images.Group_1063} className="img-responsive" />
                                        </div>
                                        <div className="lp-Consumers-text">Manage and keep track of your projects and payments in one place.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt_40">
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="d-block">
                                <div className="lp-How-it-works-for">How it works for</div>
                                <div className="lp-Consumers">Service Provider</div>
                                <div className="mt_20">
                                    <div style={{ display: "inline-flex", width: "100%" }}>
                                        <div className="lp-Consumers-icon">
                                            <img src={images.Group_1064} className="img-responsive" />
                                        </div>
                                        <div className="lp-Consumers-text">Boost your visibility, accelerate growth and raise the level of customer’s trust and engagement for your services.</div>
                                    </div>
                                    <div className="mt-2" style={{ display: "inline-flex", width: "100%" }}>
                                        <div className="lp-Consumers-icon">
                                            <img src={images.Group_1065} className="img-responsive" />
                                        </div>
                                        <div className="lp-Consumers-text">Signup for a free account, customize it, add the services you offer, and wait for projects to come your way.</div>
                                    </div>
                                    <div className="mt-2" style={{ display: "inline-flex", width: "100%" }}>
                                        <div className="lp-Consumers-icon">
                                            <img src={images.Group_1066} className="img-responsive" />
                                        </div>
                                        <div className="lp-Consumers-text">Get hired, rated and, paid by clients from anywhere, anytime, through protected payments.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img className="img-fluid" src={images.img_3} />
                        </div>
                    </div>
                </div>
                <div className="landingpage_section2 pt-5 pb-5 mt-5">
                    <div className="container">
                        <div className="row mt_40">
                            <div className="col-md-6 d-flex align-items-center">
                                <div className="d-block">
                                    <div className="lp-Consumers">Gift a Service</div>
                                    <div className="lp-servicesubtext">Use AcDiTo Gift Service to assist family, friends, or anyone else. </div>
                                    <div className="mt_20 lp-servicedetail">Does someone you know face a problem or want help with a project? Now you can help them by paying for the service they need. Choose from popular categories such as Business Services, Health and Wellness, Legal Services, Home Improvement Services – and many more.</div>
                                    <div className="mt_20">
                                        <button className="btn lp-button1 fwBold">
                                        <Link className="text-white" to="gift-service">Find a Service to Gift</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img className="img-fluid mt-5 mt-md-auto" style={{ height: '340px' }} src={images.giftservice1} />
                            </div>
                        </div>
                        <div className="row pt-2 mt_55">
                            <div className="col-md-6">
                                <img className="img-fluid" style={{ height: '340px' }} src={images.corpservice1} />
                            </div>
                            <div className="col-md-6 d-flex align-items-center">
                                <div className="d-block">
                                    <div className="lp-Consumers">Find the right professional. Right now. Right here</div>
                                    <div className="mt_20 lp-servicedetail">Use our AI-Powered technology to find local contractors and freelancers who match your business needs in minutes and get high-quality services from experienced professionals.</div>
                                    <div className="mt_20 lp-servicedetail">Negotiate what you will pay upfront and, your payment isn’t released until you approve the work.</div>
                                    <div className="mt_20 lp-servicedetail">In addition to our quality service management, our 24/7 support team is available to help anytime.</div>
                                    <div className="mt_20">
                                        <button className="btn lp-button1 fwBold" >
                                            <Link className="text-white" to="corporate-services">Post a request</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center lp-trustedbyheader1 mt_30">
                    Trusted and used by thousands of public/private businesses
                     of all sizes to boost workplace productivity.
                    </div>
                    <div className="row justify-content-center lp-trustedbyheader">
                    Trending Services in Your City
                    </div>
                    <div className="row mt_12">
                        <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                            <img src={images.istockphoto_carpenter} className="img-responsive" />
                            <div className="lp-trending-img-child-text">Carpenter</div>
                        </div>
                        <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                            <img src={images.istockphoto_electrician} className="img-responsive" />
                            <div className="lp-trending-img-child-text">Electrician</div>
                        </div>
                        <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                            <img src={images.istockphoto_plumber} className="img-responsive" />
                            <div className="lp-trending-img-child-text">Plumber</div>
                        </div>
                        <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                            <img src={images.istockphoto_legalservice} className="img-responsive" />
                            <div className="lp-trending-img-child-text">Legal Services</div>
                        </div>
                        <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                            <img src={images.istockphoto_dentist} className="img-responsive" />
                            <div className="lp-trending-img-child-text">Dentist</div>
                        </div>
                    </div>
                </div>
                <div className="landingpage_section3 pt-3 pb-5 mt-5">
                    <div className="container">
                        <div className="row justify-content-center lp-trustedbyheader">Trusted By</div>
                        <div className="row pt-3">
                            <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                                <img src={images.trustedby01} className="img-responsive" />
                            </div>
                            <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                                <img src={images.trustedby02} className="img-responsive" />
                            </div>
                            <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                                <img src={images.trustedby03} className="img-responsive" />
                            </div>
                            <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                                <img src={images.aws} className="img-responsive" />
                            </div>
                            <div className="mb-3 lp-trending-img-child text-center col-12 col-sm-6 col-md-3 col-lg-auto col-xl-auto">
                                <img src={images.trustedby05} className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5 mb_70">
                    <div className="row justify-content-center lp-trustedbyheader">
                        What our customers are saying about us.
                    </div>
                    <div className="row mt-3 justify-content-center" id="reviewSlider">
                        <div className="col-12">
                            <Carousel indicators={true} controls={false} interval={3000}>
                            <Carousel.Item>
                                <div className="row">
                                    <div className="mb-3 col-md-6 lp-card" >
                                        <div className="card lp-profile h-100">
                                            <div className="card-body">
                                                <img src={images.review1} />
                                                <p className="lp-card-text mt_18">The problem with finding, hiring and paying verified service providers in Africa has always been a huge pain that every one in the Diaspora can relate with. AcDiTo directory of verified professionals completely takes that pain away. </p>
                                                <p className="lp-card-title">Nkafu A.</p>
                                                <p className="lp-card-sub-title">BD Analyst</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-md-6 lp-card" >
                                        <div className="card lp-profile h-100">
                                            <div className="card-body">
                                                <img src={images.review2} />
                                                <p className="lp-card-text mt_18">As a medical student, finding reliable professionals has always been time-consuming and costly when you need them. AcDiTo provides a genius African solution to the hurdles. </p>
                                                <p className="lp-card-title">Ines O.</p>
                                                <p className="lp-card-sub-title">MD Student</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row">
                                    <div className="mb-3 col-md-6 lp-card" >
                                        <div className="card lp-profile h-100">
                                            <div className="card-body">
                                                <img src={images.review2} />
                                                <p className="lp-card-text mt_18">As a medical student, finding reliable professionals has always been time-consuming and costly when you need them. AcDiTo provides a genius African solution to the hurdles. </p>
                                                <p className="lp-card-title">Ines O.</p>
                                                <p className="lp-card-sub-title">MD Student</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-md-6 lp-card" >
                                        <div className="card lp-profile h-100">
                                            <div className="card-body">
                                                <img src={images.review1} />
                                                <p className="lp-card-text mt_18">The problem with finding, hiring and paying verified service providers in Africa has always been a huge pain that every one in the Diaspora can relate with. AcDiTo directory of verified professionals completely takes that pain away. </p>
                                                <p className="lp-card-title">Nkafu A.</p>
                                                <p className="lp-card-sub-title">BD Analyst</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPageContent;
