import React, { useState } from 'react'
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { Link, useHistory } from 'react-router-dom';

import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import OtpInput from 'react-otp-input';
import Input from 'components/Controls/Input';
import axios from 'services/axios';
import { toast } from 'react-toastify';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const validatePasswordLength = (pwd) => {
    if (pwd.length >= 8 && pwd.length <= 15) {
        return true;
    } else {
        return false;
    }
}

const ChangePassword = (props) => {
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleClickShowCPassword = () => setShowCPassword(!showCPassword);
    const handleMouseDownCPassword = () => setShowCPassword(!showCPassword);

    const onSubmitHandler = () => {
        let tErrors = {  };
        if (password.trim() === '') {
            tErrors['password'] = 'Please enter new password.'
        }
        if (cPassword.trim() === '') {
            tErrors['cPassword'] = 'Please enter confirm password.'
        }

        if (Object.keys(tErrors).length === 0) {
            if (!validatePasswordLength(password)) {
                tErrors['password'] = 'Password must be between 8 and 15 characters.'
            }

            if (password !== cPassword) {
                tErrors['password'] = 'New and confirm password must be equal'
            }

            if (Object.keys(tErrors).length === 0 && password === cPassword) {
                props.changePassword(password);
            } else {
                setErrors(tErrors);
            }
        } else {
            setErrors(tErrors);
        }
    }

    const onBlur = () => {
        if (password !== cPassword) {
            setErrors({ cPassword: 'New and confirm password must be equal' })
        } else {
            setErrors({})
        }
    }

    const checkError = (key) => {
        if (errors && errors[key]) {
            return errors[key];
        }

        return undefined;
    }

    const Error = (props) => {
        return <span className="text-danger">{props.text}</span>
    }

    return (
        <div className="container-fluid my-5" style={{minHeight: '69.8vh'}}>
            <div className="row mt-5">
                <div className="col-md-6 mx-auto text-center">
                    <div className="otp-verify-title">Change password</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 mx-auto">
                    <div className="row mt-5">
                        <div className="col-12">
                            <Input
                                id="f_password"
                                label="New Password"
                                variant="outlined"
                                type={showPassword ? "text" : "password"} 
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                                inputProps={{
                                  maxLength: 15,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                            />
                            {checkError('password') && <Error text={checkError('password')} />}
                        </div>
                        <div className="col-12 mt-3">
                            <Input
                                id="s_password"
                                label="Confirm Password"
                                variant="outlined"
                                type={showCPassword ? "text" : "password"} 
                                value={cPassword}
                                inputProps={{
                                  maxLength: 15,
                                }}
                                onChange={e => setCPassword(e.target.value)}
                                onBlur={onBlur}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowCPassword}
                                        onMouseDown={handleMouseDownCPassword}
                                      >
                                        {showCPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                            />
                            {checkError('cPassword') && <Error text={checkError('cPassword')} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4 mx-auto text-center">
                    <button type="button" onClick={onSubmitHandler} className="btn btn-block send-button tx-tfm" >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

const OTPVerification = (props) => {
    const [otp, setOtp] = useState(null);
    const handleChange = (otp) => setOtp(otp);

    const verifyOtp = () => {
        if (otp && otp.length === 6) {
            props.verifyOTP(otp);
        }
    }

    return (
        <div className="container-fluid my-5" style={{minHeight: '69.8vh'}}>
            <div className="row mt-5">
                <div className="col-md-6 mx-auto text-center">
                    <div className="otp-verify-title">OTP Verification</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 mx-auto text-center">
                    <div className="row mt-5">
                        <span className="col-12">
                            OTP has been sent to your registered
                            <strong> Email Address</strong>. Please verify
                            </span>
                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            containerStyle="col-12 mt-5 justify-content-center"
                            inputStyle="otpInput"
                            separator={<span className="mx-2"></span>}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-1 mx-auto text-center">
                    <button className="otpButton btn mt-3" onClick={verifyOtp}>
                        <i className="fa fa-3x fa-arrow-circle-right"></i>
                    </button>
                </div>
            </div>
            <div className="row mt-3 text-center">
                <div className="col-md-12 mx-auto text-center">
                    <span>Didn't receive the OTP?</span><br></br><button className="resendBtn btn btn-link link-blue" onClick={props.sentReq}>Resend Code</button>
                </div>
            </div>
        </div>
    )
}

export default function ForgotPassword() {
    const [step, setStep] = useState(0);
    const [mobile, setMobile] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [otp, setOtp] = useState('');
    const history = useHistory();

    const sentReq = () => {
        axios.post('/auth/sendotp_reset', {
            username: emailAddress
        })
        .then(response => {
            if(response.status === 200 && response.statusText === 'OK') {
                setStep(1);
                return false
            } else { return false }
        })
        .catch(err => {
            let errmsg = err.response.data[Object.keys(err.response.data)[0]];
            toast.error(errmsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false
        });
    }

    const verifyOTP = (otp) => {
        setOtp(otp);
        axios.post('/auth/verifyresetotp', {
            username: emailAddress,
            otp_code: otp
        })
        .then(response => {
            if(response.status === 200 && response.statusText === 'OK') {
                setStep(2);
                return false
            } else { return false }
        })
        .catch(err => {
            let errmsg = err.response.data[Object.keys(err.response.data)[0]];
            toast.error(errmsg || 'Entered OTP is invalid', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false
        });
    }

    const changePassword = (password) => {
        axios.post('/auth/saveresetpassword', {
            username: emailAddress,
            otp_code: otp,
            password: password
        })
        .then(response => {
            if(response.status === 200 && response.statusText === 'OK') {
                history.push('/login');
                toast.success('Your password has been changed successfully.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false
            } else { return false }
        })
        .catch(err => {
            let errmsg = err.response.data[Object.keys(err.response.data)[0]];
            toast.error(errmsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false
        });
    }

    const onSubmitClick = (e) => {
        e.preventDefault();
        sentReq();
    }

    const getStep = () => {
        switch (step) {
            case 1:
                return <OTPVerification sentReq={sentReq} email={emailAddress} verifyOTP={verifyOTP} />;

            case 2:
                return <ChangePassword changePassword={changePassword} />;

            case 0:
            default:
                return (
                    <div className="container-fluid my-5" style={{minHeight: '69.8vh'}}>
                        <div className="row  text-center mb-3">
                            <h1 className="signup-title col-12 link-blue">Forgot Password</h1>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4 mx-auto">
                                <p className="text-center">Enter the <strong>Username</strong> associated with your account and we'll send you a <strong>OTP</strong>.</p>
                                <form className="row form" onSubmit={onSubmitClick}>
                                    {/* <div className="col-12 form-group">
                                    <Form.Control type="text" onChange={e => setMobile(e.target.value.trim())} className="signup-input" name='mobile' placeholder="Mobile No." />
                                </div>
                                <div className="col-12 form-group">
                                    <div className="signup-or">OR</div>
                                </div> */}
                                    <div className="col-12 form-group">
                                        <Form.Control required type="email" onChange={e => setEmailAddress(e.target.value.trim())} className="signup-input" name='email' placeholder="Username" />
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-block send-button tx-tfm">Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group text-center">
                                        <Link to="login" className="signup-help mt-4 mb-2 link-blue"><strong>Return to Sign In</strong></Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return (
        <>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            <PagesHeader />
            {getStep()}
            <PagesFooter showFooter={false} copyClass={true} />
        </>
    )
}
