import React, { useState } from 'react'
import {Alert} from 'react-bootstrap'

// 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'

function Alerts(props) {
    const [show, setShow] = useState(props.show);
    
    if (show) {
        return (
            <Alert variant={props.type} className="common_alert" onClose={() => setShow(false)} dismissible closeLabel="Close alert">
                {props.msg}
            </Alert>
        );
    }
    return (<div className="d-none"></div>)
}

export default Alerts;