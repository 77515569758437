import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import nounPress from 'assets/img/noun-press.svg';
import nounPartner from 'assets/img/noun-partner.svg';
import nounHelpCenter from 'assets/img/noun-help-center.svg';

const Contact = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <>
            <Helmet>
                <title>Contact</title>
                <meta property="og:title" content="AcDiTo - Contact Us | Get in Touch with Our Team" />
                <meta key="og:description" property="og:description" content="Contact AcDiTo for feedback, suggestions, or inquiries. Visit our Contact page and get in touch with our team via form, email, or phone. We’re happy to help." />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="250px" blueTitle="Contact" title1="Get in touch and let us know how we can help." />
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-4 col-12 mb-3">
                        <div className="contact-item">
                            <img className="mb-2" src={nounPartner} />
                            <div className="contact-title mb-2 text-center">Partner With Us</div>
                            <div className="contact--desc mb-3 text-center">We’d love to talk about how we can work together.</div>
                            <div className="c-link mb-2 text-center">Contact Marketing Dept.</div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 mb-3">
                        <div className="contact-item">
                            <img className="mb-2" src={nounHelpCenter} />
                            <div className="contact-title mb-2 text-center">Help Center</div>
                            <div className="contact--desc mb-3 text-center">We’re here to help with any questions or support you need.</div>
                            <div className="c-link mb-2 text-center">Get Support</div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 mb-3">
                        <div className="contact-item">
                            <img className="mb-2" src={nounPress} />
                            <div className="contact-title mb-2 text-center">Press</div>
                            <div className="contact--desc mb-3 text-center">Get AcDiTo news, company info, and media resources.</div>
                            <div className="c-link mb-2 text-center">Visit Newsroom</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-5 mb-3">
                        <div className="contact-title mb-2 text-center">General Communication</div>
                    </div>
                    <div className="col-12">
                        <div className="contact-desc mb-2 text-center">
                            For general queries, including partnership opportunities,<br />please email <a className="b-link" href="mailto:hello@acdito.com">hello@acdito.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <PagesFooter />
        </>
    )
}

export default Contact;