import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import career_header from 'assets/img/career-header.png';
import career_desc from 'assets/img/career-desc.svg';
import classes from './style.module.scss';
import user_plus from 'assets/img/user-add.svg';
import { useHistory } from 'react-router-dom';

const Career = () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])

    const onSignUpClick = () => {
        history.push('/signup');
    }

    return (
        <>
            <Helmet>
                <title>Career</title>
                <meta property="og:title" content="AcDiTo - Careers | Join Us and Build a Better Future for Africa" />
                <meta key="og:description" property="og:description" content="Explore jobs at AcDiTo, the platform connecting consumers with reliable local services in Africa. Find a role that suits your skills and interests. Work with a diverse, inclusive team valuing innovation, collaboration, and growth. Join us and build a better future for Africa." />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="250px" image={career_header} blueTitle="Career" title1="We are always on the lookout for the brightest talents to join us & build meaningful things that help communities thrive." />
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div>
                            <div className={classes.subTitle}>Diversity</div>
                            <div className={classes.subDesc}>
                                We embrace cultural diversity as a strength and innovation enabler. That is why our employees come from a diverse background.
                            </div>
                        </div>
                        <div>
                            <div className={classes.subTitle}>Innovation and Ethics</div>
                            <div className={classes.subDesc}>
                                Innovation is at the center of our daily activities and we are all moved by integrity, reliability, and respect for one another.
                            </div>
                        </div>
                        <div>
                            <div className={classes.subTitle}>Make life convenient for everyone</div>
                            <div className={classes.subDesc}>
                                Through our innovative approach we strive to make life easier and convenient for everyone, and everywhere they go.
                            </div>
                        </div>
                        <div>
                            <div className={classes.subTitle}>Creating Value</div>
                            <div className={classes.subDesc}>
                                We have our users at heart in every step of our design and development process. This can be seen through our user’s satisfactory reviews.
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.descImgWrapper} col-12 col-md-6 mb-5`}>
                        <img src={career_desc} className={classes.descImg} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-xl-3">
                        <button type="button" className="btn btn-block send-button tx-tfm" >
                            Explore Open Positions 
                        </button>
                    </div>
                </div>
            </div>
            <div className={classes.areYouLooking}>
                <img src={user_plus} className={`mr-3 ${classes.userAddIcon}`} />
                Are you a Professional looking for customers? 
                <div className={`${classes.signUpBtn}`} onClick={onSignUpClick} role="button">Sign-Up</div>
            </div>
            <PagesFooter />
        </>
    )
}

export default Career;