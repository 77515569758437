import React, { useEffect, useMemo, useState } from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import gift_header_img from 'assets/img/501809-PHWI2V-89.png';
import gift_bottom_img from 'assets/img/gift-a-service-bottom.JPG';
import classes from './style.module.scss';
import _ from 'lodash';
import { Helmet } from "react-helmet";
import axios from 'services/axios';
import { useHistory, useLocation } from 'react-router-dom';
import creditCard from 'assets/img/credit-card.svg';
import creditCardW from 'assets/img/credit-card-w.svg';
import clsx from 'clsx';
import { getAllPaymentMethods } from 'services/settings';
import Dropdown from 'components/Controls/Dropdown';
import ReadOnlyDetails from 'components/ReadOnlyDetails';
import { GIFT_A_SERVICE_CHARGE } from 'lib/constants';
import { getCurrnecyCode } from 'lib/getCurrencyCode';
import { addRatingForUser } from 'services/rating';

const GiftAServicePayment = () => {
    const history = useHistory();
    const [msg, setMsg] = useState();
    const [startStatusApi, setStartStatusApi] = useState(false);
    const [method, setMethod] = useState(1); // 1 Mobile money, 2 Stripe
    const [mobile, setMobile] = useState();
    const [momoAccs, setMomoAccs] = useState();
    const [orderSummary, setOrderSummary] = useState({});
    const [userSummary, setUserSummary] = useState({});
    const location = useLocation();

    useEffect(() => {
        if (startStatusApi && orderSummary.orderId) {
            const checkStatus = () => {
                axios.get(`/pymt/giftaservice/${orderSummary.orderId}`)
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data && 'status' in response.data) {
                                if (response.data.status === 'successful') {
                                    history.replace('/p-s');
                                } else if (response.data.status === 'failed') {
                                    history.replace('/p-f');
                                }
                            }
                        }
                    })
            };
            checkStatus();
            const interval = setInterval(() => checkStatus(), 5000);
            return () => {
                clearInterval(interval);
            }
        }
    }, [startStatusApi]);

    const getMomoAccounts = async () => {
        const res = await getAllPaymentMethods();

        if (res.status === 200 && res.data.data && res.data.data.length > 0) {
            const lstMomo = res.data.data.map(i => ({ value: i.uid, label: i.mobile }));
            const primaryAcc = res.data.data.find(i => i.is_primary === true);
            setMomoAccs(lstMomo);
            setMobile(primaryAcc.uid);
        }
    }

    useEffect(() => {
        if (location && location.state && location.state.paymentSummary) {
            setOrderSummary(location.state.paymentSummary);
            setUserSummary(location.state.userSummary);
            getMomoAccounts();
        } else {
            history.push('/');
        }
    }, []);

    const onPayClick = () => {
        setMsg({
            type: 1,
            msg: "Please wait while we are processing your request..."
        });
        if (orderSummary && orderSummary.orderId) {
            addRatingForUser(userSummary);
            let payload = {
                "order_id": orderSummary.orderId,
            };
            if (method === 1) {
                payload = {
                    ...payload,
                    payerUId: mobile,
                    type: 'momo'
                }
            } else if (method === 2) {
                payload = {
                    ...payload,
                    type: 'stripe'
                }
            }
            axios.post('/pymt/checkout', payload)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.link) {
                            window.location.href = response.data.link;
                        }
                        if (method === 1) {
                            setStartStatusApi(true);
                        }
                    }
                    if (method == 2) {
                        setMsg(undefined);
                    }
                }).catch(() => {
                    setMsg(undefined);
                });
        }
    }

    const getTitle = useMemo(() => {
        switch (method) {
            case 1:
                return 'Pay with Mobile Money';

            case 2:
                return 'Pay with Stripe';
        }
    }, [method]);

    const getControls = useMemo(() => {
        switch (method) {
            case 1:
                return (
                    <>
                        {
                            mobile && (
                                <>
                                    <Dropdown
                                        options={momoAccs || []}
                                        onChange={(event) => {
                                            setMobile(event.target.value);
                                        }}
                                        value={mobile}
                                        label="Mobile"
                                        id="Mobile"
                                    />
                                    <div className="mt-1 f-14"><i>Make sure you've KYC done for the entered phone number</i></div>
                                </>
                            )
                        }
                    </>
                );

            case 2:
                return (
                    <>
                    </>
                );
        }
    }, [method, momoAccs, mobile]);

    const getAmount = () => {
        return `${_.get(orderSummary, 'amount', '')} + ${GIFT_A_SERVICE_CHARGE} = ${getCurrnecyCode()} ${parseFloat(orderSummary.amount) + parseFloat(GIFT_A_SERVICE_CHARGE)}`
    }

    return (
        <>
            <Helmet>
                <title>Gift A Service</title>
            </Helmet>
            <PagesHeader />
            {/* <PageTitleSection
                minHeight="400px"
                image={gift_header_img}
                search={false}
                blueTitle=""
                title1="Gift a Service to family,"
                title2="friends or anyone else"
                button={{ text: 'Find A Service to Gift' }}
            /> */}
            <div className="container-lg full-center">
                <div className="mt-5 mb-5">
                    <div className={classes.formWrapper}>
                        <div className={classes.title}>Select Payment Method</div>
                        <div className="row mt-4">
                            <div className="col-xs-12 col-sm-12 col-md-8 mb-3">
                                <div className={classes.paymentOptions}>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <div className={classes.paymentOptionsWrapper}>
                                                <div
                                                    onClick={() => setMethod(1)}
                                                    className={clsx(method === 1 && classes.confirmPayMethodActive, classes.confirmPayMethod)}
                                                >
                                                    <div>
                                                        <img src={creditCard} className={clsx(method === 1 && 'd-none')} />
                                                        <img src={creditCardW} className={clsx(method !== 1 && 'd-none')} />
                                                    </div>
                                                    <div>
                                                        <div className={classes.pName}>Mobile Money</div>
                                                        <div className={classes.pDesc}>Pay with Mobile Money</div>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => setMethod(2)}
                                                    className={clsx(method === 2 && classes.confirmPayMethodActive, classes.confirmPayMethod)}
                                                >
                                                    <div>
                                                        <img src={creditCard} className={clsx(method === 2 && 'd-none')} />
                                                        <img src={creditCardW} className={clsx(method !== 2 && 'd-none')} />
                                                    </div>
                                                    <div>
                                                        <div className={classes.pName}>Stripe</div>
                                                        <div className={classes.pDesc}>Pay with Stripe</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <div className="ml-3 mr-3">
                                                <div>
                                                    <div className={classes.paymentOptionTitle}>
                                                        {getTitle}
                                                    </div>
                                                    <div className="mt-3">
                                                        {getControls}
                                                    </div>
                                                </div>
                                                <div className="mt-5">
                                                    <button disabled={momoAccs === undefined || (msg && msg.type === 1)} className="btn lp-button1 fwBold w-100" onClick={onPayClick}>Pay Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-2 text-center">
                                            {msg && (
                                                <span className={clsx('h6', msg.type === 1 && 'text-success', msg.type === 0 && 'text-danger')}>
                                                    {msg.msg}
                                                </span>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4">
                                <div className={classes.paymentSummary}>
                                    <div className={classes.paymentSummaryTitle}>
                                        Service Gift Summary
                                    </div>
                                    <div>
                                        <div className="mb-3"><ReadOnlyDetails title="Beneficiary Name" data={_.get(orderSummary, 'name', '')} /></div>
                                        <div className="mb-3"><ReadOnlyDetails title="Service Category" data={_.get(orderSummary, 'service_category', '')} /></div>
                                        <div className="mb-3"><ReadOnlyDetails title="Service" data={_.get(orderSummary, 'service', '')} /></div>
                                        <div className="mb-3"><ReadOnlyDetails title="Service Provider" data={_.get(orderSummary, 'service_provider', '')} /></div>
                                        <div className="mb-3"><ReadOnlyDetails title="Amount" data={<b>{getAmount()}</b>} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={`${classes.bottomBanner}`}>
                <div className={`container-lg`}>
                    <div className={`row`}>
                        <div className="col-lg-7 col-md-12 col-sm-12 side_section d-flex flex-column justify-content-center">
                            <div className={`${classes.bottomBannerTitle}`}>
                                Ask for the kind of support that anyone would provide
                            </div>
                            <div className={`${classes.bottomBannerSubTitle}`}>
                                Reach out and engage busy family or friends to help you solve a problem, execute a project or pay a bill.
                            </div>
                            <div className={`${classes.bottomBannerTextBtn}`}>
                                Request for a Service Gift
                            </div>
                        </div>
                        <div className="col-12 col-md-4 image_section text-right">
                            <img alt="" className="img-fluid mt-5 mt-md-auto" src={gift_bottom_img} />
                        </div>
                    </div>
                </div>
            </div> */}
            <PagesFooter showFooter={false} />
        </>
    )
}

export default GiftAServicePayment;