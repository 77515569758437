import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import axios from 'services/axios';
import Card from 'react-bootstrap/Card';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';

import ServiceProfileCard from '../../components/pages/ServiceProfileCard';
import providerProfileImgDummy from '../../assets/img/user-244.png';
import { isUserLoggedIn } from 'lib/helpers';
import { useHistory } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import PreviewImage from 'components/PreviewImage';
import ReadMoreLess from 'components/ReadMoreLess';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function ServiceProvidersDetails() {
    const history = useHistory();
    let searchParams = new URL(window.location.href);
    let serviceType = searchParams.pathname.split('/')[2]
    let displayType = serviceType.split('-').join(' ')
    let providerId = searchParams.pathname.split('/')[3]

    const [providersData, setProvidersData] = useState([]);
    let backLink = {
        text: (
            <div className="d-flex align-items-center">
                <ArrowBackIosIcon sx={{ fontSize: 18 }} />
                Back to List
            </div>
        ), link: '/service/'+serviceType
    }

    useEffect( () => {
		let url = '/user/pro/' + providerId
		
        axios.get(url)
        .then(response => {
            if(response.status === 200) {
                setProvidersData(response.data.data)
            }
        })
        .catch(err => {
            console.log(err)
        });  
    }, [setProvidersData]);

    const onButttonClick = () => {
        if (!isUserLoggedIn()) {
            history.push('/login');
            return true;
        }
        return false;
    }

    const onPaymentButtonClick = () => {
        if (onButttonClick() === false) {
            history.push({ 
                pathname: '/payment',
                state: {
                    id: providerId
                }
            });
        }
    }

    const getProfilePic = () => {
        if (providersData && providersData.profile_id) {
            return `https://acdito.com/v1/api/files/${providersData.profile_id}`;
        }

        return providerProfileImgDummy;
    }

    const getBusinessFiles = () => {
        if (providersData && providersData.business_files) {
            return providersData.business_files.map(x => `https://acdito.com/v1/api/files/${x}`);
        }

        return [];
    }

    const onContactMeClick = () => {
        if (!onButttonClick()) {
            history.push(`/hire-pro/${serviceType}/${providerId}`);
        }
    }

    return (
        <>
           <Helmet>
                <title>All Services</title>
                <meta property="og:title" content="AcDiTo - Find verified local professionals in minutes - It's free and simple" />
                <meta name="description" content="Find the perfect professional for your services needs" />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="200px" search={false} backLink={backLink} blueTitle={serviceType.split('-').join(' ')} />
            <div className="full-center container my-5 provider-details-page card">
                {providersData && providersData.first_name &&
                    <div className="row">
                        <div className="col-12 col-md-2 col-lg-1 mt-4 text-center">
                            <Card.Img className="service-provider-img" variant="top" src={getProfilePic()} />
                        </div>
                        <div className="col-12 col-md-10 col-lg-11">
                            <ServiceProfileCard 
                                name={providersData.first_name + " " + providersData.last_name.charAt(0)}
                                isVerified={providersData.is_verified}
                                location={providersData.country_name}
                                hires={"Hires on AcDiTo"}
                                hiresCnt={providersData.order_count || 0}
                                reviews={`(${providersData.rating_count || 0})`}
                                stars={providersData.rating}
                            />
                            <div className="service-provide-section card-body">
                                {/* <h6 className="text-secondary">{displayType} Photos</h6> */}
                                {
                                    providersData.business_files && (
                                        <>
                                            <h6 className="text-secondary">{serviceType.split('-').join(' ')} Photos</h6>
                                            <div className="service-provider-business-photos">
                                                {getBusinessFiles().map(x => {
                                                    return <PreviewImage src={x}>
                                                        <Image src={x} rounded />
                                                    </PreviewImage>
                                                })}
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    providersData.servicecategory_name && (
                                        <>
                                            <h6 className="text-secondary">Category</h6>
                                            <p>{providersData.servicecategory_name}</p>
                                        </>
                                    )
                                }
                                {providersData.services && (
                                    <>
                                        <h6 className="text-secondary">Services</h6>
                                        <ul className="list-unstyled">
                                            {providersData.services.map(ele => {
                                                    return <li><i className="fas fa-check-circle check-green mr-3"></i>{ele.name}</li>
                                            })}
                                        </ul>
                                    </>
                                )}
                                {
                                    providersData.service_description && (
                                        <>
                                            <h6 className="text-secondary">Description</h6>
                                            <ReadMoreLess
                                                charLimit={250}
                                            >
                                                {providersData.service_description}
                                            </ReadMoreLess>
                                        </>
                                    )
                                }
                            </div>
                            <div className="col-12 d-flex">
                                <div className="mb-3 mr-3 text-center">
                                    <button onClick={onContactMeClick} type="button" className="btn btn-block send-button tx-tfm">Contact me</button>
                                </div>
                                <div className="mb-3 mr-3 text-center">
                                    <button onClick={onPaymentButtonClick} type="button" className="btn btn-block send-button tx-tfm">Payment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default ServiceProvidersDetails
