import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config/config';

const baseURL = config.API_URL;
let iPendingReq = 0;

const AxiosService = axios.create({
    baseURL,
});

AxiosService.interceptors.request.use(
    (req) => {
        let currUser = JSON.parse(localStorage.getItem("currUser"))
        if(currUser && currUser.token !== '') {
            req.headers.Authorization = currUser.token;
        }
        // showAppLoader();
        iPendingReq++;
        return req;
    },
    (error) => {
        // showAppLoader();
        return Promise.reject(error);
    }
);

AxiosService.interceptors.response.use(
    (response) => {
        iPendingReq--;

        if (iPendingReq === 0) {
            // hideAppLoader();
        }
        return response;
    },
    (error) => {
        iPendingReq--;
        if (iPendingReq === 0) {
            // hideAppLoader();
        }
        try {
            let errmsg = error.response.data[Object.keys(error.response.data)[0]];
            toast.error(errmsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch(e) {
            
        }
        return Promise.reject(error);
    },
);

export default AxiosService;