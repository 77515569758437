import React, { useState, useEffect } from 'react'
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import PagesHeader from '../../components/Navbars/PageHeader';
import { useDispatch, useSelector } from "react-redux";
import axios from 'services/axios';
import Alerts from '../../components/alerts/alerts'
import UploadDocuments from 'components/UploadDocuments';
import { toast } from 'react-toastify';
import { isFileImage } from 'lib/helpers';
import moment from 'moment';
import { maxSize, PROFILE_DOCUMENT_ITEM } from 'lib/constants';
import { isUserLoggedIn } from 'lib/helpers';
import { useHistory } from 'react-router-dom';
import { getProfile } from 'services/profile';
import { getAllCategories, getSpecialities } from 'services/category';
import clsx from 'clsx';
import PageTitleSection from 'containers/PageTitleSection';
import ReadOnlyDetails from 'components/ReadOnlyDetails';
import PreviewImage from 'components/PreviewImage';
import Input from 'components/Controls/Input';
import Dropdown from 'components/Controls/Dropdown';
import MultiSelect from 'components/Controls/MultiSelect';
import { getCurrnecyCode } from 'lib/getCurrencyCode';
import { setUserProfileId } from 'features/user-data';
import PagesFooterSub from 'components/Footer/PageFooterSub';

function SetupProfile(props) {
    const history = useHistory();
    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);

    const [first_name, setFirst_name] = useState(user.first_name);
    const [last_name, setLast_name] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [mobile, setMobile] = useState(user.mobile);
    const [dob, setDob] = useState('');
    const [business_name, setBusiness_name] = useState('');
    const [services, setServices] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [selectedSpecialities, setSelectedSpecialities] = useState('');
    const [specialities, setSpecialities] = useState('');
    const [business_address, setBusiness_address] = useState('');
    const [description, setDescription] = useState('');
    const [documents, setDocuments] = useState();
    const [apiMessagae, setApiMessagae] = useState();
    const [allData, setAllData] = useState();

    // Error states
    const [first_nameError, setFirst_nameError] = useState('');
    const [last_nameError, setLast_nameError] = useState('');
    const [dobError, setDobError] = useState('');
    const [business_nameError, setBusiness_nameError] = useState('');
    const [selectedServiceError, setSelectedServiceError] = useState('');
    const [selectedSpecialitiesError, setSelectedSpecialitiesError] = useState('');
    const [business_addressError, setBusiness_addressError] = useState('');

    const [alertMsg, setAlertMsg] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [image, setImage] = useState({ preview: "", fileId: "" });
    const [businessImages, setBusinessImages] = useState();

    const loadInit = async () => {
        const allCateforiesRes = await getAllCategories();

        if (allCateforiesRes.status === 200 && allCateforiesRes.statusText === 'OK') {
            let servicesListArray = allCateforiesRes.data
            let servicesList = servicesListArray.data.map((service) => {
                return { value: service.id, label: service.name }
            })
            setServices(servicesList);
        }

        const profileRes = await getProfile();
        let rData = profileRes.data.data;

        if (profileRes.status === 200 && profileRes.statusText === 'OK') {
            if (rData.role != "serviceprovider") {
                history.push('/');
            }
            setFirst_name(rData.first_name);
            setLast_name(rData.last_name);
            setEmail(rData.email);
            setMobile(rData.mobile);
            setDob(moment(new Date(rData.dob)).format('YYYY-MM-DD'));
            setBusiness_name(rData.business_name);
            setSelectedService(rData.servicecategory_id);
            setBusiness_address(rData.business_address);
            if (rData.service_description) {
                setDescription(rData.service_description);
            }
            if (rData.profile_id) {
                setImage({ preview: `https://acdito.com/v1/api/files/${rData.profile_id}`, fileId: rData.profile_id })
            }
            if (rData.user_files) {
                setDocuments(rData.user_files);
            }
            if (rData.business_files) {
                const tBFiles = [];
                rData.business_files.forEach(file => {
                    tBFiles.push({
                        preview: `https://acdito.com/v1/api/files/${file}`,
                        fileId: file
                    })
                });
                setBusinessImages(tBFiles);
            }
            setAllData(rData);
        }

        if (rData.servicecategory_id) {
            const tServices = rData.services.map((ser) => {
                return { ...ser, value: ser.id };
            })
            setSelectedSpecialities(tServices);
        }
    }

    useEffect(() => {
        if (!isUserLoggedIn()) {
            history.push('/login')
        }
        loadInit();
    }, [])

    useEffect(() => {
        if (selectedService) {
            getSpecialities(selectedService).then((allSpecialitiesRes) => {
                if (allSpecialitiesRes.status === 200 && allSpecialitiesRes.statusText === 'OK') {

                    const specialitiesList = allSpecialitiesRes.data.data.map((curr) => {
                        return { value: curr.id, label: curr.name }
                    })
                    setSpecialities(specialitiesList);
                }
            });
        }
    }, [selectedService])

    const handleProfileChange = e => {
        if (e.target.files.length) {
            var fileSize = e.target.files[0].size; // in bytes
            if (fileSize > maxSize) {
                toast.error('File size exceeded the maximum allowed size');
                return false;
            }

            if (!isFileImage(e.target.files[0])) {
                toast.error('Please select an image');
                return false;
            }

            var data = new FormData();
            data.append('input_files', e.target.files[0]);

            axios.post('/files/upload/profile', data).then((res) => {
                if (res.data.files.length > 0) {
                    setImage({
                        preview: URL.createObjectURL(e.target.files[0]),
                        fileId: res.data.files[0]
                    });
                }
            });
        }
    };

    const handlePhotoVideo = e => {
        if (e.target.files.length) {
            if (e.target.files.length > 4) {
                toast.error('Maximum 4 files are allowed');
                return false;
            }
            const anyErrors = Array.from(e.target.files).some(file => {
                var fileSize = file.size; // in bytes
                if (fileSize > maxSize) {
                    toast.error('File size exceeded the maximum allowed size');
                    return true;
                }

                if (!isFileImage(file)) {
                    toast.error('Please select an image');
                    return true;
                }
            });

            if (!anyErrors) {
                const files = [];
                var data = new FormData();
                Array.from(e.target.files).forEach(file => {
                    data.append('input_files', file);
                });
                axios.post('/files/upload/business', data).then((res) => {
                    if (res.data.files.length > 0) {
                        Array.from(res.data.files).forEach(file => {
                            files.push({
                                preview: `https://acdito.com/v1/api/files/${file}`,
                                fileId: file,
                            });
                        });
                        setBusinessImages(files);
                    }
                });
            }
        }
    }

    const submitProfile = () => {
        if (!isEdit) {
            setIsEdit(!isEdit);
            return;
        }
        setApiMessagae(undefined);
        !first_name ? setFirst_nameError("Please Enter First Name.") : setFirst_nameError('')
        !last_name ? setLast_nameError("Please Enter Last Name.") : setLast_nameError('')
        !dob ? setDobError("Please Enter Date of Birth.") : setDobError('')
        !business_name ? setBusiness_nameError("Please Enter Business Name.") : setBusiness_nameError('')
        !business_address ? setBusiness_addressError("Please Enter Business Address.") : setBusiness_addressError('')
        !selectedService ? setSelectedServiceError("Please select Service Type.") : setSelectedServiceError('')
        !selectedSpecialities ? setSelectedSpecialitiesError("Please select Specialities.") : setSelectedSpecialitiesError('')


        if (first_name && last_name && dob && business_name && business_address && selectedService && selectedSpecialities) {

            let specialityList = [];
            if (selectedSpecialities.length > 0) {
                selectedSpecialities.forEach(element => {
                    specialityList.push(element.value)
                });
            }
            let profileData = {
                "first_name": first_name,
                "last_name": last_name,
                "servicecategory_id": selectedService,
                "dob": dob,
                "business_name": business_name,
                "business_address": business_address,
                "services": specialityList,
                "profile_id": image.fileId === '' ? undefined : image.fileId,
                "business_files": businessImages ? businessImages.map(a => a.fileId) : undefined,
                "user_files": documents ? {
                    [PROFILE_DOCUMENT_ITEM['Doc-Id']]: documents['Doc-Id']?.id,
                    [PROFILE_DOCUMENT_ITEM['License']]: documents['License']?.id,
                    [PROFILE_DOCUMENT_ITEM['Tax-Id']]: documents['Tax-Id']?.id,
                } : undefined,
                "service_description": description,
            }
            dispatch(setUserProfileId(profileData.profile_id));

            axios.post('/user/profilesetup', profileData)
                .then(response => {
                    if (response.status === 200 && response.statusText === 'OK') {
                        setApiMessagae({
                            type: 1,
                            msg: 'Profile updated successfully'
                        });
                        setTimeout(() => {
                            history.push('/');
                        }, 2000);
                    } else { return false }
                })
                .catch(err => {
                    let errmsg = err.response.data[Object.keys(err.response.data)[0]];
                    setAlertMsg(errmsg)
                    setShowAlert(false)
                    setShowAlert(true)
                    return false
                });
        }
    }

    const getSelectedService = () => {
        if (selectedService && services) {
            return services.find(x => x.value === selectedService);
        }
    }

    const onDescriptionChange = (event) => {
        const LIMIT = 150;
        const value = event.target.value.trim();
        let totalWords = [];
        totalWords = value.split(/\s+/).filter(Boolean);

        if (totalWords.length > LIMIT) {
            let trimmed = totalWords.slice(0, LIMIT).join(" ");
            setDescription(trimmed + ' ');
        } else {
            setDescription(event.target.value);
        }
    }

    const uploadDiv = (
        <div className="add-profile-document">
            <div className="add-profile-document-plus">
                <i className="fa fa-plus"></i>
            </div>
        </div>
    )

    const businessPhotosElement = (
        businessImages && businessImages.length > 0 ? (
            <div className="business-photos">
                {
                    businessImages.map(file => (
                        <div className="mb-2">
                            <PreviewImage src={file.preview}>
                                <Image src={file.preview} rounded />
                            </PreviewImage>
                        </div>
                    ))
                }
                {
                    isEdit && (
                        <div className="mt-2">
                            <label htmlFor="uploadPhotoVideo" className="mb-0">
                                {uploadDiv}
                            </label>
                        </div>
                    )
                }
            </div>
        ) : null
    )

    return (
        <>
            {/* {!user.first_name && <Redirect to={{ pathname: "/" }} />} */}
            {/* {redirect && <Redirect to={{ pathname: "/" }} />} */}
            <PagesHeader />
            { showAlert && <Alerts msg={alertMsg} show={showAlert} type="danger" />}
            <PageTitleSection minHeight="120px" blueTitle="My Profile" />
            <div className="container-fluid my-5 setupProfile">
                <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="upload-photo">
                                <span className="fa-stack fa-2x mt-3 mb-2 bg-secondary rounded-circle upload-photo-icon">
                                    {
                                        image.preview ? (
                                            <div>
                                                <img src={image.preview} className="profile-pic" alt="dummy" width="300" height="300" />
                                                {
                                                    isEdit && (
                                                        <div className="rounded-circle upload-photo-icon-small">
                                                            <i className="fa fa-edit text-white"></i>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : <i className="fa fa-camera text-white"></i>
                                    }
                                </span>
                                <hr className="profile-pic-seperator" />
                                <div className="profile-pic-verify">
                                    {
                                        allData?.is_verified ? (
                                            <>
                                                <i className="fas fa-check text-success"></i>
                                                    Verified
                                            </>
                                        ) : (
                                                <>
                                                    <i className="fas fa-times text-danger"></i>
                                                    Not Verified
                                                </>
                                            )
                                    }
                                </div>
                            </label>
                            <input
                                type="file"
                                name="photo"
                                id="upload-photo"
                                style={{ display: "none" }}
                                onChange={handleProfileChange}
                                accept="image/*"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-xl-8 profile-controls">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="first_name"
                                            label="First Name"
                                            variant="outlined"
                                            onChange={e => setFirst_name(e.target.value)}
                                            value={first_name}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="First Name" data={first_name} />
                                        )
                                }
                                {first_nameError ? <span style={{ color: "red" }}>{first_nameError}</span> : ''}
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            id="last_name"
                                            label="Last Name"
                                            variant="outlined"
                                            onChange={e => setLast_name(e.target.value)}
                                            value={last_name}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Last Name" data={last_name} />
                                        )
                                }
                                {last_nameError ? <span style={{ color: "red" }}>{last_nameError}</span> : ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            type="email"
                                            id="email"
                                            label="Email Address"
                                            variant="outlined"
                                            disabled={true}
                                            value={email}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Email Address" data={email} />
                                        )
                                }
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            type="text"
                                            id="email"
                                            label="Mobile No."
                                            variant="outlined"
                                            disabled={true}
                                            value={mobile}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Mobile No." data={mobile} />
                                        )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            type="date"
                                            id="dob"
                                            label="Date of Birth"
                                            variant="outlined"
                                            onChange={e => {
                                                setDob(e.target.value)
                                            }}
                                            value={dob}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Date of Birth" data={dob} />
                                        )
                                }
                                {dobError ? <span style={{ color: "red" }}>{dobError}</span> : ''}
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            type="text"
                                            id="business_name"
                                            label="Business Name or Your Name"
                                            variant="outlined"
                                            onChange={e => {
                                                setBusiness_name(e.target.value)
                                            }}
                                            value={business_name}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Business Name or Your Name" data={business_name} />
                                        )
                                }
                                {business_nameError ? <span style={{ color: "red" }}>{business_nameError}</span> : ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 form-group">
                                {
                                    isEdit ? (
                                        <Dropdown
                                            options={services}
                                            onChange={(event) => {
                                                setSelectedSpecialities([]);
                                                setSelectedService(event.target.value);
                                            }}
                                            value={getSelectedService()?.value}
                                            label="Service Type"
                                            id="service-type"
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Service Type" data={getSelectedService()?.label || ''} />
                                        )
                                }
                                {selectedServiceError ? <span style={{ color: "red" }}>{selectedServiceError}</span> : ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            type="text"
                                            id="descriptions"
                                            label="Descriptions"
                                            variant="outlined"
                                            onChange={onDescriptionChange}
                                            value={description}
                                            minRows={3}
                                            multiline={true}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Descriptions" data={description} />
                                        )
                                }
                            </div>
                        </div>
                        <div className="row">
                            {selectedService &&
                                <div className="col-12 form-group">
                                    {
                                        isEdit ? (
                                            <MultiSelect 
                                                selectProps={{
                                                    options: specialities,
                                                    value: selectedSpecialities,
                                                    onChange: (event, key) => {
                                                        setSelectedSpecialities([...new Map(key.map(item => [item.value, item])).values()]);
                                                    }
                                                }}
                                                inputProps={{
                                                    label: 'Specialities',
                                                    placeholder: 'Select Specialities'
                                                }}
                                            />
                                        ) : (
                                                <ReadOnlyDetails title="Specialities" data={selectedSpecialities ? selectedSpecialities.map(function (k) { return k.label }).join(", ") : ''} />
                                            )
                                    }
                                    {selectedSpecialitiesError ? <span style={{ color: "red" }}>{selectedSpecialitiesError}</span> : ''}
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-12 form-group">
                                <ReadOnlyDetails title="Business Photos" data={businessPhotosElement} />
                                <input
                                    type="file"
                                    name="photoVideo"
                                    id="uploadPhotoVideo"
                                    className="d-none"
                                    onChange={handlePhotoVideo}
                                    accept="image/*"
                                    multiple
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 form-group">
                                {
                                    isEdit ? (
                                        <Input
                                            type="text"
                                            id="business_address"
                                            label="Business Address"
                                            variant="outlined"
                                            onChange={e => setBusiness_address(e.target.value)}
                                            value={business_address}
                                            minRows={3}
                                            multiline={true}
                                        />
                                    ) : (
                                            <ReadOnlyDetails title="Business Address" data={business_address} />
                                        )
                                }
                                {business_addressError ? <span style={{ color: "red" }}>{business_addressError}</span> : ''}
                            </div>
                        </div>
                        <div className="row">
                            {
                                documents && (
                                    <div className="col-12 form-group" key={Object.keys(documents).length}>
                                        <ReadOnlyDetails
                                            title="Documents"
                                            data={<UploadDocuments isEdit={isEdit} documents={documents} onSubmit={setDocuments} />}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        {!isEdit && allData && allData.balance && (
                            <div className="row">
                                <div className="col-12 form-group">
                                    <ReadOnlyDetails title="Earnings" data={`${getCurrnecyCode()} ${allData.balance}`} />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 form-group">
                                <button disabled={allData === undefined} type="button" onClick={submitProfile} className="btn btn-block send-button tx-tfm" >
                                    {isEdit ? 'Save Profile' : 'Edit Profile'}
                                </button>
                            </div>
                        </div>
                        {
                            apiMessagae && (
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <span className={clsx('h5', apiMessagae.type === 1 && 'text-success')}>{apiMessagae.msg}</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <PagesFooterSub />
        </>
    )
}

export default SetupProfile
