import React, { useState, useEffect } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { inject, observer } from 'mobx-react';
import Loader from 'components/Loader';
import { ToastContainer } from 'react-toastify';

// Containers
import LandingPage from './containers/Landing/LandingPage';
import AllServices from './containers/Service/AllServices';
import serviceProviders from './containers/Service/serviceProviders';
import ServiceProvidersDetails from './containers/Service/ServiceProvidersDetails'
import Signup from './containers/Auth/Signup';
import Login from './containers/Auth/Login';
import Verification from './containers/Auth/Verification';
import ForgotPassword from './containers/Auth/ForgotPassword';
import SetupProfile from './containers/Service/SetupProfile';
import StudentsProgram from './containers/Service/StudentsProgram';
import PrivacyPolicy from './containers/Service/PrivacyPolicy';
import TermsCondition from './containers/Service/TermsCondition';
import AboutUs from './containers/Service/AboutUs';
import GrowYourBusiness from './containers/Service/GrowYourBusiness';
import CorporateServices from './containers/Service/CorporateServices';
import GetHelp from './containers/Service/GetHelp';
import Contact from './containers/Contact';
import Career from 'containers/Career';
import ProfileWrapper from 'containers/Service/ProfileWrapper';
import BookPro from 'containers/BookPro';
import PaymentSuccess from 'containers/DisplayPages/PaymentSuccess';
import PaymentFailed from 'containers/DisplayPages/PaymentFailed';
import Settings from 'containers/Settings';
import Payment from 'containers/Payment';
import Projects from 'containers/Projects';
import ProjectsDetails from 'containers/ProjectsDetails';
import GiftAService from 'containers/GiftAService';
import GiftAServicePayment from 'containers/GiftAServicePayment';
import RateReview from 'containers/RateReview';
import { useDispatch } from 'react-redux';
import { updateSelectedCountry, updateAllCountries, updateAllUserCountries } from "features/country";
import { setUserProfileId, setUserCountrySettingCode, setUserCountry } from "features/user-data";
import axios from 'services/axios';
import { getProfile } from 'services/profile';
import TokenPage from 'containers/TokenPage/TokenPage';
import AboutPage from 'containers/AboutPage/AboutPage';
import FinAidPage from 'containers/FinAid/FinAid';

const AuthRoute = ({ component: Component, ...rest }) => {
    let currUser = JSON.parse(localStorage.getItem("currUser"));

  return <Route {...rest} render={(props) => (currUser ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

const App = () => {
    let currUser = JSON.parse(localStorage.getItem("currUser"));
    const dispatch = useDispatch();
    let scale = 1;
    if (window.outerWidth < 1000) scale = 1;

    const getProfileData = async () => {
        if (currUser) {
            const profileRes = await getProfile();
    
            if (profileRes.status === 200 && profileRes.data.data) {
                dispatch(setUserProfileId(profileRes.data.data.profile_id));
                dispatch(setUserCountrySettingCode(profileRes.data.data?.country_setting?.code));
                dispatch(setUserCountry(profileRes.data.data?.country_name));
                localStorage.setItem("currUser", JSON.stringify({
                    ...currUser,
                    country_setting_code: profileRes.data.data?.country_setting?.code
                }))
            }
        }
    }

    useEffect(() => {
        axios.get('/countries')
            .then(response => {
                if (response.status === 200) {
                    let countries = response.data.serviceProviderCountries.map(curr => { return { 'label': curr.name, 'value': curr.code } })
                    dispatch(updateAllCountries(countries));
                    dispatch(updateSelectedCountry({ 'label': response.data.serviceProviderCountries[0].name, 'value': response.data.serviceProviderCountries[0].code }));
                    dispatch(updateAllUserCountries(response.data.serviceUserCountries));
                }
            })
            .catch(err => {
                console.log(err)
            });

        getProfileData();
    }, []);

    return (
        <>
            <MetaTags>
            <title>AcDiTo | Help Family Pay Bills - Unlock Discount Tokens</title>
                    <meta key="meta:description" name="description" content="AcDiTo empowers consumers worldwide to better assist families at home and abroad - unlocking billions in global discounts" />
                    <meta key="og:title" property="og:title" content="AcDiTo | Help Family Pay Bills - Unlock Discount Tokens" />
                    <meta key="og:description" property="og:description" content="AcDiTo empowers consumers worldwide to better assist families at home and abroad - unlocking billions in global discounts" />
                    <meta property="og:image" content="http://acdito.com/static/media/logo.f966dc8a.png"></meta>
                    <meta property="og:site_name" content="AcDiTo"/>
                <meta name="viewport" content={`width=device-width, initial-scale=${scale}`} />
            </MetaTags>
            <Loader />
            <Switch>
                <Route exact path="/token" component={TokenPage} />
                <Route exact path="/about" component={AboutPage} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/all-services" component={AllServices} />
                <Route exact path="/service/:name" component={serviceProviders} />
                <Route exact path="/details/:name/:id" component={ServiceProvidersDetails} />
                <Route exact path="/students-program" component={StudentsProgram} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/verification" component={Verification} />
                <Route exact path="/setup-profile" component={ProfileWrapper} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/terms-condition" component={TermsCondition} />
                <Route exact path="/about" component={AboutUs} />
                <Route exact path="/grow-business" component={GrowYourBusiness} />
                <Route exact path="/corporate-services" component={CorporateServices} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/get-help" component={GetHelp} />
                <Route exact path="/hire-pro/:name/:id" component={BookPro} />
                <Route exact path="/career" component={Career} />
                <Route exact path="/p-s" component={PaymentSuccess} />
                <Route exact path="/p-f" component={PaymentFailed} />
                <Route exact path="/payment" component={Payment} />
                <Route exact path="/projects" component={Projects} />
                <Route exact path="/projects/:id" component={ProjectsDetails} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/finaid" component={FinAidPage} />
                <AuthRoute exact path="/gift-service" component={GiftAService} />
                <AuthRoute exact path="/gift-service/payment" component={GiftAServicePayment} />
                <AuthRoute exact path="/review" component={RateReview} />
                <Route exact path="/" component={LandingPage} />
                
            </Switch>
            <ToastContainer />
        </>
    );
}

export default App;
