import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './style.scss';
import { InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import clsx from 'clsx'

const Dropdown = ({ options, ...rest }) => {
    const tLabelId = rest.id + 'label-id';
    const tValue = typeof rest.value === 'object' ? rest.value.value : rest.value;

    return (
        <FormControl style={{ width: '100%' }}>
            <InputLabel className={clsx("custom-drp-label", tValue ? 'reset' : '')} id={tLabelId}>{rest.label}</InputLabel>
            <Select
                {...rest}
                labelId={tLabelId}
                className="custom-dropdown"
                IconComponent={() => <KeyboardArrowDownIcon />}
                value={tValue}
            >
                {
                    options.map((item) => {
                        return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )
}

export default Dropdown;