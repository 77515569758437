import React from 'react';
import './style.css';
import clsx from 'clsx';

export const showAppLoader = () => {
    const ele = document.querySelector('[data-loader-id="app-loader"]');

    if (ele) {
        ele.classList.remove('d-none');
    }
};

export const hideAppLoader = () => {
    const ele = document.querySelector('[data-loader-id="app-loader"]');

    if (ele) {
        ele.classList.add('d-none');
    }
};

const Loader = (props) => {
    const { show = false } = props;
    return (
        <div className={clsx('loaderWrapper', show === false && 'd-none')} data-loader-id={show ? "temp-app-loader" : "app-loader"}>
            <div className='lds-ripple'><div></div><div></div></div>
        </div>
    )
}

export default Loader;