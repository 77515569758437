import React, { useState, useMemo, useEffect } from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import axios from 'services/axios';
import { useParams } from 'react-router-dom';
import PageTitleSection from '../PageTitleSection';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReadOnlyDetails from 'components/ReadOnlyDetails';
import { get } from 'lodash';
import { getUserRole } from 'lib/helpers';
import moment from 'moment';
import { getCurrnecyCode } from 'lib/getCurrencyCode';
import { GIFT_A_SERVICE_CHARGE } from 'lib/constants';
import { toNumberFromString } from 'lib/utils';
import StarRatings from 'react-star-ratings';

const Projects = () => {
    const [project, setProject] = useState();
    const { id = null } = useParams();
    const isServiceUser = getUserRole() === 'serviceuser';
    const backLink = {
        text: (
            <div className="d-flex align-items-center">
                <ArrowBackIosIcon sx={{ fontSize: 18 }} />
                Back
            </div>
        )
    }

    const loadInit = () => {
        if (id) {
            axios.get(`/order/${id}`)
            .then(response => {
                if(response.status === 200 && response.data.length > 0) {
                    setProject(response.data[0]);
                }
            })
        }
    }
    
    useEffect(() => {
        loadInit();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);
    
    const getName = () => {
        const name = getUserRole() === 'serviceuser' ? get(project, 'serviceprovider_name', '') : get(project, 'serviceuser_name', '');

        if (name) {
            return name;
        }
    }

    const getBudget = () => {
        const budget = getUserRole() === 'serviceuser' ? get(project, 'budget', '') : get(project, 'provider_budget', '');

        if (budget) {
            return budget;
        }
    }

    const getBeneficiaryDetails = () => {
        if (project.type !== 'giftaservice') return null;

        return (
            <div className="row mt-3">
                <div className="col-12 mb-3 font-weight-bold h5">
                    Beneficiary Details
                </div>
                <div className="col-12 col-sm-6 mb-3">
                    <ReadOnlyDetails title="Beneficiary Name" data={get(project, 'beneficiary_name', '')} />
                </div>
                {
                    isServiceUser && (
                        <div className="col-12 col-sm-6 mb-3">
                            <ReadOnlyDetails title="Service Charges" data={`${getCurrnecyCode()} ${GIFT_A_SERVICE_CHARGE}`} />
                        </div>
                    )
                }
                <div className="col-12 col-sm-6 mb-3">
                    <ReadOnlyDetails title="Beneficiary Email" data={get(project, 'b_email', '')} />
                </div>
                <div className="col-12 col-sm-6 mb-3">
                    <ReadOnlyDetails title="Beneficiary Mobile" data={get(project, 'b_mobile', '')} />
                </div>
            </div>
        )
    }

    const getPayment = () => {
        if (isServiceUser) {
            return `${getCurrnecyCode()} ${getBudget()}`;
        }
        return `${getCurrnecyCode()} ${toNumberFromString(getBudget()) - toNumberFromString(get(project, 'servicecharge_amount', 0))}`;
    }

    return (
        <>
            <PagesHeader />
            <div className="full-center">
                <PageTitleSection minHeight="120px" blueTitle="Project Details" backLink={backLink} />
                <div className="container mt-5">
                    {
                        project && (
                            <>
                                <div className="row">
                                    <div className="col-12 col-sm-6 mb-3">
                                        <ReadOnlyDetails title="Name" data={getName()} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <ReadOnlyDetails title="Location" data={get(project, 'location', '')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6 mb-3">
                                        <ReadOnlyDetails title="Service Category" data={get(project, 'servicecategory_name', '')} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <ReadOnlyDetails title="Services" data={get(project, 'services', []).join(', ')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <ReadOnlyDetails title="Description" data={get(project, 'requirement_description', '')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6 mb-3">
                                        <ReadOnlyDetails title="Payment Status" data={get(project, 'payment_status', null) === null ? 'Not initiated' : get(project, 'payment_status', '')} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <ReadOnlyDetails title="Payment" data={getPayment()} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <ReadOnlyDetails title="Created At" data={moment(get(project, 'created_at', '')).format('D MMM YYYY, hh:mm a')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6 mb-3">
                                        <ReadOnlyDetails title="Rating" data={
                                            <StarRatings
                                                rating={get(project, 'rating', 0) === null ? 0 : project.rating}
                                                starRatedColor={'#00b9f2'}
                                                starHoverColor={'#00b9f2'}
                                                numberOfStars={5}
                                                starDimension={'20px'}
                                                name='rating'
                                                starSpacing={'5'}
                                            />} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <ReadOnlyDetails title="Review" data={get(project, 'review', '')} />
                                    </div>
                                </div>
                                {getBeneficiaryDetails()}
                            </>
                        )
                    }
                </div>
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default Projects;