import React, { useState } from 'react';
import classes from './style.module.scss';
import { Button, Dialog, Radio } from '@mui/material';
import Input from 'components/Controls/Input';
import Dropdown from 'components/Controls/Dropdown';
import Error from 'components/Error';
import { addPaymentMethods, verifyAccountPaymentMethod, resendOtp, updatePrimaryAccountPaymentMethod } from 'services/settings';
import OtpInput from 'react-otp-input';
import _ from 'lodash';
import { toast } from 'react-toastify';

const AddLinkedAccModal = (props) => {
    const { onClose, serviceTypes, getAllLinkedAcc } = props;
    const INIT_DATA = {
        "mobile":"",
        "owner_name" : "",
        "type": "momo"
    }
    const [formData, setFormData] = useState(INIT_DATA);
    const [errors, setErrors] = useState({});
    const [screen, setScreen] = useState(1); // 1 - Account add, 2 - OTP Verification
    const [otp, setOtp] = useState(null);
    const [accountData, setAccountData] = useState();

    const handleChange = (otp) => setOtp(otp);

    const verifyOtp = async () => {
        if (otp && otp.length === 6 && accountData.uid) {
            const data = await verifyAccountPaymentMethod(accountData.uid, otp);

            if (data.status === 200) {
                getAllLinkedAcc();
                toast.success('Account has been successfully added.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                onClose();
            }
        }
    }

    const resendOtpClick = () => {
        if (accountData.uid) {
            resendOtp(accountData.uid);
        }
    }

    const updateFormData = (value, key) => {
        const tFormData = {
            ...formData,
            [key]: value
        }
        setFormData(tFormData);
    }

    const onAddAccountSave = async () => {
        setErrors({});
        let tErrors = {};

        if (_.isEmpty(formData.mobile)) {
            tErrors['mobile'] = 'Please add your MSISDN No.';
        }

        if (_.isEmpty(formData.owner_name)) {
            tErrors['owner_name'] = 'Please add owner name.';
        }
        
        if (Object.keys(tErrors).length === 0) {
            const data = await addPaymentMethods(formData);

            if (data.status === 200) {
                setAccountData(data.data);
                toast.success('Account has been successfully added.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                onClose();
                getAllLinkedAcc();
                // setScreen(2);
            }
        } else {
            setErrors(tErrors);
        }
    }

    const checkError = (key) => {
        if (errors && errors[key]) {
            return errors[key]
        }
        return undefined;
    }

    const getTitle = () => {
        if (screen === 1) {
            return 'Add New Account';
        }

        return 'OTP Verification'
    }

    const getControls = () => {
        switch (screen) {
            case 1:
                return (
                <>
                    <div>
                        <Input
                            id="mobile"
                            label="MSISDN No."
                            variant="outlined"
                            value={formData.mobile}
                            onChange={(event) => {
                                if (/^[0-9]*$/.test(event.target.value)) {
                                    updateFormData(event.target.value, 'mobile')
                                }
                            }}
                            inputProps={{
                            maxLength: 12,
                            }}
                            required
                        />
                        <div className="mt-1 f-14"><i>Make sure you've KYC done for the entered phone number</i></div>
                        {checkError('mobile') && <Error text={checkError('mobile')} />}
                    </div>
                    <div>
                        <Input
                            id="owner_name"
                            label="Owner Name"
                            variant="outlined"
                            value={formData.owner_name}
                            onChange={(e) => {
                                updateFormData(e.target.value, 'owner_name')
                            }}
                            inputProps={{
                            maxLength: 250,
                            }}
                            required
                        />
                        {checkError('owner_name') && <Error text={checkError('owner_name')} />}
                    </div>
                    <div>
                        <Dropdown
                            options={serviceTypes}
                            label="Account Type"
                            id="type"
                            value={formData.type}
                            onChange={(e) => {
                                updateFormData(e.target.value, 'type')
                            }}
                        />
                    </div>
                </>
                )

            case 2:
                return (
                    <>
                        <div className="text-center">
                            OTP has been sent to your Mobile no. Please verify
                        </div>
                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            containerStyle="col-12 mt-5 justify-content-center"
                            inputStyle="otpInput"
                            separator={<span className="mx-2"></span>}
                        />
                    </>
                )
        }
    }

    const getButtonControls = () => {
        switch(screen) {
            case 1:
                return (
                    <>
                        <div className="mr-3">
                            <Button className="blueBtn" onClick={onAddAccountSave}>Save</Button>
                        </div>
                        <div>
                            <Button className="whiteBtn" onClick={onClose}>Cancel</Button>
                        </div>
                    </>
                )

            case 2:
                return (
                    <div className="row mt-3 text-center">
                        <div className="col-md-12 mx-auto text-center mb-2">
                            <button className="otpButton btn mt-3" onClick={verifyOtp}>
                                <i className="fa fa-3x fa-arrow-circle-right"></i>
                            </button>
                        </div>
                        <div className="col-md-12 mx-auto text-center">
                            <span>Didn't receive the OTP?</span><br></br><button className="resendBtn btn btn-link link-blue" onClick={resendOtpClick}>Resend Code</button>
                        </div>
                    </div>
                )
        }
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
        >
          <div className={classes.dialogWrapper}>
              <div className={classes.dialogTitle}>
                {getTitle()}
              </div>
              <div className={classes.dialogInputs}>
                {getControls()}
              </div>
              <div className="d-flex justify-content-center">
                  {getButtonControls()}
              </div>
          </div>
        </Dialog>
    )
}

export default AddLinkedAccModal;