import React, { useEffect } from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import AboutUsHeader from '../../assets/img/AboutUs-header.png';
import GiftService from '../../assets/img/giftservice1.png';
import Megaphone from '../../assets/img/megaphone.png';

export default function AboutUs() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <>
            <Helmet>
                <title>About</title>
                <meta property="og:title" content="AcDiTo - About Us | Learn More About Our Mission and Vision" />
                <meta key="og:description" property="og:description" content="Learn more about AcDiTo, the platform connecting consumers with reliable local services in Africa. Discover our mission, vision, values, and goals. Meet our team and see how we’re making a difference." />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="120px" blueTitle="About Us" title1="AcDiTo is powering small businesses, freelancers, and contractors with access to global opportunities" image={AboutUsHeader}/>
            <div className="container my-5">
                <div className="row">
                    <div className="col-12">
                        <p>For ten years, Gilbert Mbeh helped big corporations with operations in the Middle East, United States, Europe, and Africa design digital networks that manage facilities, expedite construction and modernize supply chains. Then he returned home to tackle a challenge that affects millions of African consumers every day: easing the search for local services providers with precisely the right skills, experience, and reputation.</p>
                        <p>Today, AcDiTo is modernizing the services landscape in Africa. Millions of African consumers are very familiar with the hurdles associated with 
                            finding and engaging, reliable providers of the services they require. AcDiTo empowers these consumers with an online, digital network 
                            where consumers can find, select, and engage service providers and contractors based on crowd-sourced reviews.</p>
                        <p>AcDiTo unlocks a range of services through easy-to-use web and mobile apps. In seconds, users can find trusted local businesses and 
                            professionals that match specific needs and locations. Busy users save valuable time and money tracking down appropriate providers in 
                            business services, health and wellness, legal, and home improvement services.</p>
                        <p>Launched in November 2019, AcDiTo is helping thousands of contractors, small businesses, and freelancers by boosting their visibility, 
                            accelerating their growth, and keeping their customers engaged with them anytime, from anywhere worldwide.</p>
                        <p>To be listed on the directory, service providers <Link to="/signup">sign up for a free account</Link>, customize it, add the services they offer, and wait for 
                            projects to come their way.</p>
                        <p>Responding to the COVID-19 pandemic, the AcDiTo team expanded their healthcare services to include a Coronavirus Help Desk, where 
                            concerned individuals get answers to COVID-19 related questions from medical professionals.</p>
                    </div>
                </div>

                <div className="row py-5">
                    <div className="col-12 col-md-7 order-3 order-md-1" >
                       <h3 className="mb-3">Introducing AcDiTo Gift-a-Service</h3>
                       <p>In early 2021, the AcDiTo team created Gift-a-Service. A business model and 
                            rollout plan were established with support from the global product development 
                            group from Questrom School of Business at Boston University. With this new 
                            service, Africans living anywhere globally can engage with professional service 
                            providers, ease emergencies, or assist family and friends with bill payments. 
                            They may use this efficient, low-cost resource for legal fees, new business 
                            registration, home repairs, hospital charges, emergency travel expenses, 
                            or any other purposes.</p>
                        <h3 className="my-4">Working at AcDiTo</h3>
                        <p>We work with full-time and part-time employees, advisors, contractors, consultants, 
                        freelancers, and students from all walks of life, genders, and we find our strength in 
                        cultural diversity.</p>
                    </div>
                    <div className="col-1 d-none d-md-block order-md-2"></div>
                    <div className="col-md-4 order-1 order-md-3">
                        <img src={GiftService} className="img-fluid" alt="Gift service" height="320" />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row aboutus-footer py-4">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <img src={Megaphone} alt="Megaphone" className="mr-3" />
                        <h4>AcDiTo - making life easier - anytime, anywhere.</h4>
                    </div>
                </div>
            </div>
            <PagesFooter />
        </>
    )
}
