import React, { useState } from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import Alerts from '../../components/alerts/alerts'
import { BrowserRouter } from 'react-router-dom';
import { Redirect, useHistory } from 'react-router'
import OtpInput from 'react-otp-input';
import { useSelector } from "react-redux";
import axios from 'services/axios';
import {Helmet} from "react-helmet";

function Verification_functional(props) {
    const [redirect, setRedirect] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('danger');
    const history = useHistory();
    try {
        let currUser = JSON.parse(localStorage.getItem("currUser"))
        if(currUser.token && currUser.token !== '') {
            history.push('/');
        }
    }catch(e){}
    const user = useSelector((state) => state.user.value);
    const [otp, setOtp] = useState(null);
    const handleChange = (otp) => setOtp(otp);
    
    let mobileNo = null;
    try{
        mobileNo = props.history.location.state.mobile
    } catch (err) {
        console.log(err)
    }

    
    const verifyOtp = () => {
        if(otp && otp.length === 6) {
            let data = {
                mobile: user.mobile,
                otp_code: otp
            }
            axios.post('/auth/verifyotp', data)
                .then(response => {
                    if(response.status === 200 && response.statusText === 'OK') {
                        setRedirect(true)
                    } else { return false }
                })
                .catch(err => {
                    let errmsg = err.response.data[Object.keys(err.response.data)[0]];
                    setAlertMsg(errmsg)
                    setShowAlert(false)
                    setShowAlert(true)
                    return false
                });
        }
        
    }

    const resendOtp = () => {
        let data = {
            mobile: user.mobile
        }
        axios.post('/auth/resendotp', data)
            .then(response => {
                if(response.status === 200 && response.statusText === 'OK') {
                    setAlertMsg('OTP resent succesfully')
                    setAlertType('warning')
                    setAlertType('success')
                    setShowAlert(false)
                    setShowAlert(true)
                    return false
                } else { return false }
            })
            .catch(err => {
                let errmsg = err.response.data[Object.keys(err.response.data)[0]];
                setAlertMsg(errmsg)
                setAlertType('warning')
                setAlertType('danger')
                setShowAlert(false)
                setShowAlert(true)
                return false
            });
    }

    return (
        <>
            {redirect && <Redirect to={{ pathname: "/login", state: { verified: true } }} />}
            <PagesHeader />
            <Helmet>
                <title>OTP Verification</title>
            </Helmet>
            {showAlert && <Alerts msg={alertMsg} show={showAlert} type="danger" />}
            <div className="container fmroboto mt-5 mb-5">
                <div className="row mt-5">
                    <div className="col-md-6 mx-auto text-center">
                        <div className="otp-verify-title">OTP Verification</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mx-auto text-center">
                        <div className="row mt-5">
                            <span className="col-12">
                                OTP has been sent to your registered <strong>Mobile No.</strong> or{' '}
                                <strong>Email Address</strong>. Please verify
                            </span>
                            <OtpInput
                                value={otp}
                                onChange={handleChange}
                                numInputs={6}
                                containerStyle="col-12 mt-5 justify-content-center"
                                inputStyle="otpInput"
                                separator={<span className="mx-2"></span>}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-1 mx-auto text-center">
                        <button className="otpButton btn mt-3" onClick={verifyOtp}>
                            <i className="fa fa-3x fa-arrow-circle-right"></i>
                        </button>
                    </div>
                </div>
                <div className="row mt-3 text-center">
                    <div className="col-md-12 mx-auto text-center">
                        <span>Don't recieve the OTP?</span><br></br><button className="resendBtn btn btn-link link-blue" onClick={resendOtp}>Resend Code</button>
                    </div>
                </div>
            </div>

            <PagesFooter showFooter={false} copyClass={true} />
        </>
    );
    
}

export default Verification_functional;
