import React from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import { Helmet } from 'react-helmet';
import images from '../../assets/image/index';

import 'bootstrap/dist/css/bootstrap.min.css';

class TokenPage extends React.Component {
    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>AcDiTo - Helping consumers save money online and offline</title>
                    <meta
                        key="meta:description"
                        name="description"
                        content="Tokenized discount platform that help consumers save money online and offline."
                    />
                    <meta
                        key="og:title"
                        property="og:title"
                        content="AcDiTo - Helping consumers save money online and offline"
                    />
                    <meta
                        key="og:description"
                        property="og:description"
                        content="Tokenized discount platform that help consumers save money online and offline."
                    />
                </Helmet>
                <PagesHeader />
                <>
                    {' '}
                    <div className="fmroboto">
                        <div className="landingpage_section1">
                            <div className="row" style={{ margin: 'auto' }}>
                                <div className="col-lg-3 col-md-3"></div>
                                <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center landing_jumbotron justify-content-center">
                                    <div className="">
                                        {/* <div className="fs30 mb_25 fwBold yellow text-center">
                                    Building a credible decentralized coupon system
                                </div> */}
                                        <div className="fs32 mb_25 fwBold text-center">
                                            Crypto for Discount Coupons and More
                                        </div>
                                        <div className="text-center">
                                            <button className="lp-primary-btn lp-bg-tobottom">Buy ACT</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3"></div>
                            </div>
                            <div className="row" style={{ margin: 'auto' }}>
                                <div className="col-12" id="landing-page-caorusel">
                                    <img
                                        // className="img-fluid"
                                        style={{ maxWidth: '100%', opacity: 0.5, objectFit: 'contain' }}
                                        src={images.banner1}
                                        alt="slider1"
                                    />
                                </div>
                            </div>
                        </div>
                        {/*  */}
                        <div className="landingpage_section2 pt-5 mt-5">
                            <div className="container">
                                <div className="row mt_40">
                                    <div className="col-md-7 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="lp-title-text">For Businesses</div>
                                            <div className="mt_20 lp-description-text">
                                                AbegCoin Tokens make it easy for you to turn discounts you offer on your
                                                products and services into tradeable assets with durable market value
                                                for your business, and your customers and attract even more clients from
                                                the +350 million crypto holders around the world. Market economics will
                                                incentivize the first ACT holders to seek discounts.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <img
                                            className="img-fluid"
                                            style={{ height: '340px' }}
                                            src={images.tokenpage1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="landingpage_section2 pt-5 mt-5">
                            <div className="container">
                                <div className="row mt_40">
                                    <div className="col-md-5">
                                        <img
                                            className="img-fluid"
                                            style={{ height: '340px' }}
                                            src={images.tokenpage2}
                                        />
                                    </div>
                                    <div className="col-md-7 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="lp-title-text">For Individuals</div>
                                            <div className="mt_20 lp-description-text">
                                                You will never waste time searching for discounts again, with AbegCoin
                                                Tokens, you can own your discount coupons, store them, send them to
                                                family and friends, buy and sell them on crypto exchanges, and issue it
                                                in exchange for discounts on retail websites. Use ACT to shop online and
                                                offline differently, No matter where you live, what you do, or how much
                                                you earn.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="landingpage_section2 pt-5 mt-5">
                            <div className="container">
                                <div className="row mt_40">
                                    <div className="col-md-7 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="lp-title-text">For Developers</div>
                                            <div className="mt_20 lp-description-text">
                                                The AbegCoin SDK is fostering a vibrant community of developers building
                                                products and services to help businesses provide customers with a
                                                trusted and innovative discount coupon experience. ACT SDK is
                                                production-build-ready and has excellent support for integration with
                                                any client's legacy applications.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <img
                                            className="img-fluid"
                                            style={{ height: '340px' }}
                                            src={images.tokenpage3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="landingpage_section2 pt-5 mt-5">
                            <div className="container">
                                <div className="row mt_40">
                                    <div className="col-md-5">
                                        <img
                                            className="img-fluid"
                                            style={{ height: '340px' }}
                                            src={images.tokenpage4}
                                        />
                                    </div>
                                    <div className="col-md-7 d-flex align-items-center">
                                        <div className="d-block">
                                            <div className="lp-title-text">Universal</div>
                                            <div className="mt_20 lp-description-text">
                                                The AbegCoin token is for everyone with an entry-level smartphone and
                                                data connectivity. Transactions are quick and easy, no matter where you
                                                are sending, receiving, or spending your tokens, online or offline.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                <PagesFooter />
            </>
        );
    }
}

export default TokenPage;
