import React, { useState, useEffect } from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import classes from './style.module.scss';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { Button, Radio } from '@mui/material';
import sim from 'assets/img/settings-sim-card.svg';
import wallet from 'assets/img/settings-wallet.svg';
import user from 'assets/img/settings-user.svg';
import { getAllPaymentMethods, updatePrimaryAccountPaymentMethod } from 'services/settings';
import _ from 'lodash';
import AddLinkedAccModal from './AddLinkedAccModal';
import clsx from 'clsx';
import WithdrawMoney from './WithdrawMoneyForm';
import HistoryIcon from '@mui/icons-material/History';
import TransactionHistory from './TransactionHistory';
import { getUserRole } from 'lib/helpers';

const SERVICES_TYPE = [
    { value: "momo", label: "MTN MoMo" },
]

const getAccTypeLabel = (val) => {
    return SERVICES_TYPE.find(i => i.value === val)?.label;
}

const SettingItem = (props) => {
    const { data, getAllLinkedAcc } = props;
    const { mobile, owner_name, type, is_primary, uid } = data;

    const onPrimaryChkClick = async () => {
        if (uid) {
            await updatePrimaryAccountPaymentMethod(uid);
            getAllLinkedAcc();
        }
    }

    return (
        <div className={classes.settingItem}>
            <div className="d-flex flex-wrap">
                <div className={classes.settingItemCmp}>
                    <div>
                        <img src={sim} />
                    </div>
                    <div className={classes.settingItemCmpDetails}>
                        <div>MSISDN No.</div>
                        <div>{mobile}</div>
                    </div>
                </div>
                <div className={classes.settingItemCmp}>
                    <div>
                        <img src={user} />
                    </div>
                    <div className={classes.settingItemCmpDetails}>
                        <div>Owner Name</div>
                        <div>{owner_name}</div>
                    </div>
                </div>
                <div className={classes.settingItemCmp}>
                    <div>
                        <img src={wallet} />
                    </div>
                    <div className={classes.settingItemCmpDetails}>
                        <div>Account Type</div>
                        <div>{getAccTypeLabel(type)}</div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column">
                <div className="text-center">
                    <Radio
                        checked={is_primary}
                        className={classes.settingItemPrimaryChk}
                        onChange={onPrimaryChkClick}
                        value="a"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </div>
                <label>Set as Primary</label>
            </div>
        </div>
    )
}

const getScreen = () => {
    var searchParams = new URLSearchParams(window.location.search);
    switch(searchParams.get('v')) {
        case '2':
            return 2;

        case '1':
        default:
            return 1;
    }
}

const Settings = () => {
    const [open, setOpen] = useState(false);
    const [allLinkedAccount, setAllLinkedAccount] = useState();
    const [settingItem, setSettingItem] = useState(getScreen());

    const SETTING_OPTIONS = getUserRole() !== 'serviceuser' ? [
        {
            id: 1,
            title: 'Linked Account',
            icon: <SettingsIcon />
        },
        {
            id: 2,
            title: 'Withdraw',
            icon: <LocalAtmIcon />
        },
        {
            id: 3,
            title: 'Transaction History',
            icon: <HistoryIcon />
        }
    ] : [
        {
            id: 1,
            title: 'Linked Account',
            icon: <SettingsIcon />
        },
        {
            id: 3,
            title: 'Transaction History',
            icon: <HistoryIcon />
        }
    ]

    const getAllLinkedAcc = async () => {
        const res = await getAllPaymentMethods();

        if (res.status === 200) {
            setAllLinkedAccount(res.data.data);
        }
    }

    useEffect(() => {
        getAllLinkedAcc();
    }, []);

    const getMoMoOptions = () => {
        if (allLinkedAccount) {
            return allLinkedAccount.map(i => ({ value: i.uid, label: i.mobile }))
        }

        return [];
    }

    const getContent = () => {
        switch (settingItem) {
            case 1:
                return (
                    <div className={classes.itemsWrapper}>
                        <div className={classes.itemsHeader}>
                            <div>Linked Account</div>
                            <div>
                                <Button className="blueBtn" onClick={() => setOpen(true)}>Add New Account</Button>
                            </div>
                        </div>
                        <div>
                            {
                                allLinkedAccount && allLinkedAccount.map(item => <SettingItem data={item} key={item.uid} getAllLinkedAcc={getAllLinkedAcc} />)
                            }
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className={classes.itemsWrapper}>
                        <div className={classes.itemsHeader}>
                            <div>Request a Payment</div>
                        </div>
                        <WithdrawMoney momoOptions={getMoMoOptions()} />
                    </div>
                )
            case 3:
                return (
                    <div className={classes.itemsWrapper}>
                        <div className={classes.itemsHeader}>
                            <div>Transaction History</div>
                        </div>
                        <TransactionHistory />
                    </div>
                )
        }
    }

    return (
        <>
            <PagesHeader />
            {/* <PageTitleSection minHeight="120px" blueTitle="Settings" /> */}
            {open && <AddLinkedAccModal serviceTypes={SERVICES_TYPE} onClose={() => setOpen(false)} getAllLinkedAcc={getAllLinkedAcc} />}
            <div className="full-center container-lg">
                <div className="row mt-5 mb-3">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className={classes.subSettings}>
                            <div className={classes.subSettingsHeader}>
                                Settings
                            </div>
                            <div>
                                {
                                    SETTING_OPTIONS.map(i => (
                                        <div
                                            key={i.id}
                                            onClick={() => setSettingItem(i.id)}
                                            className={clsx(classes.subSettingsItem, settingItem === i.id && classes.subSettingsItemActive)}
                                        >
                                            {i.icon} {i.title}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8">
                        {getContent()}
                    </div>
                </div>
            </div>
            <PagesFooter showFooter={false} />
        </>
    )
}

export default Settings;