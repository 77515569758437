import React, { useEffect, useRef, useState } from 'react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import gift_header_img from 'assets/img/501809-PHWI2V-89.png';
import gift_bottom_img from 'assets/img/gift-a-service-bottom.JPG';
import classes from './style.module.scss';
import Input from 'components/Controls/Input';
import PhoneInput from 'components/Controls/PhoneInput';
import Dropdown from 'components/Controls/Dropdown';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import ReadOnlyDetails from 'components/ReadOnlyDetails';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Error from 'components/Error';
import { Helmet } from "react-helmet";
import { getAllCategories, getSpecialities } from 'services/category';
import axios from 'services/axios';
import { Autocomplete } from '@mui/lab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import schema from './form.schema.js';
import { getProfilePic } from 'lib/getProfilePic';
import { InputAdornment } from '@mui/material';
import { getCurrnecyCode } from 'lib/getCurrencyCode';
import { useHistory } from 'react-router-dom';
import { GIFT_A_SERVICE_CHARGE } from 'lib/constants';

const GiftAService = () => {
    const history = useHistory();
    const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm({
        resolver: yupResolver(schema)
    });
    const userData = useSelector((state) => {
        return state.userData.value
    });
    const countryData = useSelector((state) => {
        return state.country.value
    });
    const [specialities, setSpecialities] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [providersData, setProvidersData] = useState([]);
    
    const COUNTRY_NAME = _.get(userData, 'country_name', ' ');

    const loadInit = async () => {
        const allCateforiesRes = await getAllCategories();

        if (allCateforiesRes.status === 200) {
            let servicesListArray = allCateforiesRes.data
            let services = servicesListArray.data.map((service) => {
                return { value: service.id, label: service.name }
            });
            setAllCategories(services);
        }
    }

    const getSpecialitiesOnChange = (id) => {
        let url = `/service/v1?country=${COUNTRY_NAME}&is_giftaservice=true&servicecategory_id=${id}`;

        axios.get(url).then((allSpecialitiesRes) => {
            if (allSpecialitiesRes.status === 200) {
                const specialitiesList = allSpecialitiesRes.data.data.map((curr) => {
                    return { value: curr.id, label: curr.name }
                })
                setSpecialities(specialitiesList);
            }
        });
    }

    const getProviderOnChange = () => {
        const serviceId = getValues('service_id');
        const serviceName = specialities.find(i => i.value === serviceId);
        if (serviceName && serviceName.label) {
            let url = `/user/getpro?service_name=${serviceName.label}&country=${COUNTRY_NAME}`;

            axios.get(url)
                .then(response => {
                    if (response.status === 200) {
                        const allList = response.data.data.map(i => ({ label: `${i.first_name} ${i.last_name}`, value: i.id, profileId: i.profile_id }))
                        setProvidersData(allList);
                    }
                })
                .catch(err => {
                    setProvidersData([])
                    console.log(err)
                });
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        loadInit();
    }, []);

    const onSubmit = async data => {
        let paymentSummary = {
            name: `${data.b_first_name} ${data.b_last_name}`,
            service_category: allCategories.find(i => i.value === data.servicecategory_id)?.label,
            service: specialities.find(i => i.value === data.service_id)?.label,
            service_provider: data.serviceprovider_id.label,
            amount: data.budget,
        };
        let userSummary = {
            name: data.serviceprovider_id.label,
            profileId: data.serviceprovider_id.profileId,
        }
        const payload = {
            ...data,
            service_id: `${data.service_id}`,
            country: COUNTRY_NAME,
            serviceprovider_id: data.serviceprovider_id.value,
            budget: parseFloat(data.budget) + GIFT_A_SERVICE_CHARGE,
            b_country: data.b_country.name,
            b_mobile: data.b_mobile.replace(`+${data.b_country.dialCode}`, '')
        }

        axios.post('/order/giftaservice', payload)
        .then(response => {
            if (response.status === 200 && response.data) {
                paymentSummary.orderId = response.data.id;
                history.push({ 
                    pathname: '/gift-service/payment',
                    state: {
                        paymentSummary,
                        userSummary: {
                            ...userSummary,
                            id: response.data.id
                        }
                    }
                });
            }
        });
    };

    const getEnableCountries = () => {
        if (countryData && countryData.allUserCountries.length > 0) {
            const allAvaCountry = countryData.allCountries.map((i) => i.label);
            const filterdCountries = countryData.allUserCountries.filter((i) => {
                return allAvaCountry.includes(i.name);
            })
            return filterdCountries.map(i => i.iso_code.toLowerCase());
        }

        return []
    }

    return (
        <>
            <Helmet>
                <title>Gift A Service</title>
                <meta property="og:title" content="Use AcDiTo Gift Service to assist family, friends, or anyone else." />
                <meta key="og:description" property="og:description" content="Instantly assist your family, friends, or anyone else by paying for the service they need. Engage a professional service, ease an emergency or help pay a bill." />
            </Helmet>
            <PagesHeader />
            {/* <PageTitleSection
                minHeight="400px"
                image={gift_header_img}
                search={false}
                blueTitle=""
                title1="Gift a Service to family,"
                title2="friends or anyone else"
                button={{ text: 'Find A Service to Gift' }}
            /> */}
            <div className="container-lg full-center">
                <div className="mt-5 mb-5">
                    {
                        COUNTRY_NAME === 'Cameroon' ? (
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <div className={classes.formWrapper}>
                                    <div className={classes.title}>Gift A Service</div>
                                    <div className="row mt-4">
                                        <div className="col-xs-12 col-sm-12 col-md-4">
                                            <div className={`${classes.subTitle} mb-3`}>Beneficiary Details</div>
                                            <div>
                                                <div className="mb-4">
                                                    <Controller
                                                        name={"b_first_name"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                type="text"
                                                                id="b_first_name"
                                                                label="First Name"
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 250 }}
                                                                onChange={onChange}
                                                                value={value}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.b_first_name?.message} />
                                                </div>
                                                <div className="mb-4">
                                                    <Controller
                                                        name={"b_last_name"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                type="text"
                                                                id="b_last_name"
                                                                label="Second Name"
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 250 }}
                                                                required
                                                                onChange={onChange}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.b_last_name?.message} />
                                                </div>
                                                <div className="mb-4">
                                                    <Controller
                                                        name={"b_email"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                type="email"
                                                                id="b_email"
                                                                label="Email"
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 250 }}
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.b_email?.message} />
                                                </div>
                                                <div className="mb-4">
                                                    <Controller
                                                        key={getEnableCountries().length}
                                                        name={"b_mobile"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <PhoneInput
                                                                onlyCountries={getEnableCountries()}
                                                                defaultCountry="cm"
                                                                id="b_mobile"
                                                                label="Mobile"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                onChange={(e, s) => {
                                                                    onChange(e);
                                                                    setValue('b_country', s);
                                                                }}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.b_mobile?.message} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-4">
                                            <div className={`${classes.subTitle} mb-3`}>Service Details</div>
                                            <div>
                                                <div className="mb-3">
                                                    <ReadOnlyDetails title="Country" data={COUNTRY_NAME} />
                                                </div>
                                                <div className="mb-4">
                                                    <Controller
                                                        name={"city"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                id="city"
                                                                label="City"
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 250 }}
                                                                required
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.city?.message} />
                                                </div>
                                                <div className="mb-4">
                                                    <Controller
                                                        name={"location"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                type="text"
                                                                id="location"
                                                                label="Location"
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 250 }}
                                                                value={value}
                                                                required
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.location?.message} />
                                                </div>
                                                <div className="mb-4">
                                                    <Controller
                                                        name={"servicecategory_id"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Dropdown
                                                                options={allCategories}
                                                                label="Service Category *"
                                                                id="servicecategory_id"
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    setProvidersData([]);
                                                                    setSpecialities([]);
                                                                    setValue('serviceprovider_id', undefined);
                                                                    setValue('service_id', undefined);
                                                                    getSpecialitiesOnChange(e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.servicecategory_id?.message} />
                                                </div>
                                                <div className="mb-4">
                                                    <Controller
                                                        key={getValues('service_id')}
                                                        name={"service_id"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Dropdown
                                                                options={specialities}
                                                                label="Service *"
                                                                id="service_id"
                                                                value={value}
                                                                onChange={(e) => {
                                                                    setProvidersData([]);
                                                                    setValue('serviceprovider_id', undefined);
                                                                    onChange(e);
                                                                    getProviderOnChange();
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.service_id?.message} />
                                                </div>
                                            </div>
                                        </div><div className="col-xs-12 col-sm-12 col-md-4">
                                            <div className={`${classes.subTitle} mb-3`}>&nbsp;</div>
                                            <div>
                                                <div className="mb-4">
                                                    <Controller
                                                        name={"requirement_description"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                type="text"
                                                                id="requirement_description"
                                                                label="Message"
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 250 }}
                                                                multiline={true}
                                                                minRows={6}
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.requirement_description?.message} />
                                                </div>
                                                <div className={`mb-4 ${classes.serviceProvider}`}>
                                                    <Controller
                                                        key={_.uniqueId()}
                                                        name={"serviceprovider_id"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Autocomplete
                                                                popupIcon={<KeyboardArrowDownIcon />}
                                                                id="country-select-demo"
                                                                options={providersData}
                                                                autoHighlight
                                                                getOptionLabel={(option) => option.label}
                                                                renderOption={(props, option) => (
                                                                    <div {...props}>
                                                                        {option.profileId && <img className={classes.optionImg} src={getProfilePic(option.profileId)} />}
                                                                        {option.label}
                                                                    </div>
                                                                )}
                                                                autoComplete="new-password"
                                                                onChange={(e,v) => {
                                                                    setValue('serviceprovider_id', v)
                                                                }}
                                                                value={value}
                                                                renderInput={(params) => (
                                                                    <Input
                                                                        {...params}
                                                                        required
                                                                        autoComplete="new-password"
                                                                        id="serviceprovider_id"
                                                                        label="Service Provider"
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.serviceprovider_id?.message} />
                                                </div>
                                                <div className="mb-4">
                                                    <Controller
                                                        key={getValues('budget')}
                                                        name={"budget"}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input
                                                                type="text"
                                                                id="budget"
                                                                label="Service Amount"
                                                                variant="outlined"
                                                                helperText={`Service fee ${GIFT_A_SERVICE_CHARGE} ${getCurrnecyCode()} `}
                                                                required
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <b>{getCurrnecyCode()}</b>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                onChange={(event) => {
                                                                    if (_.isEmpty(event.target.value) || /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(event.target.value)) {
                                                                        onChange(event)
                                                                    }
                                                                }}
                                                                inputProps={{ inputMode: 'numeric' }}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                    <Error text={errors.budget?.message} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <button type="submit" className="btn lp-button1 fwBold">View & Confirm Gift Details</button>
                                </div>
                            </form>
                            ) : (
                                <div className="text-center" style={{ height: '30vh'}}>
                                    {COUNTRY_NAME && <h5>This feature is not available in your region.</h5>}
                                </div>
                            )
                    }
                </div>
            </div>
            {/* <div className={`${classes.bottomBanner}`}>
                <div className={`container-lg`}>
                    <div className={`row`}>
                        <div className="col-lg-7 col-md-12 col-sm-12 side_section d-flex flex-column justify-content-center">
                            <div className={`${classes.bottomBannerTitle}`}>
                                Ask for the kind of support that anyone would provide
                            </div>
                            <div className={`${classes.bottomBannerSubTitle}`}>
                                Reach out and engage busy family or friends to help you solve a problem, execute a project or pay a bill.
                            </div>
                            <div className={`${classes.bottomBannerTextBtn}`}>
                                Request for a Service Gift
                            </div>
                        </div>
                        <div className="col-12 col-md-4 image_section text-right">
                            <img alt="" className="img-fluid mt-5 mt-md-auto" src={gift_bottom_img} />
                        </div>
                    </div>
                </div>
            </div> */}
            <PagesFooter showFooter={false} />
        </>
    )
}

export default GiftAService;