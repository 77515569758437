import ReadOnlyDetails from 'components/ReadOnlyDetails';
import React, { useState, useEffect } from 'react';
import classes from './style.module.scss';
import creditCard from 'assets/img/credit-card.svg';
import creditCardW from 'assets/img/credit-card-w.svg';
import clsx from 'clsx';
import { get } from 'lodash';
import axios from 'services/axios';
import { useSelector } from "react-redux";
import Error from 'components/Error';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { getAllPaymentMethods } from 'services/settings';
import Dropdown from 'components/Controls/Dropdown';
import { getCurrnecyCode } from 'lib/getCurrencyCode';
import { addRatingForUser } from 'services/rating';

const ConfirmPay = (props) => {
    const { getFormData, allData, orderDetails } = props;
    const countryData = useSelector((state) => state.country.value);
    const [method, setMethod] = useState(2); // 1 Mobile money, 2 Cards
    const [mobile, setMobile] = useState();
    const [momoAccs, setMomoAccs] = useState();
    const [errors, setErrors] = useState({});
    const [msg, setMsg] = useState();
    const [startStatusApi, setStartStatusApi] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (startStatusApi && orderDetails && orderDetails.id) {
            const checkStatus = () => {
                axios.get(`/pymt/momo/${orderDetails.id}`)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && 'status' in response.data) {
                            if (response.data.status === 'successful') {
                                history.replace('/p-s');
                            } else if (response.data.status === 'failed') {
                                history.replace('/p-f');
                            }
                        }
                    }
                })
            };
            checkStatus();
            const interval = setInterval(() => checkStatus(), 5000);
            return () => {
              clearInterval(interval);
            }
        }
    }, [startStatusApi])

    const getMomoAccounts = async () => {
        const res = await getAllPaymentMethods();

        if (res.status === 200 && res.data.data && res.data.data.length > 0) {
            const lstMomo = res.data.data.map(i => ({ value: i.uid, label: i.mobile }));
            const primaryAcc = res.data.data.find(i => i.is_primary === true);

            setMomoAccs(lstMomo);
            setMobile(primaryAcc.uid);
        }
    }

    useEffect(() => {
        getMomoAccounts();
    }, []);

    const getServiceText = () => {
        return getFormData('services_purchased').map(i => i.label).join(', ')
    }

    const getName = () => {
        return `${allData.first_name} ${allData.last_name.charAt(0)}.`
    }

    const onConfirm = () => {
        const tErrors = {};
        if (method === 1) {
            if (!mobile) {
                tErrors.mobile = "Please enter mobile number";
            }
        }
        if(_.isEmpty(tErrors)) {
            setErrors({});
            setMsg({
                type: 1,
                msg: "Please wait while we are processing your request..."
            })
            if (orderDetails && orderDetails.id) {
                addRatingForUser({
                    id: orderDetails.id,
                    name: getName(),
                    profileId: allData.profile_id
                })
                let payload = {
                    "order_id": orderDetails.id,
                };
                if (method === 1) {
                    payload = {
                        ...payload,
                        payerUId: mobile,
                        type: 'momo'
                    }
                } else if (method === 2) {
                    payload = {
                        ...payload,
                        type: 'stripe'
                    }
                }
                axios.post('/pymt/checkout', payload)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.link) {
                            window.location.href = response.data.link;
                        }
                        if (method === 1) {
                            setStartStatusApi(true);
                        }
                    } 
                    if (method == 2) {
                        setMsg(undefined);
                    }
                }).catch(() => {
                    setMsg(undefined);
                });
            }
        } else {
            setErrors(tErrors);
        }
    }

    const checkError = (key) => {
        if (errors && errors[key]) {
            return errors[key];
        }

        return undefined;
    }

    return (
        <div className={`container`}>
            <div className="row">
                <div className={`col-12 col-sm-12 col-md-6 ${classes.confirmPayMethods}`}>
                    <div
                        onClick={() => setMethod(2)}
                        className={clsx(method === 2 && classes.confirmPayMethodActive, classes.confirmPayMethod)}
                    >
                        <div>
                            <img src={creditCard} className={clsx(method === 2 && 'd-none')} />
                            <img src={creditCardW} className={clsx(method !== 2 && 'd-none')} />
                        </div>
                        <div>
                            <div className={classes.pName}>Card</div>
                            <div className={classes.pDesc}>Pay with Mastercard, Visa or Visa Electron</div>
                        </div>
                    </div>
                    <div style={{cursor: 'not-allowed'}}
                        // onClick={() => setMethod(1)}
                        className={clsx(method === 1 && classes.confirmPayMethodActive, classes.confirmPayMethod)}
                    >
                        <div>
                            <img src={creditCard} className={clsx(method === 1 && 'd-none')} />
                            <img src={creditCardW} className={clsx(method !== 1 && 'd-none')} />
                        </div>
                        <div>
                            <div className={classes.pName}>Mobile Money (Coming Soon)</div>
                            <div className={classes.pDesc}>Pay with Mobile Money</div>
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-sm-12 col-md-6 ${classes.confirmPaySummary}`}>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <ReadOnlyDetails
                                title="Name"
                                data={`${get(allData, 'first_name', '')} ${get(allData, 'last_name', '')}`}
                            />
                        </div>
                        <div className="col-12 mb-4">
                            <ReadOnlyDetails title="Services Purchased" data={getServiceText()} />
                        </div>
                        <div className="col-12 mb-4">
                            <ReadOnlyDetails title="Service Descriptions" data={getFormData('service_description')} />
                        </div>
                        <div className="col-12 mb-4">
                            <ReadOnlyDetails title="Price" data={`${getCurrnecyCode()} ${getFormData('price')}`} />
                        </div>
                        <div className="col-12 mb-4">
                            <ReadOnlyDetails title="Paying with" data={method === 1 ? 'Mobile Money' : 'Card'} />
                        </div>
                        {method === 1 && mobile && (
                            <div className="col-12 mb-4">
                                <Dropdown
                                    options={momoAccs || []}
                                    onChange={(event) => {
                                        setMobile(event.target.value);
                                    }}
                                    value={mobile}
                                    label="Mobile"
                                    id="Mobile"
                                />
                                <div className="mt-1 f-14">
                                    <i>Make sure you've KYC done for the entered phone number</i>
                                </div>
                                {/* <MuiPhoneNumber 
                                        onlyCountries={getEnableCountries()} 
                                        onChange={(value) => setMobile(value)}
                                        defaultCountry="ng"
                                        id="service_description"
                                        label="Mobile"
                                        variant="outlined" 
                                        error={checkError('mobile') != undefined}
                                        fullWidth
                                    /> */}
                                <div>{checkError('mobile') && <Error text={checkError('mobile')} />}</div>
                            </div>
                        )}
                        <div className="col-12 mb-1">
                            {msg && (
                                <span
                                    className={clsx(
                                        'h6',
                                        msg.type === 1 && 'text-success',
                                        msg.type === 0 && 'text-danger'
                                    )}
                                >
                                    {msg.msg}
                                </span>
                            )}
                        </div>
                        <div className="col-12 col-sm-6 col-md-8 mb-4">
                            <button
                                disabled={msg}
                                type="button"
                                onClick={onConfirm}
                                className="btn btn-block send-button tx-tfm"
                            >
                                Confirm &#38; Pay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPay;