import axios from 'services/axios';

export const getAllPaymentMethods = async () => {
    try {
        return await axios.get('/account/getAccountPaymentMethods');
    } catch(e) {
        console.log(e);
    }
}

export const addPaymentMethods = async (data) => {
    try {
        return await axios.post('/account/addAccountPaymentMethod', data);
    } catch(e) {
        console.log(e);
    }
}

export const updatePrimaryAccountPaymentMethod = async (uid) => {
    try {
        return await axios.post('/account/updatePrimaryAccountPaymentMethod', {
            uid
        });
    } catch(e) {
        console.log(e);
    }
}

export const verifyAccountPaymentMethod = async (uid, otp) => {
    try {
        return await axios.post('/account/verifyAccountPaymentMethod', {
            uid,
            otp
        });
    } catch(e) {
        console.log(e);
    }
}

export const resendOtp = async (uid) => {
    try {
        return await axios.post('/account/resendOtp', {
            uid
        });
    } catch(e) {
        console.log(e);
    }
}