import React from 'react'
import { Link } from 'react-router-dom';

function AllServicesIcon(props) {
    return (
        <div className="col-6 col-md-3 col-sm-4 mb-4 d-flex justify-content-center text-center">
            <Link to={props.link} className="text-decoration-none">
                <img
                    className="img-reponsive"
                    src={props.icon}
                    alt={props.icon}
                />
                <div className="mt-3 service-icon-name text-center text-dark">{props.name}</div>   
            </Link>
        </div>
    )
}

export default AllServicesIcon
