import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

function AccordianWidget(props) {

    let accordians = []
    for (let i = 0; i < props.items.length; i++) {
        accordians.push(
            <Accordion.Item className="mb-2" eventKey={i}>
                <Accordion.Header>{props.items[i].heading}</Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: props.items[i].content }} />
                </Accordion.Body>
            </Accordion.Item>
        );
    }
    return (
        <Accordion defaultActiveKey="0" className="accordianWidget my-3">
            {accordians}
        </Accordion>
    )
}

export default AccordianWidget
