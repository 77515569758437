import React, { useState, useEffect } from 'react';
import classes from './style.module.scss';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import PageTitleSection from '../PageTitleSection';
import { Helmet } from "react-helmet";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReadOnlyDetails from 'components/ReadOnlyDetails';
import Input from 'components/Controls/Input';
import InputAdornment from '@mui/material/InputAdornment';
import MultiSelect from 'components/Controls/MultiSelect';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import calendar_icon from 'assets/img/calendar.svg';
import clock_icon from 'assets/img/clock.svg';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import axios from 'services/axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import { getCurrnecyCode } from 'lib/getCurrencyCode';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const currentDateTime = new Date();
const defaultTime = currentDateTime.toLocaleTimeString('en-US', {
  hour: '2-digit',
  minute: '2-digit',
});

const INIT_FORM_DATA = {
    describe_your_need: '',
    budget: '',
    sub_services: [],
    select_date: new Date(),
    select_time: defaultTime,
}

const CalendarIcon = () => {
    return <img src={calendar_icon}/>
}

const ClockIcon = () => {
    return <img src={clock_icon}/>
}


const BookPro = () => {
    let searchParams = new URL(window.location.href);
    let serviceType = searchParams.pathname.split('/')[2]
    let displayType = serviceType.split('-').join(' ')
    let providerId = searchParams.pathname.split('/')[3]
    const [formData, setFormData] = useState(INIT_FORM_DATA);
    const [specialities, setSpecialities] = useState([]);
    const [providersData, setProvidersData] = useState();
    const [apiMessagae, setApiMessagae] = useState();
    const history = useHistory();
    const country = useSelector((state) => state.country.value);
    const [errors, setErrors] = useState({});
   

    const updateFormData = (value, key) => {
        const tFormData = {
            ...formData,
            [key]: value
        }
        setFormData(tFormData);
    }

    const getFormData = (key) => {
        if (formData[key]) {
            return formData[key];
        }
    }

    const backLink = {
        text: (
            <div className="d-flex align-items-center">
                <ArrowBackIosIcon sx={{ fontSize: 18 }} />
                Back
            </div>
        )
    }
    const location = (
        <div>
            {country.selectedCountry?.label}
        </div>
    )

    const loadInit = async () => {
        let url = '/user/pro/' + providerId;
        axios.get(url)
        .then(response => {
            if(response.status === 200) {
                setProvidersData(response.data.data);
                const specialitiesList = response.data.data.services.map((curr) => {
                    return { value: curr.id, label: curr.name }
                })
                setSpecialities(specialitiesList)
            }
        })
        .catch(err => {
            history.push('/');
            console.log(err);
        });  
    }

    const onSubmitHandler = () => {
        setErrors({});
        let tErrors = {};

        if (formData.budget == '') {
            tErrors['budget'] = 'Please add your budget';
        }

        if (formData.describe_your_need == '') {
            tErrors['describe_your_need'] = 'Please describe your neeeds';
        }

        if (formData.sub_services.length === 0) {
            tErrors['sub_services'] = 'Please select services';
        }

        if (Object.keys(tErrors).length === 0) {
            const payload = {
                "servicecategory_id": providersData.servicecategory_id,
                "service_id": formData.sub_services ? formData.sub_services.map(i => i.value).join(',') : undefined,
                "serviceprovider_id": parseInt(providerId),
                "requirement_description": getFormData('describe_your_need'),
                "start_date": moment(getFormData('select_date')).format('YYYY-MM-DD'),
                "start_time": getFormData('select_time'),
                "location": country.selectedCountry?.label,
                "budget": parseFloat(getFormData('budget')),
                "screen_from": 'book-pro'
            }
            console.log("payload:",payload)
            axios.post('/order', payload)
            .then(response => {
                if (response.status === 200 && response.statusText === 'OK') {
                    setApiMessagae({
                        type: 1,
                        msg: 'Your request was successfully submitted'
                    });
                    setTimeout(() => {
                        history.push('/');
                    }, 2000);
                } else { 
                    setApiMessagae({
                        type: 0,
                        msg: 'Oops, Something went wrong. Try again later'
                    });
                    return false 
                }
            })
            .catch(err => {
                let errmsg = err.response.data[Object.keys(err.response.data)[0]];
                setApiMessagae({
                    type: 0,
                    msg: errmsg
                });
                return false
            });
        } else {
            setErrors(tErrors);
        }
    }

    const checkError = (key) => {
        if (errors && errors[key]) {
            return errors[key]
        }
        return undefined;
    }

    useEffect(() => {
        loadInit();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    const Error = (props) => {
        return <span className="text-danger">{props.text}</span>
    }
    return (
        <>
            <Helmet>
                <title>Hire Pro</title>
                <meta property="og:title" content="AcDiTo - Hire Pro" />
                <meta name="description" content="Hire Pro" />
            </Helmet>
            <PagesHeader />
            <PageTitleSection minHeight="120px" blueTitle="Hire Pro" backLink={backLink} />
            <div className={`container-fluid my-5 ${classes.wrapper}`}>
                <div className="row">
                    <div className="col-12 col-sm-6 mb-4">
                        <ReadOnlyDetails title="Selected Service" data={providersData?.servicecategory_name} />
                    </div>
                    <div className="col-12 col-sm-6 mb-4">
                        <ReadOnlyDetails title="Service Location" data={location} />
                    </div>
                </div>
                <div className="row">
                    <div className={`col-12 col-sm-6 mb-4`}>
                        <MultiSelect
                            selectProps={{
                                options: specialities,
                                value: getFormData('sub_services'),
                                freeSolo: getFormData('sub_services').length > 5 ? false : true,
                                getOptionDisabled: (options) => (getFormData('sub_services').length > 5 ? true : false),
                                onChange: (value, key) => {
                                    updateFormData(key, 'sub_services');
                                },
                            }}
                            inputProps={{
                                label: 'Sub-Services',
                                helperText: 'You can add max 5',
                                className: classes.multiSelect,
                                required: true,
                            }}
                        />
                        {checkError('sub_services') && <Error text={checkError('sub_services')} />}
                    </div>
                    <div className="col-12 col-sm-6 mb-4">
                        <Input
                            type="text"
                            id="describe_your_need"
                            label="Describe your needs"
                            variant="outlined"
                            onChange={(e) => {
                                updateFormData(e.target.value, 'describe_your_need');
                            }}
                            inputProps={{
                                maxLength: 250,
                            }}
                            value={getFormData('describe_your_need')}
                            minRows={3}
                            multiline={true}
                            required
                        />
                        {checkError('describe_your_need') && <Error text={checkError('describe_your_need')} />}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-4">
                        <div>When would you like the work to start?</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb-4">
                        <ReadOnlyDetails title="Select Date" />
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={moment(getFormData('select_date')).toDate()}
                            onChange={(date) => {
                                updateFormData(date, 'select_date');
                            }}
                            minDate={new Date()}
                            customInput={<Input className={classes.bookProInput} />} // Replace Input with your component
                        />
                    </div>
                    <div className="col-12 col-sm-3 mb-4">
                        <ReadOnlyDetails title="Select Time" />
                        <TimePicker
                            value={getFormData('select_time') || defaultTime}
                            onChange={(newValue) => {
                                console.log(newValue)
                                updateFormData(newValue, 'select_time');
                            }}
                            className={classes.bookProInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb-4">
                        <Input
                            type="text"
                            id="price"
                            label=""
                            placeholder="Your Budget"
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{getCurrnecyCode()}</InputAdornment>,
                            }}
                            inputProps={{ inputMode: 'numeric' }}
                            className={classes.bookProInput}
                            onChange={(event) => {
                                if (/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(event.target.value)) {
                                    updateFormData(event.target.value, 'budget');
                                }
                            }}
                            onBlur={(e) => {
                                const tBudget = getFormData('budget');

                                if (isNaN(tBudget)) {
                                    updateFormData(parseFloat('0').toFixed(2), 'budget');
                                } else {
                                    updateFormData(parseFloat(getFormData('budget')).toFixed(2), 'budget');
                                }
                            }}
                            required
                            value={getFormData('budget')}
                        />
                        {checkError('budget') && <Error text={checkError('budget')} />}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mt-4 mb-4">
                        <button type="button" onClick={onSubmitHandler} className="btn btn-block send-button tx-tfm">
                            Submit
                        </button>
                    </div>
                </div>
                {apiMessagae && (
                    <div className="row">
                        <div className="col-12">
                            <span
                                className={clsx(
                                    'h5',
                                    apiMessagae.type === 1 && 'text-success',
                                    apiMessagae.type === 0 && 'text-danger'
                                )}
                            >
                                {apiMessagae.msg}
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <PagesFooter showFooter={false} />
        </>
    );
}

export default BookPro;