import React from 'react';
import { Link } from 'react-router-dom';

function PagesFooterSub(props) {
    const copyClass = props.copyClass ? 'footer2 fmroboto footer-copyright' : 'footer2 fmroboto';
    return (
        <div className={copyClass}>
            <div className="container">
                <div className="row footer2">
                    <div className="col-xl-6 col-md-6 col-sm-12 col-xm-12">
                        © AcDiTo, Inc. {new Date().getFullYear()}. All Rights Reserved
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-12 col-xm-12">
                        <span className="ft-terms-cond">
                            <Link to="/terms-condition" className="text-white">
                                Terms & Conditions
                            </Link>{' '}
                            |{' '}
                            <Link to="/privacy-policy" className="text-white">
                                {' '}
                                Privacy Policy
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PagesFooterSub;
