import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import PagesHeader from '../../components/Navbars/PageHeader';
import PagesFooter from '../../components/Footer/PageFooter';
import LandingPageContent from './LandingPageContent';
import {Helmet} from "react-helmet";

//@inject('userStore')
@withRouter
@observer
class LandingPage extends React.Component {
    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Home</title>
                    <meta key="meta:description" name="description" content="AcDiTo - Instantly find, and connect with top-rated trusted local specialists, freelancers, and contractors" />
                    <meta key="og:title" property="og:title" content="AcDiTo - Easily find and hire trusted local professionals anytime, anywhere" />
                    <meta key="og:description" property="og:description" content="AcDiTo - Instantly find, and connect with top-rated trusted local specialists, freelancers, and contractors" />
                </Helmet>
                 <PagesHeader />
                 <LandingPageContent />
                 <PagesFooter />
            </>
        );
    }
}

export default LandingPage;