import React from 'react';
import classes from './style.module.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ShareIcon from '@mui/icons-material/Share';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import calendar from 'assets/img/calendar-and-clock-time-admin.svg';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { get } from 'lodash';
import moment from 'moment';
import { getUserRole } from 'lib/helpers';
import { useHistory } from 'react-router-dom';

const ProjectItem = (props) => {
    const { project } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        alert('Coming soon!');
        setAnchorEl(null);
    };

    const getName = () => {
        const name = getUserRole() === 'serviceuser' ? get(project, 'serviceprovider_name', '') : get(project, 'serviceuser_name', '');

        if (name) {
            const arrName = name.split(' ');
            if (arrName.length > 1) {
                return `${arrName[0]} ${arrName[1].charAt(0)}.`
            }
            return name;
        }
    }

    const onSeeDetailsClick = () => {
        history.push(`/projects/${project.id}`)
    }

    return (
        <div className={classes.project}>
            <div className={classes.projectOwner}>
                <div className="d-flex">
                    <div>
                        <div className={classes.projectServiceCat}>{get(project, 'servicecategory_name', '')}</div>
                        <div className={classes.projectServices}>{get(project, 'services', []).join(', ')}</div>
                    </div>
                    {
                        get(project, 'type', '') === 'giftaservice' && (
                            <div className="ml-3 d-flex align-items-center" style={{ fontSize: '25px', color: '#00b9f2' }}>
                                <i className="fa fa-gift"></i>
                            </div>
                        )
                    }
                </div>
                <div>
                    <MoreVertIcon onClick={handleClick} style={{ fontSize: '26px', color: '#bebebe', cursor: 'pointer' }} />
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem className={classes.menuOption} onClick={handleClose}>
                            <ShareIcon /> Share
                        </MenuItem>
                        <MenuItem className={classes.menuOption} onClick={handleClose}>
                            <HighlightOffIcon /> Delete
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            <div>
                <hr />
            </div>
            <div className={classes.userDetails}>
                <div className={classes.userDetailsItem}>
                    <PersonOutlineIcon />
                    <div className="text-capitalize">
                        {getName()}
                    </div>
                </div>
                {
                    get(project, 'location', '') && (
                        <div className={classes.userDetailsItem}>
                            <LocationOnIcon />
                            {get(project, 'location', '')}
                        </div>
                    )
                }
                <div className={classes.userDetailsItem}>
                    <img src={calendar} alt="calendar" />
                    {moment(get(project, 'created_at', '')).format('D MMM YYYY, h:mm a')}
                </div>
                <div className={classes.userDetailsItem}>
                    <i className="fa fa-money-bill"></i>&nbsp;
                    <span className="text-capitalize">{get(project, 'payment_status', null) === null ? 'Not initiated' : get(project, 'payment_status', '')}</span>
                </div>
            </div>
            <div className={classes.moreDetails}>
                <div className={classes.seeDetails} onClick={onSeeDetailsClick}>
                    See Details
                    <ArrowRightAltIcon />
                </div>
            </div>
        </div>
    )
}

export default ProjectItem;