import React, { Component, Fragment } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { Navbar, Modal, Nav, Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import NewLogo from '../../assets/img/logo_abeg_ya.png';
import { isUserLoggedIn } from 'lib/helpers';
import classes from './style.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import { getProfilePic } from 'lib/getProfilePic';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import images from '../../assets/image/index';

const   UserDrpd = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userData = useSelector((state) => {
        return state.userData.value
    });
    let currUser = JSON.parse(localStorage.getItem("currUser"));
    const history = useHistory();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        if (anchorEl) {
            handleClose();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const onItemClick = (item) => {
        if (item === 1) {
            history.push('/setup-profile');
        } else {
            history.push('/settings');
        }
    }

    return (
        <div className={`${classes.userDrpd} d-flex align-items-center`} onClick={handleClick}>
            {
                userData.profileId && (
                    <div className={classes.userImg}>
                        <img key={userData.profileId} src={getProfilePic(userData.profileId)} alt="img" />
                    </div>
                )
            }
            <div className={classes.userName}>
                {currUser.first_name}
            </div>
            <div>
                <KeyboardArrowDownIcon  />
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onBackdropClick={handleClose}
            >
                <MenuItem onClick={() => onItemClick(1)}>
                    My Profile
                </MenuItem>
                <MenuItem onClick={() => onItemClick(2)}>
                    Settings
                </MenuItem>
            </Menu>
        </div>
    )
}

class PagesHeader extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
        this.state = {
            width: window.innerWidth,
            openLoginModal: false,
            openForgotPwdModal: false,
            openSignUpModal: false
        };
    }

    // function that sets the class to active of the active page
    activeRoute(routeName) {
        return window.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    }

    // function that shows/hides sidebar on responsive
    mobileSidebarToggle(e) {
        document.documentElement.classList.toggle('nav-open');
    }

    updateWidth() {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('resize', this.updateWidth.bind(this));
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.updateWidth.bind(this));
    }


    changeNavLink = (route) => {
        const { history } = this.props;
        history.push('/' + route);
    };

    gotoProfile = () => {
        const { history } = this.props;
        history.push('/setup-profile');
    };


    handleSignUpClick = () => {
        // const { history } = this.props;
        // window.history.pushState('/signup');
        // window.location.href = '/signup'
    }

    handleLoginBtnClick = () => {
        // alert("login button click");
        this.setState({ openLoginModal: true });
    };
    handleForgotPwdClick = () => {
        this.setState({ openForgotPwdModal: true, openLoginModal: false });
    };
    handleBackToLogin = () => {
        this.setState({ openForgotPwdModal: false, openLoginModal: true, openSignUpModal: false });
    };
    handleClose = () => this.setState({ openLoginModal: false, openForgotPwdModal: false, openSignUpModal: false });
    //handleSubmit(name) => //some code
    handleLogout = () => {
        localStorage.removeItem("currUser");
        const { history } = this.props;
        history.push('/');
        window.location.reload();
    }

    render() {
        let signUp = '', signIn = '', loggedIn = '';
        if (!localStorage.getItem("currUser")) {
            try {
                signIn = <Nav.Link className="lp-navbar-list_item" href="/login">
                    <button className="lp-primary-btn fwBold" onClick={this.handleLoginBtnClick}>
                        Sign In
                    </button>
                </Nav.Link>
                signUp = <Nav.Link className="lp-navbar-list_item" href="/signup">
                    <button className="lp-primary-btn fwBold" onClick={this.handleSignUpClick}>
                        Sign Up
                    </button>
                </Nav.Link>
            } catch (e) { }
        } else {
            try {
                loggedIn = <div className="mt-2 mb-2 d-flex"> 
                    <UserDrpd />
                    <button className="ml-3 btn lp-button1 fwBold btn-danger" onClick={this.handleLogout}>Logout</button>
                </div>
            } catch (e) { }
        }
        return (
            <>
                <Navbar expand="lg" className="header">
                    <Container>
                        <Navbar.Brand>
                            <NavLink to="/">
                                <img className="img-reponsive header-logo" src={images.logo} alt="react-logo" />
                            </NavLink>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav
                                className="ml-auto"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <NavLink
                                    className="lp-button-link lp-navbar-list_item text-decoration-none"
                                    to="/all-services"
                                >
                                    All Services
                                </NavLink>
                                {isUserLoggedIn() && (
                                    <NavLink
                                        className="lp-button-link lp-navbar-list_item text-decoration-none"
                                        to="/projects"
                                    >
                                        Projects
                                    </NavLink>
                                )}
                                {isUserLoggedIn() && (
                                    <NavLink
                                        className="lp-button-link lp-navbar-list_item text-decoration-none"
                                        to="/settings"
                                    >
                                        Payments
                                    </NavLink>
                                )}
                                {signIn}
                                {signUp}
                                {loggedIn}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default withRouter(PagesHeader);
